import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Recensione } from '../interface/recensione.interface';
import { environment } from 'src/environments/environment.development';
import { map } from 'rxjs';
import { getDate, getHours, getMinutes, getMonth, getSeconds, getYear, parseISO } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class RecensioniService {
  private recensioniSub = new BehaviorSubject<Recensione | null>(null);
  recensioni$ = this.recensioniSub.asObservable();
  constructor(private http: HttpClient) {}

  saveRecensione(recensione:any) {
    return this.http.post<number>(`${environment.apiUrl}/recensioni`, recensione);
  }

  getAllRecensioni() {
    return this.http.get<Recensione[]>(`${environment.apiUrl}/recensioni`);
  }

  getAllRecensioniEasy() {
    return this.http.get<Recensione[]>(`${environment.apiUrl}/recensioni/easy`).pipe(
      map((recensioni) => {
        let newRecensioni: Recensione[] = recensioni.map((recensione) => {
          let combinedDateTime = this.combineDateTime(recensione.data, recensione.orario);
          recensione.giorno = getDate(combinedDateTime);
          recensione.mese = getMonth(combinedDateTime) + 1; // getMonth returns 0-based month
          recensione.anno = getYear(combinedDateTime);
  
          // Extract time components
          recensione.ora = getHours(combinedDateTime);
          recensione.minuti = getMinutes(combinedDateTime);
          recensione.secondi = getSeconds(combinedDateTime);
          return recensione
        })
        return newRecensioni;
      })
    );
  }

  patchStatoRecensione(stato: string, recensione: number) {
    return this.http.patch<Recensione>(`${environment.apiUrl}/recensioni/${recensione}`, stato)
  }

  patchPosizioneRecensione(posizione: number, recensione: number) {
    return this.http.patch<Recensione>(`${environment.apiUrl}/recensioni/${recensione}/posizione`, posizione)
  }

  private combineDateTime(data: string, time: string): Date {
    console.log(data, time)
    const dateTimeString = `${data}T${time}`;
    return parseISO(dateTimeString)
  }
}
