<div class="container px-5 py-3">
    <div class="d-flex justify-content-between align-items-center mt-3">
        <h3 class="  mt-2 fw-bold">Domande</h3>
        <a routerLink="/domande" class="nav-link" >Read more...</a>
    </div>
    <div class="py-4">
        <div class="row mb-4 mx-lg-4 mx-md-0 mx-0 mt-2" style="height: max-content;">
            <div class="col-lg-6 col-12 col-md-6 text-black mb-4">
              <div ngbAccordion>
                <div ngbAccordionItem>
                  <h5 ngbAccordionHeader>
                    <button ngbAccordionButton>Non riesco a registrarmi. Come posso fare?</button>
                  </h5>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>È possibile che ti sia già registrato in passato ai nostri servizi utilizzando lo stesso indirizzo mail: in questo caso prova a procedere con il recupero della password. Se avessi altri problemi con la procedura di registrazione, contattaci.</ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12 col-md-6 text-black mb-4">
              <div ngbAccordion>
                <div ngbAccordionItem>
                  <h5 ngbAccordionHeader>
                    <button ngbAccordionButton>Ho chiesto il reset della password ma non la ricevo. Come posso fare?</button>
                  </h5>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>Può controllare la casella spam , per mancata ricezione, recarsi in libreria per segnalare il problema o scriverci sul nostro form di contatto per avere una rapida assistenza.</ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12 col-md-6 text-black mb-4">
                <div ngbAccordion>
                    <div ngbAccordionItem>
                      <h5 ngbAccordionHeader>
                        <button ngbAccordionButton>Quanto tempo impiega la consegna?</button>
                      </h5>
                      <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                          <ng-template>La consegna avviene entro 5-7 giorni lavorativi.</ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="d-none d-md-block col-lg-6 col-12 col-md-6 mb-4 text-black">
                <div ngbAccordion>
                    <div ngbAccordionItem>
                      <h5 ngbAccordionHeader>
                        <button ngbAccordionButton>Posso tracciare il mio ordine?</button>
                      </h5>
                      <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                          <ng-template>Sì, riceverai un'email con il numero di tracciamento una volta spedito l'ordine.</ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="d-none d-md-block col-lg-6 col-12 col-md-6 text-black mb-4">
              <div ngbAccordion>
                  <div ngbAccordionItem>
                    <h5 ngbAccordionHeader>
                      <button ngbAccordionButton>Quali metodi di pagamento accettate?</button>
                    </h5>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody>
                        <ng-template>Accettiamo carte di credito, Apple Pay, Google Pay, Link e Klarna.</ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="d-none d-md-block col-lg-6 col-12 col-md-6 mb-4 text-black">
                <div ngbAccordion>
                    <div ngbAccordionItem>
                      <h5 ngbAccordionHeader>
                        <button ngbAccordionButton>Qual è la politica di reso?</button>
                      </h5>
                      <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                          <ng-template>Accettiamo resi entro 30 giorni dalla consegna, purché il libricino sia in condizioni originali. Le spese di restituzione del prodotto sono a carico del cliente.</ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>