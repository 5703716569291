<ng-template #sceltaMockup>
    Devi scegliere un tema per procedere
</ng-template>

<ng-template #sceltaMockupEPacchetto>
    Devi scegliere un tema e un pacchetto per procedere
</ng-template>

<ng-template #sceltaPacchetto>
    Devi scegliere un pacchetto per procedere
</ng-template>

<ng-template #sceltaOrdine>
    Devi completare i passaggi
</ng-template>

<ng-template #noLoggato>
    Devi essere loggato per entrare in questa pagina
</ng-template>

<ng-template #paginaNonRimuovibile>
    Questa pagina non è rimuovibile
</ng-template>

<ng-template #erroreCaricamentoImmagine>
    C'è stato un errore nel caricamento dell'immagine
</ng-template>

<ng-template #formDaCompletare>
    Devi completare i campi obbligatori
</ng-template>

<ng-template #prodottoFullCustomNonDisponibile>
    Pacchetto Custom attualmente non disponibile
</ng-template>

<ng-template #prodottoPremiumNonDisponibile>
    Pacchetto Premium attualmente non disponibile
</ng-template>

<ng-template #richiestaInviata>
    Richiesta Inviata
</ng-template>

<ng-template #registrazioneEffettuata>
    Registrazione Effettuata. Ora effettua il login
</ng-template>

<ng-template #loginEffettuato>
    Login effettuato
</ng-template>

<ng-template #ordineEffEPagato>
    Ordine effettuato e pagato
</ng-template>

<ng-template #elementoRimosso>
    Elemento Rimosso. Potrai sempre riaggiungerlo da gestione pagine.
</ng-template>

<ng-template #accessoProvider>
    Devi effettuare l'accesso con il provider con il quale ti sei registrato
</ng-template>

<ng-template #passwordStrong>
    La password deve contenere:
    <ul>
        <li>Minimo 8 caratteri</li>
        <li>Minimo una lettera minuscola</li>
        <li>Minimo una lettera maiuscola</li>
        <li>Minimo un numero</li>
        <li>Minimo un carattere speciale (!, &#64;, #, $)</li>
    </ul>
</ng-template>

<ng-template #custom let-customMessage="customMessage">
    {{customMessage}}
</ng-template>