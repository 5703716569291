<div class="container px-5 py-3">
    <div class="d-flex justify-content-between align-items-center mt-3">
        <h3 *ngIf="!stato" class="fw-bold text-white  mt-2">Recensioni</h3>
        <h3 *ngIf="stato" class="fw-bold mt-2">Recensioni</h3>
        <a routerLink="recensioni" class="nav-link" >vedi tutte</a>
    </div>
    <div class="py-4">
        <div class="row mb-4 mx-lg-4 mx-md-0 mx-0 mt-2" style="height: max-content;">
            <div class="col-lg-4 col-12 col-md-6 text-black mb-4">
                <div [ngClass]="!stato ? 'classebordo' : 'classebordo2'" class="container-fluid bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5">
                    <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">Fabio Brogi</h5>
                    <p class="ellipsis duerighe m-1 w-100 mt-1" style="font-style: italic;">"Il libricino è molto utile per i nostri ospiti. Informazioni chiare e ben strutturate. Ottima aggiunta!"</p>
                    <div class="d-flex justify-content-between  align-items-end">
                        <div>
                            <!-- crea un template che va ad indicare il riempimento e l'indice di ogni rate -->
                            <ng-template #t let-fill="fill">
                                <!-- Crea un'icona di una stella -->
                                <i class="bi-star-fill">
                                    <!-- Se il fill è maggiore di 0 riempie la stella -->
                                    <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                                </i>
                            </ng-template>
                            <!-- Crea il rating di max 5 stelle in base alla variabile rating con template t -->
                            <ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="true" [max]="5" [ariaValueText]="ariaValueText" />
                        </div>
                        <p class="fs-7 m-0 p-0">10 ottobre 2023</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6 text-black mb-4">
                <div [ngClass]="!stato ? 'classebordo' : 'classebordo2'" class="container-fluid bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5">
                    <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">F. Naccari</h5>
                    <p class="ellipsis duerighe m-1 w-100 mt-1" style="font-style: italic;">"Molto apprezzato dagli ospiti. Contiene tutto ciò che serve sapere sulla zona. Solo la mappa potrebbe essere migliorata."</p>
                    <div class="d-flex justify-content-between  align-items-end">
                        <div>
                            <!-- crea un template che va ad indicare il riempimento e l'indice di ogni rate -->
                            <ng-template #t let-fill="fill">
                                <!-- Crea un'icona di una stella -->
                                <i class="bi-star-fill">
                                    <!-- Se il fill è maggiore di 0 riempie la stella -->
                                    <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                                </i>
                            </ng-template>
                            <!-- Crea il rating di max 5 stelle in base alla variabile rating con template t -->
                            <ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="true" [max]="5" [ariaValueText]="ariaValueText" />
                        </div>
                        <p class="fs-7 m-0 p-0">10 ottobre 2023</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6 text-black mb-4">
                <div [ngClass]="!stato ? 'classebordo' : 'classebordo2'" class="container-fluid bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5">
                    <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">Antonio Copetti</h5>
                    <p class="ellipsis duerighe m-1 w-100 mt-1" style="font-style: italic;">"Risorsa eccellente! I nostri ospiti trovano tutte le informazioni necessarie. Consigliatissimo."</p>
                    <div class="d-flex justify-content-between  align-items-end">
                        <div>
                            <!-- crea un template che va ad indicare il riempimento e l'indice di ogni rate -->
                            <ng-template #t let-fill="fill">
                                <!-- Crea un'icona di una stella -->
                                <i class="bi-star-fill">
                                    <!-- Se il fill è maggiore di 0 riempie la stella -->
                                    <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                                </i>
                            </ng-template>
                            <!-- Crea il rating di max 5 stelle in base alla variabile rating con template t -->
                            <ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="true" [max]="5" [ariaValueText]="ariaValueText" />
                        </div>
                        <p class="fs-7 m-0 p-0">10 ottobre 2023</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!stato" class="d-none d-md-block col-lg-4 col-12 col-md-6 mb-4 text-black">
                <div class="container-fluid classebordo bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5">
                    <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">Vito Dagnello</h5>
                    <p class="ellipsis duerighe m-1 w-100 mt-1" style="font-style: italic;">"Pratico e ben fatto. I clienti lo usano spesso. Aggiungerei solo qualche suggerimento in più sui ristoranti."</p>
                    <div class="d-flex justify-content-between  align-items-end">
                        <div>
                            <!-- crea un template che va ad indicare il riempimento e l'indice di ogni rate -->
                            <ng-template #t let-fill="fill">
                                <!-- Crea un'icona di una stella -->
                                <i class="bi-star-fill">
                                    <!-- Se il fill è maggiore di 0 riempie la stella -->
                                    <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                                </i>
                            </ng-template>
                            <!-- Crea il rating di max 5 stelle in base alla variabile rating con template t -->
                            <ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="true" [max]="5" [ariaValueText]="ariaValueText" />
                        </div>
                        <p class="fs-7 m-0 p-0">10 ottobre 2023</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!stato" class="d-none d-md-block col-lg-4 col-12 col-md-6 mb-4 text-black">
                <div class="container-fluid classebordo bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5">
                    <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">Alexandru Ifrim</h5>
                    <p class="ellipsis duerighe m-1 w-100 mt-1" style="font-style: italic;">"Utile e informativo. Ha migliorato l'esperienza dei nostri ospiti. Ottimo acquisto!"</p>
                    <div class="d-flex justify-content-between  align-items-end">
                        <div>
                            <!-- crea un template che va ad indicare il riempimento e l'indice di ogni rate -->
                            <ng-template #t let-fill="fill">
                                <!-- Crea un'icona di una stella -->
                                <i class="bi-star-fill">
                                    <!-- Se il fill è maggiore di 0 riempie la stella -->
                                    <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                                </i>
                            </ng-template>
                            <!-- Crea il rating di max 5 stelle in base alla variabile rating con template t -->
                            <ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="true" [max]="5" [ariaValueText]="ariaValueText" />
                        </div>
                        <p class="fs-7 m-0 p-0">10 ottobre 2023</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!stato" class="d-none d-md-block col-lg-4 col-12 col-md-6 mb-4 text-black">
                <div class="container-fluid classebordo bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5">
                    <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">P. Pastushenko</h5>
                    <p class="ellipsis duerighe m-1 w-100 mt-1" style="font-style: italic;">"Il libricino è completo e ben strutturato. Gli ospiti lo trovano molto utile. Solo qualche piccolo aggiornamento sulla mappa."</p>
                    <div class="d-flex justify-content-between align-items-end">
                        <div>
                            <!-- crea un template che va ad indicare il riempimento e l'indice di ogni rate -->
                            <ng-template #t let-fill="fill">
                                <!-- Crea un'icona di una stella -->
                                <i class="bi-star-fill">
                                    <!-- Se il fill è maggiore di 0 riempie la stella -->
                                    <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                                </i>
                            </ng-template>
                            <!-- Crea il rating di max 5 stelle in base alla variabile rating con template t -->
                            <ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="true" [max]="5" [ariaValueText]="ariaValueText" />
                        </div>
                        <p class="fs-7 m-0 p-0">10 ottobre 2023</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
