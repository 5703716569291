<ng-template #content let-modal id="feedback" style="z-index: 12000000!important;">
  <form #modaleFeedback="ngForm">
    <div class="modal-header border-0" style="height: 0 !important"></div>
    <div class="modal-body border-0 py-2 px-4">
      <div class="d-flex justify-content-between">
        <h3 class="fw-bold mb-3">Scrivi una Recensione</h3>
        <button
          type="button"
          class="btn-close"
          style="color: #8d99ae"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        ></button>
      </div>
      <textarea
        (resize)="(false)"
        name="feedback"
        ngModel 
        class="form-control"
        placeholder="Vota con 1/5 stelline e scrivi la tua recensione"
        style="height: 20vh"
      ></textarea>
      <input type="file" (change)="selectFile($event)" name="immagineUrl" class="form-control mt-3 mb-2" ngModel />
      <div class="d-flex justify-content-between align-items-center px-4">
        <div>
          <ng-template #t let-fill="fill">
            <i class="bi-star-fill fs-4">
              <i
                *ngIf="fill > 0"
                class="bi-star-fill filled"
                [style.width.%]="fill"
              ></i>
            </i>
          </ng-template>
          <ngb-rating
            [starTemplate]="t"
            [readonly]="false"
            [max]="5"
            ngModel
            name="voto"
          />
        </div>
        <a
          class="btn rounded-3 colorerosso fs-6 fs-md-5 mt-3"
          (click)="sendFeedback(modaleFeedback); modal.close('Close click');"
          role="button"
          >Invia</a
        >
      </div>
    </div>
    <div class="modal-footer border-0"></div>
  </form>
</ng-template>

<ng-template #cambioPassword let-modal2 id="feedback" style="z-index: 12000000!important;">
  <form #modaleFeedback="ngForm">
    <div class="modal-header border-0" style="height: 0 !important"></div>
    <div class="modal-body border-0 py-2 px-4">
      <div class="d-flex justify-content-between">
        <h3 class="fw-bold mb-3">Modifica Password</h3>
        <button
          type="button"
          class="btn-close"
          style="color: #8d99ae"
          aria-label="Close"
          (click)="modal2.dismiss('Cross click')"
        ></button>
      </div>
      <div class="form-floating">
        <input type="password" name="newPassword" ngModel class="form-control" />
        <label for="newPassword" class="form-label">Nuova Password</label>
      </div>
      <a
        class="btn rounded-3 colorerosso fs-6 fs-md-5 mt-3"
        (click)="changePassword(modaleFeedback); modal2.close('Close click');"
        role="button"
        >Cambia Password</a
      >
    </div>
    <div class="modal-footer border-0"></div>
  </form>
</ng-template>

<div class="container-fluid container-md px-3 px-md-5 pt-3 mt-3 pb-3 mb-9">
  <div class="row">
    <div class="col-lg-7 col-12 pe-lg-4">
      <h3 class="fw-bold mb-3">Profilo</h3>
      <div class="border border-1 rounded-4 w-100 p-3 mb-4 py-4 mb-2">
        <div class="row">
          <div class="col-3 ps-4 ps-md-0 d-flex justify-content-center clicca">
            <input type="file" id="avatarChange" #avatarChange class="d-none" />
            <div style="width: max-content" class="position-relative">
              <img
                src="assets/img/immagineavatar.png"
                height="70"
                width="70"
                alt="Immagine Profilo"
              />
            </div>
          </div>
          <div class="col-9">
            <p class="m-1">
              <span class="fw-bolder"
                >{{ user?.nome }} {{ user?.cognome }}</span
              >
            </p>
            <p class="m-1">{{ user?.email }}</p>
          </div>
        </div>
      </div>
      <h3 class="fw-bold mb-3">Ordini</h3>
      <div class="w-100 d-flex justify-content-center my-5" *ngIf="attesa">
        <div class="spinner-border spinner-colore" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div
        class="border border-1 rounded-4 w-100 py-4 mb-4 px-lg-5 px-3 mb-2"
        *ngFor="let ordine of ordini"
      >
        <div class="d-flex justify-content-between">
          <h5>Ordine IT-{{ ordine.id }}</h5>
          <p class="m-0 p-0">{{ ordine.data }}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="m-0 p-0 fs-7 fs-md-6">
            Pacchetto
            {{
              ordine.pacchetto == 1
                ? "Basic"
                : ordine.pacchetto == 2
                ? "Premium"
                : "Custom"
            }}
          </p>
          <p class="m-0 p-0">
            Totale:
            <strong>{{
              (ordine.totale && ordine.totale + " €") || "Non definito"
            }}</strong>
          </p>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div>
            <button
              type="button"
              class="btn btn-outline-dark rounded-pill me-2 fs-7 fs-md-6"
              (click)="openModalDettagli(ordine)"
            >
              Dettagli
            </button>
            <button
              type="button"
              class="btn btn-outline-dark rounded-pill ms-md-2 fs-7 fs-md-6"
              (click)="openModalFeedback(content, ordine.id)"
              *ngIf="ordine.statoOrdine == 'CONSEGNATO'"
            >
              Feedback
            </button>
          </div>
          <h5 class="fw-bold fs-6 fs-md-5">
            {{ ordine.statoOrdine | customStatus }}
          </h5>
        </div>
      </div>
      <!-- <div class="border border-1  rounded-4 w-100 py-4 mb-4 px-lg-5 px-3 mb-2 ">
        <div class="d-flex justify-content-between">
          <h5>Ordine n00001</h5>
          <p class="m-0 p-0">05/02/2024</p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="m-0 p-0 fs-7 fs-md-6">tracciamento: 238746cx</p>
          <p class="m-0 p-0">Totale: <strong>127.90$</strong></p>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-3">
          <button type="button" class="btn btn-outline-dark rounded-pill">Dettagli</button>
          <h5 class="fw-bold fs-6 fs-md-5">Form Incompleto</h5>
        </div>
      </div> -->
    </div>
    <div class="col-lg-5 col-12 ps-lg-4">
      <h3 class="fw-bold mb-3">Impostazioni</h3>
      <div class="border border-1 rounded-4 w-100 px-5 my-2 py-4 mb-4">
        <p class="clicca" (click)="openModalCambioPassword(cambioPassword)">Cambio Password</p>
        <hr />
        <p class="clicca">Assistenza</p>
        <hr />
        <p class="clicca">Gestione Profilo</p>
        <hr *ngIf="user?.ruoloUtente == 'ADMIN'" />
        <p
          class="clicca"
          *ngIf="user?.ruoloUtente == 'ADMIN'"
          routerLink="/admin"
        >
          Gestione Admin
        </p>
        <hr />
        <p class="clicca" (click)="logout()">Logout</p>
      </div>
    </div>
  </div>
</div>
