import { Component } from '@angular/core';

@Component({
  selector: 'app-domandesection',
  templateUrl: './domandesection.component.html',
  styleUrls: ['./domandesection.component.scss']
})
export class DomandesectionComponent {

}
