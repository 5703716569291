import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs';
import { Mockup } from 'src/app/interface/mockup.interface';
import { Recensione } from 'src/app/interface/recensione.interface';
import { Utente } from 'src/app/interface/utente.interface';
import { AuthService } from 'src/app/service/auth.service';
import { EnvironmentService } from 'src/app/service/environment.service';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { RecensioniService } from 'src/app/service/recensioni.service';

@Component({
  selector: 'app-mokupsection',
  templateUrl: './mokupsection.component.html',
  styleUrls: ['./mokupsection.component.scss'],
})
export class MokupsectionComponent implements OnInit {
  private modalService = inject(NgbModal);
  mockupSelezionato!: string;
  isOverlayVisible: boolean[] = [];
  isSelected: boolean[] = [];
  rating = 5;
  pagine!: Mockup;
  attesa = false
  attesa2 = false
  user: Utente | undefined;
  // References to DOM Elements
  private prevBtn!:HTMLDivElement;
  private nextBtn!:HTMLDivElement;

  private paper1!:HTMLDivElement;
  private paper2!:HTMLDivElement;
  private paper3!:HTMLDivElement;
  private currentLocation = 1;
  private numOfPapers = 10;
  private maxLocation = this.numOfPapers;
  private modale!: NgbModalRef;
  mockups: Mockup[] = [];
  prezzoMin: number = 1000000.0;

  paginaSpeciale: boolean = false;

  constructor(private service:FileUploadService, private router: Router, private environmentSrv: EnvironmentService, private recensioniSrv: RecensioniService) {
  }

  async ngOnInit() {
    this.environmentSrv.getAllPacchetti().subscribe((data) => {
      data.forEach((data2) => {
        if (this.prezzoMin > Number(data2.prezzo)) {
          this.prezzoMin = Number(data2.prezzo)
        }

      })
    })
    this.router.events.pipe(
      // filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if (event.routerEvent.url == '/acquisto/scegli-tema') {
        this.paginaSpeciale = true;
        if (localStorage.getItem('mockup')) {
          this.isSelected[Number(localStorage.getItem('mockup'))] = true
        }
      }
    });
    this.environmentSrv.getAllMockup().subscribe((mockups) => {
      this.mockups = mockups.sort((a, b) => a.id - b.id);
      this.recensioniSrv.getAllRecensioniEasy().subscribe((recensioni)  => {
        this.mockups.forEach((mockup, index) => {
          let recensioniMockup = recensioni.filter(r => r.mockup == mockup.id)
          let voto = 0;
          let recensioniTot = 0;
          recensioniMockup.forEach((recensione) => {
            voto += recensione.voto;
            recensioniTot += 1;
          })
          voto /= recensioniTot;
          this.mockups[index].voto = voto;
          this.mockups[index].recensioni = recensioniTot;
        })
      })
    })
  }

  openModal(item: number, content: TemplateRef<any>, div: HTMLDivElement) {
    this.attesa2 = true
    this.mockupSelezionato = item.toString()
    this.pagine = this.mockups[item-1]
    let image = new Image();
    image.src = this.pagine.pagine["intro"];
    image.onload = () => {
      this.attesa2 = false;
      this.modale = this.modalService.open(content, { size: 'lg', centered: true });
      this.modale.dismissed.subscribe(data => {
        this.closeModal()
      })
    }
  }

  showOverlay(item: number) {
    this.isOverlayVisible[item] = true;
  }

  hideOverlay(item: number) {
    this.isOverlayVisible[item] = false;
  }

  ariaValueText(current: number, max: number) {
    return `${current} out of ${max} hearts`;
  }
  
  openBook() {
    const book = document.getElementById('book');
    if (book) book.style.transform = 'translateX(50%)';
    // prevBtn.style.transform = "translateX(-180px)";
    // nextBtn.style.transform = "translateX(180px)";
  }

  closeModal() {
    const book = document.getElementById('book');
    if (!book) {
      return
    }
    this.currentLocation = 1;
    book.style.transform = 'translateX(0%)';
  }

  saveMockup() {
    if (this.mockupSelezionato) {
      localStorage.setItem('mockup', this.mockupSelezionato)
    }
  }

  closeBook(isAtBeginning: boolean) {
    this.prevBtn = document.getElementById('prev-btn') as HTMLDivElement;
    this.nextBtn = document.getElementById('next-btn') as HTMLDivElement;
    const book = document.getElementById('book');
    if (!book) {
      return
    }
    if (isAtBeginning) {
      book.style.transform = 'translateX(0%)';
    } else {
      book.style.transform = 'translateX(100%)';
    }

    if (this.prevBtn) this.prevBtn.style.transform = 'translateX(0px)';
    this.nextBtn.style.transform = 'translateX(0px)';
  }

  goNextPage() {
    this.paper1 = document.getElementById('p1') as HTMLDivElement;
    this.paper2 = document.getElementById('p2') as HTMLDivElement;
    this.paper3 = document.getElementById('p3') as HTMLDivElement;
    if (this.currentLocation < (this.maxLocation +1)) {
      let paper = document.getElementById(`p${this.currentLocation}`) as HTMLDivElement;
      console.log('p'+this.currentLocation)
      console.log(this.currentLocation)
      if (this.currentLocation == (this.maxLocation)) {
        paper.classList.add('flipped')
        paper.style.zIndex = this.currentLocation.toString();
        this.closeBook(false);
      } else if (this.currentLocation == 1) {
        this.openBook();
        paper.classList.add('flipped')
        paper.style.zIndex = this.currentLocation.toString();
      } else {
        paper.classList.add('flipped')
        paper.style.zIndex = this.currentLocation.toString();
      }
      // switch (this.currentLocation) {
      //   case 1:
      //     this.openBook();
      //     this.paper1.classList.add('flipped');
      //     this.paper1.style.zIndex = '1';
      //     break;
      //   case 2:
      //     this.paper2.classList.add('flipped');
      //     this.paper2.style.zIndex = '2';
      //     break;
      //   case 3:
      //     this.paper3.classList.add('flipped');
      //     this.paper3.style.zIndex = '3';
      //     this.closeBook(false);
      //     break;
      //   default:
      //     throw new Error('unkown state');
      // }
      this.currentLocation++;
    }
  }

  goPrevPage() {
    this.paper1 = document.getElementById('p1') as HTMLDivElement;
    this.paper2 = document.getElementById('p2') as HTMLDivElement;
    this.paper3 = document.getElementById('p3') as HTMLDivElement;
    if (this.currentLocation > 1) {
      let paper = document.getElementById(`p${this.currentLocation-1}`) as HTMLDivElement;
      if (this.currentLocation == (this.maxLocation + 1)) {
        this.openBook();
        paper.classList.remove('flipped')
        paper.style.zIndex = "1"
      } else if (this.currentLocation == 2) {
        this.closeBook(true);
        paper.classList.remove('flipped')
        paper.style.zIndex = this.maxLocation.toString();
      } else {
        paper.classList.remove('flipped')
        paper.style.zIndex = (this.maxLocation - (this.currentLocation - 2)).toString();
      }
      // switch (this.currentLocation) {
      //   case 2:
      //     this.closeBook(true);
      //     this.paper1.classList.remove('flipped');
      //     this.paper1.style.zIndex = "3";
      //     break;
      //   case 3:
      //     this.paper2.classList.remove('flipped');
      //     this.paper2.style.zIndex = "2";
      //     break;
      //   case 4:
      //     this.openBook();
      //     this.paper3.classList.remove('flipped');
      //     this.paper3.style.zIndex = "1";
      //     break;
      //   default:
      //     throw new Error('unkown state');
      // }

      this.currentLocation--;
    }
  }
}
