import { Injectable } from '@angular/core';
import { Toast } from '../interface/toast.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts: Toast[] = []
  private toastsSub = new BehaviorSubject<Toast[]>([])
  $toasts = this.toastsSub.asObservable();
  constructor() { }

  show(toast: Toast) {
    this.toasts.push(toast);
    this.toastsSub.next(this.toasts)
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
    this.toastsSub.next(this.toasts)
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
    this.toastsSub.next(this.toasts)
  }
}
