import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { ProcessoComponent } from './component/processo/processo.component';
import { MokupsectionComponent } from './component/home/mokupsection/mokupsection.component';
import { ScegliPacchettoComponent } from './component/processo/scegli-pacchetto/scegli-pacchetto.component';
import { PersonalizzaComponent } from './component/processo/personalizza/personalizza.component';
import { AcquistaComponent } from './component/processo/acquista/acquista.component';
import { ProfiloComponent } from './component/profilo/profilo.component';
import { AuthGuard } from './guard/auth.guard';
import { ContattattaciComponent } from './component/contattattaci/contattattaci.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { RecensioniComponent } from './component/recensioni/recensioni.component';
import { PacchettoGuard } from './guard/pacchetto.guard';
import { PersonalizzaGuard } from './guard/personalizza.guard';
import { AcquistaGuard } from './guard/acquista.guard';
import { CambiamentiPersonalizzazioneGuard } from './guard/cambiamenti-personalizzazione.guard';
import { DettagliPersonalizzazioneComponent } from './component/dettagli-personalizzazione/dettagli-personalizzazione.component';
import { PagineQrcodeComponent } from './component/pagine-qrcode/pagine-qrcode.component';
import { PdfQrcodeComponent } from './component/pdf-qrcode/pdf-qrcode.component';
import { DomandeComponent } from './component/domande/domande.component';
import { RecuperoPasswordComponent } from './component/recupero-password/recupero-password.component';
import { PagamentoComponent } from './component/processo/pagamento/pagamento.component';

const routes: Routes = [
  {path:"", component: HomeComponent},
  {path:"acquisto", component: ProcessoComponent, children: [
    {path:"scegli-tema", component: MokupsectionComponent},
    {path:"scegli-pacchetto", component: ScegliPacchettoComponent, canActivate: [PacchettoGuard]},
    {path:"personalizza", component: PersonalizzaComponent, canActivate: [PersonalizzaGuard], canDeactivate: [CambiamentiPersonalizzazioneGuard]},
    {path:"buy", component: AcquistaComponent, canActivate: [AuthGuard, AcquistaGuard]}
  ]},
  {path:"profilo", component: ProfiloComponent, canActivate: [AuthGuard]},
  { path: 'admin', loadChildren: () => import('./component/admin/admin.module').then(m => m.AdminModule) },
  { path: "contattattaci", component: ContattattaciComponent},
  { path: "recupero-password", component: RecuperoPasswordComponent},
  { path: "processo/pagamento", component: PagamentoComponent},
  { path: "recensioni", component: RecensioniComponent},
  { path: "domande", component: DomandeComponent},
  { path: "ordine/:id", component: DettagliPersonalizzazioneComponent},
  { path: "guida/:nome/:id", component: PagineQrcodeComponent},
  { path: "guida/:id", component: PdfQrcodeComponent, canActivate: [AuthGuard]},
  { path: '**', component: PageNotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
