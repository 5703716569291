import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Prodotto } from 'src/app/interface/prodotto.interface';
import { EnvironmentService } from 'src/app/service/environment.service';
import { TemplateRefService } from 'src/app/service/template-ref.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-scegli-pacchetto',
  templateUrl: './scegli-pacchetto.component.html',
  styleUrls: ['./scegli-pacchetto.component.scss']
})
export class ScegliPacchettoComponent implements OnInit {
  innerWidth!: number;
    pacchetti!: Prodotto[];
    selezionato = {
      id: 1,
      titolo: "Basic",
      descrizione: "Scegli un tema di partenza tra quelli proposti. Completa il form fornendo i dati della sua struttura",
      traduzione: "1 lingua a scelta",
      copiaFisica: "0",
      qrcode: "0",
      pdf: "0",
      img: ""
    }

    constructor(private router: Router, private templateRefSrv: TemplateRefService, private toastService: ToastService, private environmentSrv: EnvironmentService) {
      let pacchetto = localStorage.getItem('pacchetto')
      this.environmentSrv.getAllPacchetti().subscribe((pacchetti) => {
        this.pacchetti = pacchetti
        this.pacchetti.forEach((value, index2) => {
          if (pacchetto && Number(pacchetto) == value.id) {
            this.selezionato.id = value.id;
            this.selezionato.titolo = value.nome;
            this.selezionato.traduzione = value.traduzione;
            this.selezionato.copiaFisica = value.copiaFisica;
            this.selezionato.qrcode = value.qrcode;
            this.selezionato.pdf = value.pdf;
            this.selezionato.descrizione = value.descrizione;
            value.selezionato = true;
          } else {
            value.selezionato = false;
          }
        })
      })
    }

    selezionaPacchetto(name: string, index: number) {
      if(index != 0) {
        if (index == 1) {
          this.toastService.show({template: this.templateRefSrv.getTemplateRef('prodottoPremiumNonDisponibile'), classname: 'bg-secondary text-white', delay: 10000})
        } else {
          this.toastService.show({template: this.templateRefSrv.getTemplateRef('prodottoFullCustomNonDisponibile'), classname: 'bg-secondary text-white', delay: 10000})
          // this.router.navigate(
          //   ['/contattattaci'], {
          //   queryParams: {
          //     preventivo: true
          //   },
          //   queryParamsHandling: 'merge'
          // })
        }
        return;
      }
      this.selezionato.id = this.pacchetti[index].id;
      this.selezionato.titolo = name;
      this.selezionato.traduzione = this.pacchetti[index].traduzione;
      this.selezionato.copiaFisica = this.pacchetti[index].copiaFisica;
      this.selezionato.qrcode = this.pacchetti[index].qrcode;
      this.selezionato.pdf = this.pacchetti[index].pdf;
      this.selezionato.descrizione = this.pacchetti[index].descrizione
      this.pacchetti.forEach((value, index2) => {
        if (index == index2) {
          value.selezionato = true;
        } else {
          value.selezionato = false;
        }
      })
    }

    procedi() {
      localStorage.setItem('pacchetto', this.selezionato.id.toString())
      this.router.navigate(['/acquisto/personalizza'])
    }

    ngOnInit(): void {
      this.innerWidth = window.innerWidth;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event:any) {
      this.innerWidth = window.innerWidth;
    }
}
