<footer
  class="text-center text-lg-start classe"
  style="background-color: #929fba"
>
  <!-- Grid container -->
  <div class="container-md g-0 py-4 pb-0">
    <!-- Section: Links -->
    <section class="">
      <!--Grid row-->
      <div class="row m-0">
        <!-- Grid column -->
        <div class="col-md-4 col-lg-4 col-xl-3 mt-3 px-5 px-md-0 pb-3 pb-md-0">
          <h6 class="text-uppercase mb-4 fw-bold">WelcomeBook.it</h6>
          <p>
            Welcomebook è la soluzione che ti permette di realizzare con facilità una guida personalizzata della tua struttura, che i tuoi ospiti potranno sfogliare
          </p>
        </div>
        <!-- Grid column -->

        <hr class="w-100 clearfix d-md-none" />

        <!-- Grid column -->
        <div class="col-md-4 col-lg-4 col-xl-2 mx-auto mt-3 pb-3 pb-md-0" style="width: max-content;">
          <h6 class="text-uppercase mb-4 fw-bold">Paccheti</h6>
          <p>
            <a class="fot-link">Basic</a>
          </p>
          <p>
            <a class="fot-link">Premium</a>
          </p>
          <p>
            <a class="fot-link">FullCustum</a>
          </p>
        </div>
        <!-- Grid column -->

        <hr class="w-100 clearfix d-md-none" />

        <!-- Grid column -->
        <div class="col-md-4 col-lg-4 col-xl-3 float-end mt-3">
          <div class="ms-lg-5">
            <h6 class="text-uppercase mb-4 font-weight-bold fw-bold">Contatti</h6>
            <p><i class="fas fa-home mr-3"></i> Sassari, 07100</p>
            <p><i class="fas fa-envelope mr-3"></i> info&#64;welcomebook.it</p>
            <p><i class="fas fa-phone mr-3"></i> + 333 1748911</p>
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!--Grid row-->
    </section>
    <!-- Section: Links -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3 mt-3 classee" style="background-color: #8d99ae">
    © 2024 Copyright:
    <a class="text-white fot-link" href="https://welcomebook.it/"
      >welcomebook.it</a
    >
  </div>
  <!-- Copyright -->
</footer>

