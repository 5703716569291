<div class="container px-5 pt-2 pb-3 mt-4 mb-4">
    <div class="row d-none d-lg-flex">
        <div class="col-7" style="word-wrap: break-word;">
            <h3 class="fw-bold">Come funziona il servizio</h3>
            <p class="pe-5 grigio">Seleziona il Tema che più si addice alla tua struttura, seleziona il pacchetto di servizi che preferisci, inserisci le informazioni e le immagini che vuoi visualizzare sulla tua guida. In poco tempo avrai creato la tua guida personalizzata </p>
            <br>
            <h3 class="fw-bold">Pacchetti disponibili</h3>
            <ul class="pe-5 grigio">
                <li><span class="fw-bolder">Copia Digitale</span> | Copia PDF + Copia QRCode</li>
                <li><span class="fw-bolder">Copia Fisica</span> | Copia PDF + Copia QRCode + Copia Fisica</li>
                <!-- <li><span class="fw-bolder">Premium</span> | Copia PDF + Copia digitale QRCode + Copia Fisica + Traduzione </li> -->
                <li><span class="fw-bolder">Copia Custom</span> | Servizio personalizzato solo su richiesta</li>
            </ul>
        </div>
        <div class="col-5 d-flex justify-content-end align-items-center">
            <img src="assets/img/introsectionprovisoria.jpg" alt="Intro section provvisoria" class="w-100" />
            <!-- <div class="container bg-secondary rounded-2 w-100 h-75 m-0"></div> -->
        </div>
    </div>
    <div class="row d-lg-none">
        <div class="col-12">
            <h3 class="fw-bold">Come funziona il servizio</h3>
            <p class="pe-5 grigio">Seleziona il Tema che più si addice alla tua struttura, seleziona il pacchetto di servizi che preferisci, inserisci le informazioni e le immagini che vuoi visualizzare sulla tua guida. In poco tempo avrai creato la tua guida personalizzata </p>
        </div>
        <div class="col-12 d-flex justify-content-end align-items-center my-2">
            <img src="assets/img/introsectionprovisoria.jpg" alt="Intro section provvisoria" class="w-100" />
            <!-- <div class="container bg-secondary rounded-2 w-100 h-75 m-0"></div> -->
        </div>
        <div class="col-12 mt-3" style="word-wrap: break-word;">
            <h3 class="fw-bold">Pacchetti disponibili</h3>
            <ul class="grigio">
                <li><span class="fw-bolder">Copia Digitale</span> | PDF + Copia QRCode</li>
                <li><span class="fw-bolder">Copia Fisica</span> | PDF + Copia QRCode + Copia Fisica</li>
                <!-- <li><span class="fw-bolder">Premium</span> | Copia PDF + Copia digitale QRCode + Copia Fisica + Traduzione </li> -->
                <li><span class="fw-bolder">Copia Custom</span> | Servizio personalizzato su richiesta</li>
            </ul>
        </div>
    </div>
</div>