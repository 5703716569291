import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdineEasy } from 'src/app/interface/ordine-easy.interface';
import { OrdineService } from 'src/app/service/ordine.service';
import { TemplateRefService } from 'src/app/service/template-ref.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-pagine-qrcode',
  templateUrl: './pagine-qrcode.component.html',
  styleUrls: ['./pagine-qrcode.component.scss']
})
export class PagineQrcodeComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: any;
  pagina!: OrdineEasy;
  count = 2;
  pagine: {
    indice: number;
    hostpage: number | null;
    istruzioni: number | null;
    abouthouse: number | null;
    gethouse: number | null;
    houserules: number | null;
    houseguide: number | null;
    wifi: number | null;
    piscina: number | null;
    gettingaround: number | null;
    placetosee: number | null;
    placetosee2: number | null;
    aboutcity: number | null;
    placeeat: number | null;
    emergency: number | null;
    feedback: number | null;
    extra: number | null;
    extra2: number | null;
  } = {
    indice: 2,
    hostpage: null,
    istruzioni: null,
    abouthouse: null,
    gethouse: null,
    houserules: null,
    houseguide: null,
    wifi: null,
    piscina: null,
    gettingaround: null,
    placetosee: null,
    placetosee2: null,
    aboutcity: null,
    placeeat: null,
    emergency: null,
    feedback: null,
    extra: null,
    extra2: null
  };
  personalizzazione: {[key:string]: any} = {}
  pdf: {tipo: string, url: string}[] = [];
  notFound = false;
  linguaSelezionata!: string;
  caricato = false;
  pdfCaricato = false;

  constructor(private ordineSrv: OrdineService, private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let id = params["id"];
      this.ordineSrv.findOrdineByNumeroOrdine(id).subscribe((data: OrdineEasy) => {
        if (data) {
          this.pagina = data;
          if (typeof this.pagina.personalizzazione == "string") this.personalizzazione = JSON.parse(this.pagina.personalizzazione);
          this.personalizzazione['lingue'] = JSON.parse(this.personalizzazione['lingue'])
          this.personalizzazione['copie'] = JSON.parse(this.personalizzazione['copie'])
          this.linguaSelezionata = this.personalizzazione['linguaDefault'];
          if (typeof this.pagina.pdf == 'string') {
            this.pdf = JSON.parse(this.pagina.pdf)
            let url = "";
            this.pdf.forEach((pdf) => {
              if (pdf.tipo == this.linguaSelezionata) {
                url = pdf.url;
              }
            })
            setTimeout(() => {
              this.pdfViewerOnDemand.pdfSrc = url;
              this.pdfViewerOnDemand.refresh();
            }, 1000)
          }
          this.count++;
          if (this.personalizzazione['pagina2']) {
            this.pagine.hostpage = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina3']) {
            this.pagine.istruzioni = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina4']) {
            this.pagine.abouthouse = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina5']) {
            this.pagine.gethouse = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina6']) {
            this.pagine.houserules = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina7']) {
            this.pagine.houseguide = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina8']) {
            this.pagine.wifi = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina9']) {
            this.pagine.piscina = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina10']) {
            this.pagine.gettingaround = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina11']) {
            this.pagine.placetosee = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina12']) {
            this.pagine.placetosee2 = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina13']) {
            this.pagine.aboutcity = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina14']) {
            this.pagine.placeeat = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina15']) {
            this.pagine.emergency = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina16']) {
            this.pagine.feedback = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina17']) {
            this.pagine.extra = this.count;
            this.count++;
          }
          if (this.personalizzazione['pagina18']) {
            this.pagine.extra2 = this.count;
            this.count++;
          }
        }
        this.caricato = true
      }, (err) => {
        // alert('Non trovato');
        this.router.navigate(['/pagina-non-trovata'])
        this.notFound = true;
      })
    })
  }

  caricamentoPdf(event: any) {
    const iframeDocument = this.pdfViewerOnDemand.iframe.nativeElement.contentDocument || this.pdfViewerOnDemand.iframe.nativeElement.contentWindow.document;
    iframeDocument.addEventListener('touchstart', () => {
      this.pdfViewerOnDemand.nativeElement.focus();
    })
  }

  cambioLingua(lingua: string) {
    this.linguaSelezionata = lingua;
    this.pdf.forEach((pdf) => {
      if (pdf.tipo == this.linguaSelezionata) {
        setTimeout(() => {
          this.pdfViewerOnDemand.pdfSrc = pdf.url;
          this.pdfViewerOnDemand.refresh();
        }, 1000)
      }
    })
  }

  pageRendered() {
    this.pdfViewerOnDemand.pdfViewer.currentScaleValue = 'page-fit';
  }
}
