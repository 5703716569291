<div class="container-fluid sfondo position-relative">
    <div class="mx-auto rounded-4 bg-white px-3 px-md-6 py-4 w-90 w-lg-40 position-absolute top-50 start-50 translate-middle">
        <div class="d-flex justify-content-between align-items-center my-2">
            <h2 class="fw-bolder ">Contattaci</h2>
            <div>
                <p class="m-0 p-0" *ngIf="valido">compila il form</p>
                <p class="m-0 p-0" *ngIf="!valido"><span class="spinner-colore">*</span>inserisci dati mancanti</p>
            </div>
        </div>
        <form [formGroup]="contatto" (ngSubmit)="inviaAssistenza()">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="nomeCompleto" name="nomeCompleto" [class.errore]="nomeCompleto?.invalid && (nomeCompleto?.dirty || nomeCompleto?.touched || !valido) && nomeCompleto?.errors?.['required']" required formControlName="nomeCompleto">
                <label for="nomeCompleto">Nome Completo<span class="spinner-colore">*</span></label>
            </div>
            <!-- <div *ngIf="nomeCompleto?.invalid && (nomeCompleto?.dirty || nomeCompleto?.touched)">
                <small *ngIf="nomeCompleto?.errors?.['required']">Nome Completo Richiesto.</small>
            </div> -->
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="tel" class="form-control" id="telefono" name="telefono" formControlName="telefono" [class.errore]="telefono?.invalid && (telefono?.dirty || telefono?.touched) && telefono?.errors?.['pattern']">
                        <label for="telefono">Telefono</label>
                    </div>
                    <!-- <div *ngIf="telefono?.invalid && (telefono?.dirty || telefono?.touched)">
                        <small *ngIf="telefono?.errors?.['pattern']">Inserisci un numero di telefono valido (10-15 numeri).</small>
                    </div> -->
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="email" name="email" required formControlName="email" [class.errore]="email?.invalid && (email?.dirty || email?.touched || !valido) && (email?.errors?.['required'] || email?.errors?.['email'])">
                        <label for="email">Email<span class="spinner-colore">*</span></label>
                    </div>
                    <!-- <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                        <small *ngIf="email?.errors?.['required']">Email richiesta.</small>
                        <small *ngIf="email?.errors?.['email']">Inserisci una mail valida.</small>
                      </div> -->
                </div>
            </div>
            <div class="form-floating mb-3">
                <select id="tipoAssistenza" class="form-select" name="tipoAssistenza" required formControlName="tipoAssistenza" [class.errore]="tipoAssistenza?.invalid && (tipoAssistenza?.dirty || tipoAssistenza?.touched || !valido) && (tipoAssistenza?.errors?.['required'])">
                    <option value="preventivo">Richiesta Preventivo</option>
                    <option value="ordini">Assistenza Ordini</option>
                    <option value="sicurezza">Sicurezza Account</option>
                </select>
                <label for="tipoAssistenza">Tipo di Assistenza<span class="spinner-colore">*</span></label>
            </div>
            <!-- <div *ngIf="tipoAssistenza?.invalid && (tipoAssistenza?.dirty || tipoAssistenza?.touched)">
                <small *ngIf="tipoAssistenza?.errors?.['required']">Tipo di assistenza richiesta.</small>
              </div> -->
            <div class="form-floating mb-3">
                <textarea name="descrizione" id="descrizione" class="form-control" style="height: 15vh; resize: none;" formControlName="descrizione"></textarea>
                <label for="descrizione">Descrizione</label>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-danger px-5 rounded-3 fs-6 fs-md-5 my-2 fw-semibold" type="submit" >Invia</button>
            </div>
        </form>
    </div>
</div>
<app-domandesection></app-domandesection>