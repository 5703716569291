<ng-template #immagineZoom let-modal style="z-index: 12000000!important;">
  <div class="modal-body border-0 p-0 m-0" style="background-color: transparent!important;">
    <img [src]="immagineZoommata" class="w-100" alt="Immagine zoommata" />
  </div>
</ng-template>

<form #personalizza="ngForm">
  <ng-template #content let-modal style="z-index: 12000000!important;">
    <div class="modal-header navlaterale text-white border-0">
      <h4 class="modal-title" id="modal-basic-title">Gestione Pagine <i class="bi bi-info-square fs-6 clicca"triggers="mouseenter:mouseleave" ngbPopover="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam ratione voluptatum consequatur culpa quo sed magni! Magnam et enim laudantium! Omnis laboriosam veniam corrupti cumque voluptate nam dolores molestiae autem." popoverTitle="Info" placement="top"></i></h4>
      <button
        type="button"
        class="btn-close btn-close-white"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body navlaterale text-white border-0">
      <div class="row mb-1">
        <div class="col-6">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="pagina1"
              (change)="changePage(1, $event)"
              [(ngModel)]="model.pagina1"
              name="pagina1"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6 fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina1"
              >Copertina</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina3"
              (change)="changePage(3)"
              [(ngModel)]="model.pagina3"
              name="pagina3"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina3"
              >Istruzioni</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina5"
              (change)="changePage(5)"
              [(ngModel)]="model.pagina5"
              name="pagina5"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina5"
              >Arrivare alla Casa</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina7"
              (change)="changePage(7)"
              [(ngModel)]="model.pagina7"
              name="pagina7"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina7"
              >Guida Casa</label
            >
          </div>
        </div>
        <div class="col-6">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina2"
              (change)="changePage(2)"
              [(ngModel)]="model.pagina2"
              name="pagina2"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina2"
              >Pagina dell'Host</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina4"
              (change)="changePage(4)"
              [(ngModel)]="model.pagina4"
              name="pagina4"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina4"
              >Info sulla Casa</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina6"
              (change)="changePage(6)"
              [(ngModel)]="model.pagina6"
              name="pagina6"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina6"
              >Regole della Casa</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina8"
              (change)="changePage(8)"
              [(ngModel)]="model.pagina8"
              name="pagina8"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina8"
              >Wi-Fi</label
            >
          </div>
        </div>
        <div class="col-6">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina9"
              (change)="changePage(9)"
              [(ngModel)]="model.pagina9"
              name="pagina9"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina9"
              >Piscina & Idromassaggio</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina11"
              (change)="changePage(11)"
              [(ngModel)]="model.pagina11"
              name="pagina11"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina11"
              >Posti da Vedere</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina13"
              (change)="changePage(13)"
              [(ngModel)]="model.pagina13"
              name="pagina13"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina13"
              >Info sulla Città</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina15"
              (change)="changePage(15)"
              [(ngModel)]="model.pagina15"
              name="pagina15"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina15"
              >Info Emergenza</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina17"
              (change)="changePage(17)"
              [(ngModel)]="model.pagina17"
              name="pagina17"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina17"
              >Pagina Extra</label
            >
          </div>
        </div>
        <div class="col-6">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina10"
              (change)="changePage(10)"
              [(ngModel)]="model.pagina10"
              name="pagina10"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina10"
              >Come Muoversi</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina12"
              (change)="changePage(12)"
              [(ngModel)]="model.pagina12"
              name="pagina12"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina12"
              >Posti da Vedere "img"</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina14"
              (change)="changePage(14)"
              [(ngModel)]="model.pagina14"
              name="pagina14"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina14"
              >Posti per Mangiare</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina16"
              (change)="changePage(16)"
              [(ngModel)]="model.pagina16"
              name="pagina16"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina16"
              >Feedback</label
            >
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked
              id="pagina18"
              (change)="changePage(18)"
              [(ngModel)]="model.pagina18"
              name="pagina18"
            />
            <label
              class="form-check-label fs-lg-8 fs-xl-7 fs-xxl-6"
              for="pagina18"
              >Pagina Extra</label
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal-footer navlaterale text-white border-0 d-flex justify-content-center"
    >
      <button
        type="button"
        class="btn btn-outline-light"
        (click)="modal.close('Save click')"
      >
        Salva
      </button>
    </div>
  </ng-template>

  <div class="container-fluid container-md px-3 px-md-5 pt-3 pb-3">
    <h3 class="pb-2 fw-bold mt-4">Personalizza</h3>
    <div class="row py-2 px-md-4">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="rounded-5 navlaterale text-white py-4 px-5 w-90">
          <h4 class="mb-4 mt-1 fs-lg-6 fs-xl-5 fs-xxl-4">{{pacchetto.nome}}</h4>
          <div class="w-100 d-flex justify-content-center mb-4">
            <img
              [src]="pagine['intro']"
              alt="Immagine test"
              class="w-60 border border-1 border-white"
              *ngIf="pagine['intro']"
            />
          </div>
          <h4 class="mb-4 fs-lg-6 fs-xl-5 fs-xxl-4">Gestione Pagine <i class="bi bi-info-square fs-6 clicca"triggers="mouseenter:mouseleave" ngbPopover="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam ratione voluptatum consequatur culpa quo sed magni! Magnam et enim laudantium! Omnis laboriosam veniam corrupti cumque voluptate nam dolores molestiae autem." popoverTitle="Info" placement="top"></i></h4>
          <div class="row mb-1">
            <div class="col-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="pagina1"
                  name="pagina1"
                  (change)="changePage(1, $event)"
                  [(ngModel)]="model.pagina1"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina1"
                  >Copertina</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina3"
                  name="pagina3"
                  (change)="changePage(3)"
                  [(ngModel)]="model.pagina3"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina3"
                  >Istruzioni</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina5"
                  name="pagina5"
                  (change)="changePage(5)"
                  [(ngModel)]="model.pagina5"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina5"
                  >Arrivare alla Casa</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina7"
                  name="pagina7"
                  (change)="changePage(7)"
                  [(ngModel)]="model.pagina7"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina7"
                  >Guida Casa</label
                >
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina2"
                  name="pagina2"
                  (change)="changePage(2)"
                  [(ngModel)]="model.pagina2"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina2"
                  >Pagina dell'Host</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina4"
                  name="pagina4"
                  (change)="changePage(4)"
                  [(ngModel)]="model.pagina4"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina6"
                  >Info sulla Casa</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina6"
                  name="pagina6"
                  (change)="changePage(6)"
                  [(ngModel)]="model.pagina6"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina6"
                  >Regole della Casa</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina8"
                  name="pagina8"
                  (change)="changePage(8)"
                  [(ngModel)]="model.pagina8"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina8"
                  >Wi-Fi</label
                >
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina9"
                  name="pagina9"
                  (change)="changePage(9)"
                  [(ngModel)]="model.pagina9"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina9"
                  >Piscina & Idromassaggio</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina11"
                  name="pagina11"
                  (change)="changePage(11)"
                  [(ngModel)]="model.pagina11"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina11"
                  >Posti da Vedere</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina13"
                  name="pagina13"
                  (change)="changePage(13)"
                  [(ngModel)]="model.pagina13"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina13"
                  >Info sulla Città</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina15"
                  name="pagina15"
                  (change)="changePage(15)"
                  [(ngModel)]="model.pagina15"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina15"
                  >Info di Emergenza</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina17"
                  (change)="changePage(17)"
                  [(ngModel)]="model.pagina17"
                  name="pagina17"
                />
                <label
                  class="form-check-label fs-lg-8 fs-xl-7"
                  for="pagina17"
                  >Pagina Extra</label
                >
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina10"
                  name="pagina10"
                  (change)="changePage(10)"
                  [(ngModel)]="model.pagina10"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina10"
                  >Come Muoversi</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina12"
                  name="pagina12"
                  (change)="changePage(12)"
                  [(ngModel)]="model.pagina12"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina12"
                  >Posti da Vedere "img"</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina14"
                  name="pagina14"
                  (change)="changePage(14)"
                  [(ngModel)]="model.pagina14"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina14"
                  >Posti per Mangiare</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina16"
                  name="pagina16"
                  (change)="changePage(16)"
                  [(ngModel)]="model.pagina16"
                />
                <label class="form-check-label fs-lg-8 fs-xl-7" for="pagina16"
                  >Feedback</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  checked
                  id="pagina18"
                  (change)="changePage(18)"
                  [(ngModel)]="model.pagina18"
                  name="pagina18"
                />
                <label
                  class="form-check-label fs-lg-8 fs-xl-7"
                  for="pagina18"
                  >Pagina Extra</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-lg-none col-12 py-3 px-3 mx-1">
        <div class="rounded-5 navlaterale text-white py-4 px-5 w-100">
          <div class="row">
            <div
              class="col-5 text-center d-flex justify-content-center align-items-center"
            >
              <img
                [src]="pagine['intro']"
                alt="Immagine test"
                class="w-80 w-sm-80 w-md-50 border border-1 border-white"
                *ngIf="pagine['intro']"
              />
            </div>
            <div
              class="col-7 px-4 pe-md-6 d-flex flex-column justify-content-center"
            >
              <h4>Gestione Pagine</h4>
              <p class="fs-7 fs-md-6">Pagine Selezionate: <span>12</span></p>
              <button
                class="btn w-100 bg-black border border-1 border-white text-white"
                type="button"
                (click)="open(content)"
              >
                Modifica
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-1"></div> -->
      <div class="col-12 col-lg-8 py-4 pb-0 my-2 px-4 px-md-3">
        <div class="d-flex justify-content-between mb-2">
          <h3 class="fw-semibold">
            Imposta la lingua di scrittura 
          </h3>
          <p>Seleziona</p>
        </div>
        <p>Seleziona la lingua con la quale compilerai il form</p>
        <div class="row py-3">
          <div class="col-12 col-md-6 mb-2">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h6 class="fw-semibold">Lingua di Scrittura</h6>
              </div>
              <div class="col-6">
                <select
                  class="form-select"
                  [(ngModel)]="model.linguaDefault"
                  name="linguaDefault"
                  #linguaDefault
                  (change)="changeLinguaDef(linguaDefault.value)"
                  [ngClass]="(model.linguaDefault =='-1' && controllo) ? 'is-invalid' : ''"
                >
                  <option selected value="-1">Seleziona</option>
                  <option value="italiano">Italiano</option>
                  <option value="inglese">Inglese</option>
                  <option value="tedesco">Tedesco</option>
                  <option value="francese">Francese</option>
                  <option value="spagnolo">Spagnolo</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="d-flex justify-content-between mb-2">
            <h3 class="fw-semibold">
              Multilingua <i class="bi bi-info-square fs-6 clicca"triggers="mouseenter:mouseleave" ngbPopover="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam ratione voluptatum consequatur culpa quo sed magni! Magnam et enim laudantium! Omnis laboriosam veniam corrupti cumque voluptate nam dolores molestiae autem." popoverTitle="Info" placement="top"></i>
            </h3>
            <p *ngIf="totaleTraduzioni == 0">Seleziona Lingue</p>
            <p *ngIf="totaleTraduzioni > 0">+{{totaleTraduzioni}} €</p>
          </div>
          <div>
            <p>
              In questa sezione puoi selezionare le lingue in cui desideri visualizzare la guida.<br /> Ogni traduzione aggiuntiva alla "Lingua di Scrittura" ha un costo di 10€.<br /> E' consigliato selezionare solo le lingue più parlate dai tuoi ospiti. 
            </p>
          </div>
  
          <div class="d-flex justify-content-md-evenly flex-column flex-row-md">
            <button
              type="button"
              [ngClass]="(lingue['italiano'] || model.linguaDefault == 'italiano') ? 'btnLingueActive' : ' '"
              (click)="changeLinguaTrad('italiano')"
              class="btnLingue border rounded-4 py-1 px-4 my-2"
              [disabled]="model.linguaDefault == 'italiano' || model.linguaDefault == '-1'"
            >
              Italiano
            </button>
  
            <button
              type="button"
              [ngClass]="(lingue['inglese'] || model.linguaDefault == 'inglese') ? 'btnLingueActive' : ' '"
              (click)="changeLinguaTrad('inglese')"
              class="btnLingue border rounded-4 py-1 px-4  my-2"
              [disabled]="model.linguaDefault == 'inglese' || model.linguaDefault == '-1'"
            >
              Inglese
            </button>
  
            <button
              type="button"
              [ngClass]="(lingue['tedesco'] || model.linguaDefault == 'tedesco') ? 'btnLingueActive' : ' '"
              (click)="changeLinguaTrad('tedesco')"
              class="btnLingue border rounded-4 py-1 px-4  my-2"
              [disabled]="model.linguaDefault == 'tedesco' || model.linguaDefault == '-1'"
            >
              Tedesco
            </button>
  
            <button
              type="button"
              [ngClass]="(lingue['francese'] || model.linguaDefault == 'francese') ? 'btnLingueActive' : ' '"
              (click)="changeLinguaTrad('francese')"
              class="btnLingue border rounded-4 py-1 px-4  my-2"
              [disabled]="model.linguaDefault == 'francese' || model.linguaDefault == '-1'"
            >
              Francese
            </button>
  
            <button
              type="button"
              [ngClass]="(lingue['spagnolo'] || model.linguaDefault == 'spagnolo') ? 'btnLingueActive' : ' '"
              (click)="changeLinguaTrad('spagnolo')"
              class="btnLingue border rounded-4 py-1 px-4  my-2"
              [disabled]="model.linguaDefault == 'spagnolo' || model.linguaDefault == '-1'"
            >
              Spagnolo
            </button>
          </div>
  
          <div *ngIf="!basic">
            <div class="d-flex justify-content-between mt-4 mb-2">
              <h3 class="fw-semibold">
                Gestisci Copie Fisiche <i class="bi bi-info-square fs-6 clicca" triggers="mouseenter:mouseleave" ngbPopover="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam ratione voluptatum consequatur culpa quo sed magni! Magnam et enim laudantium! Omnis laboriosam veniam corrupti cumque voluptate nam dolores molestiae autem." popoverTitle="Info" placement="top"></i>
              </h3>
              <p *ngIf="totaleCopie == 0">traduzione inclusa</p>
              <p *ngIf="totaleCopie > 0">+{{totaleCopie}} €</p>
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum
              obcaecati iusto magni amet similique sequi illo! Quas neque, officia
              cupiditate provident amet iste enim, tenetur perferendis illum qui
              sed facilis.
            </p>
            <div class="row d-flex align-items-center mb-3" *ngFor="let copia of copie; let i = index">
              <div class="col-9">
                <div
                  class="d-flex align-items-center justify-content-between border border-1 rounded-3 p-4 py-3 btnLingue"
                >
                  <div>
                    <i class="bi bi-journal-text fs-5"></i>
                    <span class="ms-2" *ngIf="i == 0">Copia Inclusa</span>
                  </div>
                  <div class="d-flex">
                    <div class="form-floating w-50 mx-2">
                      <select 
                        class="form-select"
                        name="lingua"
                        id="lingua"
                        #lingua
                        (change)="changeLingua(i, lingua.value)"
                        [disabled]="model.linguaDefault == '-1'"
                        [ngClass]="(copia.lingua == '-1' && controllo) ? 'is-invalid' : ''"
                      >
                        <option value="-1"></option>
                        <option value="italiano">Italiano</option>
                        <option value="inglese">Inglese</option>
                        <option value="tedesco">Tedesco</option>
                        <option value="francese">Francese</option>
                        <option value="spagnolo">Spagnolo</option>
                      </select>
                      <label for="lingua" class="disabled">Imposta Lingua</label>
                    </div>
                    <div class="form-floating w-50 mx-2">
                      <input class="form-control" name="copie" id="copie" type="number" min="1" value="1" [disabled]="i === 0" #copieNum (change)="changeCopie(i, copieNum.value)"/>
                      <label for="copie" class="disabled">Numero Copie</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 d-flex flex-column" *ngIf="i==0">
                <button class="btn btn-secondary my-1" [ngClass]="model.linguaDefault != '-1' ? 'copia_negra' : ''" [disabled]="true">Copia Inclusa</button>
                <button class="btn btn-secondary my-1" (click)="aggiungiCopia()" [disabled]="copie.length > 4 || model.linguaDefault == '-1'" #secondo>Aggiungi Copia</button>
              </div>
              <div class="col-3 d-flex flex-column" *ngIf="i>0">
                <button class="btn btn-secondary my-1" [ngClass]="model.linguaDefault != '-1' ? 'copia_negra' : ''" [disabled]="true">Copia Extra</button>
                <button class="btn btn-secondary my-1" #terzo (click)="removeCopia(i)">Rimuovi Copia</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="w-90 mx-auto my-4">

    <div class="row px-3 px-md-4" id="page-1" *ngIf="model.pagina1">

      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine['intro'])">
            <img
              [src]="pagine['intro']"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine['intro']"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        

        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3 class="fw-semibold">Copertina</h3>
            <p>Scrivi testo</p>
          </div>
          <div class="row">
            <div class="col-6 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="text"
                placeholder="Nome dell'Host"
                [(ngModel)]="model.nomeHost"
                [ngClass]="(model.nomeHost=='' && controllo) ? 'is-invalid' : ''"
                name="nomeHost"
              />
            </div>
            <div class="col-6 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="text"
                placeholder="Cognome dell'Host"
                [(ngModel)]="model.cognomeHost"
                [ngClass]="(model.cognomeHost=='' && controllo) ? 'is-invalid' : ''"
                name="cognomeHost"
              />
            </div>
            <div class="col-6 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="text"
                placeholder="Nome della Struttura"
                [(ngModel)]="model.nomeStruttura"
                [ngClass]="(model.nomeStruttura=='' && controllo) ? 'is-invalid' : ''"
                name="nomeStruttura"
              />
            </div>
            <div class="col-6 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="text"
                placeholder="Indirizzo Struttura"
                [(ngModel)]="model.indirizzoStruttura"
                [ngClass]="(model.indirizzoStruttura=='' && controllo) ? 'is-invalid' : ''"
                name="indirizzoStruttura"
              />
            </div>
            <div class="col-12 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="file"
                placeholder="Copertina"
                [ngClass]="(model.fotoCopertina=='' && controllo) ? 'is-invalid' : ''"
                name="fotoCopertina"
                (change)="changeImmagine($event, 'fotoCopertina')"
                accept="image/*"
              />
              <img [src]="model.fotoCopertina" alt="Immagine" class="w-25 my-2" *ngIf="model.fotoCopertina != ''" />
            </div>
            <div class="d-flex justify-content-end mt-4">
              <button
                type="button"
                class="btn btn-secondary px-5 me-2"
                (click)="changePage(1, undefined, true)"
              >
                Rimuovi
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-2"*ngIf="model.pagina2">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[2])">
            <img
              [src]="pagine[2]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[2]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Host Page</h3>
            <p>Scrivi testo</p>
          </div>
          <div class="row">
            <div class="col-6 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="text"
                placeholder="Contatto Telefonico Host"
                [(ngModel)]="model.contattoTelefono"
                [ngClass]="(model.contattoTelefono =='' && controllo) ? 'is-invalid' : ''"
                name="contattoTelefono"
              />
            </div>
            <div class="col-6 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="text"
                placeholder="Indirizzo Email Host"
                [(ngModel)]="model.email"
                [ngClass]="(model.email=='' && controllo) ? 'is-invalid' : ''"
                name="email"
              />
            </div>
            <div class="col-12 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="file"
                placeholder="Copertina"
                name="fotoHost"(change)="changeImmagine($event, 'fotoHost')"
                accept="image/*"
              />
              <img [src]="model.fotoHost" alt="Immagine" class="w-25 my-2" *ngIf="model.fotoHost != ''" />
            </div>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 18vh; overflow-y: hidden"
            [(ngModel)]="model.descrizioneHost"
            [ngClass]="(model.descrizioneHost=='' && controllo) ? 'is-invalid' : ''"
            name="descrizioneHost"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(2, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-3"*ngIf="model.pagina3">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[3])">
            <img
              [src]="pagine[3]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[3]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Istruzioni</h3>
            <p>Scrivi testo</p>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 13vh; overflow-y: hidden"
            name="istruzioniCheckIn"
            [(ngModel)]="model.istruzioniCheckIn"
            [ngClass]="(model.istruzioniCheckIn =='' && controllo) ? 'is-invalid' : ''"
          ></textarea>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 13vh; overflow-y: hidden"
            name="istruzioniCheckOut"
            [(ngModel)]="model.istruzioniCheckOut"
            [ngClass]="(model.istruzioniCheckOut=='' && controllo) ? 'is-invalid' : ''"
          ></textarea>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 13vh; overflow-y: hidden"
            name="cancellazione"
            [(ngModel)]="model.cancellazione"
            [ngClass]="(model.cancellazione=='' && controllo) ? 'is-invalid' : ''"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(3, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-4"*ngIf="model.pagina4">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[4])">
            <img
              [src]="pagine[4]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[4]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Riguardo la Casa</h3>
            <p>Scrivi testo</p>
          </div>
          <input
            type="file"
            class="form-control rounded-2 p-3 mb-2"
            [ngClass]="(model.fotoCasa=='' && controllo) ? 'is-invalid' : ''"
            name="fotoCasa"
            (change)="changeImmagine($event, 'fotoCasa')"
            accept="image/*"
          />
          <img [src]="model.fotoCasa" alt="Immagine" class="w-25 my-2" *ngIf="model.fotoCasa != ''" />
          <div class="row mb-2">
            <div class="col-6 col-lg-3 mb-2">
              <select
                class="form-select"
                [(ngModel)]="model.camere"
                [ngClass]="(model.camere=='-1' && controllo) ? 'is-invalid' : ''"
                name="camere"
              >
                <option selected value="-1">Bedroom</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3/4</option>
              </select>
            </div>
            <div class="col-6 col-lg-3 mb-2">
              <select
                class="form-select"
                [(ngModel)]="model.bagni"
                [ngClass]="(model.bagni=='-1' && controllo) ? 'is-invalid' : ''"
                name="bagni"
              >
                <option selected value="-1">Bathroom</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3/4</option>
              </select>
            </div>
            <div class="col-6 col-lg-3 mb-2">
              <select
                class="form-select"
                [(ngModel)]="model.parcheggi"
                [ngClass]="(model.parcheggi=='-1' && controllo) ? 'is-invalid' : ''"
                name="parcheggi"
              >
                <option selected value="-1">Parking</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3/4</option>
              </select>
            </div>
            <div class="col-6 col-lg-3 mb-2">
              <select
                class="form-select"
                [(ngModel)]="model.stanze"
                name="stanze"
                [ngClass]="(model.stanze=='-1' && controllo) ? 'is-invalid' : ''"
              >
                <option selected value="-1">Rooms</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3/4</option>
              </select>
            </div>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 18vh; overflow-y: hidden"
            [(ngModel)]="model.descrizioneCasa"
            [ngClass]="(model.descrizioneCasa=='' && controllo) ? 'is-invalid' : ''"
            name="descrizioneCasa"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(4, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-5"*ngIf="model.pagina5">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[5])">
            <img
              [src]="pagine[5]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[5]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Arriva a Casa</h3>
            <p>Scrivi testo</p>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 18vh; overflow-y: hidden"
            [(ngModel)]="model.descrizioneArrivo"
            [ngClass]="(model.descrizioneArrivo=='' && controllo) ? 'is-invalid' : ''"
            name="descrizioneArrivo"
          ></textarea>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 18vh; overflow-y: hidden"
            [(ngModel)]="model.descrizioneArrivo2"
            [ngClass]="(model.descrizioneArrivo2=='' && controllo) ? 'is-invalid' : ''"
            name="descrizioneArrivo2"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(5, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-6"*ngIf="model.pagina6">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[6])">
            <img
              [src]="pagine[6]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[6]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Regole</h3>
            <p>Scrivi testo</p>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 30vh; overflow-y: hidden"
            [(ngModel)]="model.regoleCasa"
            [ngClass]="(model.regoleCasa=='' && controllo) ? 'is-invalid' : ''"
            name="regoleCasa"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(6, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-7"*ngIf="model.pagina7">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[7])">
            <img
              [src]="pagine[7]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[7]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Guida Casa</h3>
            <p>Scrivi testo</p>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 30vh; overflow-y: hidden"
            [(ngModel)]="model.guidaCasa"
            [ngClass]="(model.guidaCasa=='' && controllo) ? 'is-invalid' : ''"
            name="guidaCasa"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(7, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-8"*ngIf="model.pagina8">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[8])">
            <img
              [src]="pagine[8]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[8]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>WiFi</h3>
            <p>Scrivi testo</p>
          </div>
          <div class="row">
            <div class="col-6 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="text"
                placeholder="Nome Internet"
                [(ngModel)]="model.nomeInternet"
                [ngClass]="(model.nomeInternet=='' && controllo) ? 'is-invalid' : ''"
                name="nomeInternet"
              />
            </div>
            <div class="col-6 mb-2">
              <input
                class="form-control rounded-2 p-3"
                type="text"
                placeholder="Password Internet"
                [(ngModel)]="model.passwordInternet"
                [ngClass]="(model.passwordInternet=='' && controllo) ? 'is-invalid' : ''"
                name="passwordInternet"
              />
            </div>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 22vh; overflow-y: hidden"
            [(ngModel)]="model.descrizioneElettronica"
            [ngClass]="(model.descrizioneElettronica=='' && controllo) ? 'is-invalid' : ''"
            name="descrizioneElettronica"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(8, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-9"*ngIf="model.pagina9">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[9])">
            <img
              [src]="pagine[9]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[9]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Piscina</h3>
            <p>Scrivi testo</p>
          </div>
          <input
            class="form-control rounded-2 p-3 mb-2"
            type="file"
            name="fotoPiscina"
            [ngClass]="(model.fotoPiscina=='' && controllo) ? 'is-invalid' : ''"
            (change)="changeImmagine($event, 'fotoPiscina')"
            accept="image/*"
          />
          <img [src]="model.fotoPiscina" alt="Immagine" class="w-25 my-2" *ngIf="model.fotoPiscina != ''" />
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 22vh; overflow-y: hidden"
            name="descrizionePiscina"
            [(ngModel)]="model.descrizionePiscina"
            [ngClass]="(model.descrizionePiscina=='' && controllo) ? 'is-invalid' : ''"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(9, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-10"*ngIf="model.pagina10">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[10])">
            <img
              [src]="pagine[10]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[10]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Getting Around</h3>
            <p>Scrivi testo</p>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 25vh; overflow-y: hidden"
            name="descrizioneAttorno"
            [(ngModel)]="model.descrizioneAttorno"
            [ngClass]="(model.descrizioneAttorno=='' && controllo) ? 'is-invalid' : ''"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(10, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-11"*ngIf="model.pagina11">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[11])">
            <img
              [src]="pagine[11]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[11]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Posti da visitare</h3>
            <p>Scrivi testo</p>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 25vh; overflow-y: hidden"
            name="descrizioneVisite"
            [(ngModel)]="model.descrizioneVisite"
            [ngClass]="(model.descrizioneVisite=='' && controllo) ? 'is-invalid' : ''"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(11, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-12"*ngIf="model.pagina12">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[12])">
            <img
              [src]="pagine[12]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[12]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Posti da visitare</h3>
            <p>Scrivi testo</p>
          </div>
          <input
            class="form-control rounded-2 p-3 mb-2"
            type="file"
            name="fotoVisita1"
            [ngClass]="(model.fotoVisita1=='' && controllo) ? 'is-invalid' : ''"
            (change)="changeImmagine($event, 'fotoVisita1')"
            accept="image/*"
          />
          <img [src]="model.fotoVisita1" alt="Immagine" class="w-25 my-2" *ngIf="model.fotoVisita1 != ''" />
          <input
            class="form-control mb-2"
            type="text"
            placeholder="Descrizione Immagine"
            name="descrizioneVisita1"
            [(ngModel)]="model.descrizioneVisita1"
            [ngClass]="(model.descrizioneVisita1=='' && controllo) ? 'is-invalid' : ''"
          />
          <input
            class="form-control rounded-2 p-3 mb-2"
            type="file"
            [ngClass]="(model.fotoVisita2=='' && controllo) ? 'is-invalid' : ''"
            name="fotoVisita2"
            (change)="changeImmagine($event, 'fotoVisita2')"
            accept="image/*"
          />
          <img [src]="model.fotoVisita2" alt="Immagine" class="w-25 my-2" *ngIf="model.fotoVisita2 != ''" />
          <input
            class="form-control mb-2"
            type="text"
            placeholder="Descrizione Immagine"
            name="descrizioneVisita2"
            [(ngModel)]="model.descrizioneVisita2"
            [ngClass]="(model.descrizioneVisita2=='' && controllo) ? 'is-invalid' : ''"
          />
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(12, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-13"*ngIf="model.pagina13">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[13])">
            <img
              [src]="pagine[13]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[13]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Riguardo la Città</h3>
            <p>Scrivi testo</p>
          </div>
          <input
            class="form-control rounded-2 p-3 mb-2"
            type="file"
            [ngClass]="(model.fotoCitta=='' && controllo) ? 'is-invalid' : ''"
            name="fotoCitta"
            (change)="changeImmagine($event, 'fotoCitta')"
            accept="image/*"
          />
          <img [src]="model.fotoCitta" alt="Immagine" class="w-25 my-2" *ngIf="model.fotoCitta != ''" />
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 22vh; overflow-y: hidden"
            [(ngModel)]="model.descrizioneCitta"
            [ngClass]="(model.descrizioneCitta=='' && controllo) ? 'is-invalid' : ''"
            name="descrizioneCitta"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(13, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-14"*ngIf="model.pagina14">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[14])">
            <img
              [src]="pagine[14]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[14]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Dove mangiare</h3>
            <p>Scrivi testo</p>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 25vh; overflow-y: hidden"
            name="descrizioneCibo"
            [(ngModel)]="model.descrizioneCibo"
            [ngClass]="(model.descrizioneCibo=='' && controllo) ? 'is-invalid' : ''"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(14, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-15"*ngIf="model.pagina15">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[15])">
            <img
              [src]="pagine[15]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[15]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Numeri di Emergenza</h3>
            <p>Scrivi testo</p>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 25vh; overflow-y: hidden"
            name="descrizioneEmergenza"
            [(ngModel)]="model.descrizioneEmergenza"
            [ngClass]="(model.descrizioneEmergenza=='' && controllo) ? 'is-invalid' : ''"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(15, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-16"*ngIf="model.pagina16">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[16])">
            <img
              [src]="pagine[16]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[16]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Feedback</h3>
            <p>Scrivi testo</p>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <select
                class="form-select"
                [(ngModel)]="model.airBnb"
                [ngClass]="(model.airBnb=='-1' && controllo) ? 'is-invalid' : ''"
                name="airBnb"
              >
                <option selected value="-1">Affitti con Air BNB?</option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
            </div>
            <div class="col-6">
              <select
                class="form-select"
                [(ngModel)]="model.booking"
                [ngClass]="(model.booking=='-1' && controllo) ? 'is-invalid' : ''"
                name="booking"
              >
                <option selected value="-1">Affitti con Booking</option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 22vh; overflow-y: hidden"
            [(ngModel)]="model.feedback"
            [ngClass]="(model.feedback=='' && controllo) ? 'is-invalid' : ''"
            name="feedback"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(16, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-17"*ngIf="model.pagina17">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[17])">
            <img
              [src]="pagine[17]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[17]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Extra</h3>
            <p>Scrivi testo</p>
          </div>
          <input
                class="form-control rounded-2 p-3 mb-2"
                type="text"
                placeholder="Titolo Extra"
                [(ngModel)]="model.extra1Titolo"
                [ngClass]="(model.extra1Titolo=='' && controllo) ? 'is-invalid' : ''"
                name="extra1Titolo"
              />
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 22vh; overflow-y: hidden"
            [(ngModel)]="model.extra1Descrizione"
            [ngClass]="(model.extra1Descrizione=='' && controllo) ? 'is-invalid' : ''"
            name="extra1Descrizione"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button
              type="button"
              class="btn btn-secondary px-5 me-2"
              (click)="changePage(17, undefined, true)"
            >
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3 px-md-4" id="page-18"*ngIf="model.pagina18">
      <div class="d-none d-lg-block col-4 py-3 px-2">
        <div class="py-4 px-5 w-90 h-100 align-items-center d-flex">
          <div class="w-100 d-flex justify-content-center mb-4" (click)="onImageClick(immagineZoom, pagine[18])">
            <img
              [src]="pagine[18]"
              alt="Immagine test"
              class="w-60 border border-1 border-blu"
              *ngIf="pagine[18]"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 py-3">
        <div>
          <div class="d-flex justify-content-between mb-3">
            <h3>Extra</h3>
            <p>Scrivi testo</p>
          </div>
          <input
                class="form-control rounded-2 p-3 mb-2"
                type="text"
                placeholder="Titolo Extra"
                [ngClass]="(model.extra2Titolo=='' && controllo) ? 'is-invalid' : ''"
                [(ngModel)]="model.extra2Titolo"
                name="extra2Titolo"
              />
          <textarea
            class="form-control rounded-2 p-3 mb-2"
            placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
            style="resize: none; height: 22vh; overflow-y: hidden"
            [(ngModel)]="model.extra2Descrizione"
            [ngClass]="(model.extra2Descrizione=='' && controllo) ? 'is-invalid' : ''"
            name="extra2Descrizione"
          ></textarea>
          <div class="d-flex justify-content-end mt-4">
            <button type="button" class="btn btn-secondary px-5 me-2" (click)="changePage(18)">
              Rimuovi
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3 px-md-4 d-flex justify-content-center mb-5">
      <button
        type="button"
        class="btn btn-danger px-5 rounded-3 fs-6 fs-md-5 fw-semibold"
        (click)="procedi()"
      >
        Procedi
      </button>
    </div>
  </div>
</form>
