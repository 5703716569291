import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { Ordine } from '../interface/ordine.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DettaglioOrdineComponent } from '../component/dettaglio-ordine/dettaglio-ordine.component';
import { OrdineEasy } from '../interface/ordine-easy.interface';

@Injectable({
  providedIn: 'root'
})
export class OrdineService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, private modalService: NgbModal) { }

  saveOrdine(ordine: any) {
    return this.http.post<number>(`${this.apiUrl}/ordini`, ordine)
  }

  findOrdineByIdAndUtente(ordine: number, utente:number) {
    return this.http.get(`${this.apiUrl}/ordini/${ordine}/utente/${utente}/boolean`, {responseType: "text"})
  }

  findOrdineByNumeroOrdine(ordine: string) {
    return this.http.get<OrdineEasy>(`${this.apiUrl}/ordini/easy/${ordine}`)
  }

  findOrdineByIdAndUtente2(ordine: number, utente:number) {
    return this.http.get<Ordine>(`${this.apiUrl}/ordini/${ordine}/utente/${utente}`)
  }

  patchOrdine(ordine: number, patchOrdine: any) {
    return this.http.patch(`${this.apiUrl}/ordini/${ordine}`, patchOrdine)
  }

  patchModificaOrdine(ordine:number, personalizzazione:any) {
    return this.http.patch(`${this.apiUrl}/ordini/${ordine}/personalizzazione`, personalizzazione)
  }

  patchPdf(lingua: any, ordine: number) {
    return this.http.patch<Ordine>(`${this.apiUrl}/ordini/${ordine}/pdf`, lingua)
  }

  patchStatoOrdine(ordine: number, statoOrdine: string) {
    return this.http.patch(`${this.apiUrl}/ordini/${ordine}/stato`, {statoOrdine: statoOrdine});
  }

  findOrdiniByUtente(utente: number) {
    return this.http.get<Ordine[]>(`${this.apiUrl}/ordini/utente/${utente}`)
  }

  openDettagliOrdine(ordine: Ordine) {
    let modale = this.modalService.open(DettaglioOrdineComponent, { size: 'lg', centered: true})
    modale.componentInstance.ordine = ordine;
  }
}
