import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.development';
import { Prodotto } from '../interface/prodotto.interface';
import { map, Observable, tap } from 'rxjs';
import { Mockup } from '../interface/mockup.interface';
import { MockupDto } from '../interface/mockup-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }

  getAllPacchetti(): Observable<Prodotto[]> {
    return this.http.get<Prodotto[]>(`${this.apiUrl}/prodotti/pacchetti`).pipe(
      map((pacchetti) => {
        return pacchetti.sort((a, b) => a.id - b.id)
      })
    )
  }

  getAllMockup(): Observable<Mockup[]> {
    return this.http.get<MockupDto[]>(`${this.apiUrl}/mockups`).pipe(
      map((mockupList) => {
        const originalList = [...mockupList];
        let newList: Mockup[] = originalList.map((mockup) => {
          return {
            id: mockup.id,
            titolo: mockup.titolo,
            pagine: JSON.parse(mockup.pagine),
            voto: 0,
            recensioni: 0
          }
        });
        return newList;
      })
    )
  }

  getAllPrezzi(): Observable<{
    traduzione: number,
    copia: number,
    spedizione: number
  }> {
    return this.http.get<Prodotto[]>(`${this.apiUrl}/prodotti/prezzi`).pipe(
      map((prezzi) => {
        let newPrezzi:{
          traduzione: number,
          copia: number,
          spedizione: number
        } = {
          traduzione: 0.0,
          copia: 0.0,
          spedizione: 0.0,
        }
        prezzi.forEach((prezzo) => {
          if (prezzo.tipo == 'TRADUZIONE') {
            newPrezzi.traduzione = Number(prezzo.prezzo)
          } else if (prezzo.tipo == 'COPIA') {
            newPrezzi.copia = Number(prezzo.prezzo)
          } else if (prezzo.tipo == 'SPEDIZIONE') {
            newPrezzi.spedizione = Number(prezzo.prezzo)
          }
        })
        return newPrezzi;
      })
    )
  }
}
