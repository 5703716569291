import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-processo',
  templateUrl: './processo.component.html',
  styleUrls: ['./processo.component.scss']
})
export class ProcessoComponent implements OnInit {
  header!: HTMLDivElement;
  passaggio = 1;
  totalePassaggi = 4;
  constructor(private renderer: Renderer2, private router: Router) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log(event.urlAfterRedirects);
      if (event.urlAfterRedirects == '/acquisto/scegli-tema') {
        this.passaggio = 1;
      } else if (event.urlAfterRedirects == "/acquisto/scegli-pacchetto") {
        this.passaggio = 2;
      } else if (event.urlAfterRedirects == "/acquisto/personalizza") {
        this.passaggio = 3;
      } else if (event.urlAfterRedirects == '/acquisto/buy') {
        this.passaggio = 4;
      }
    });
  }

  ngOnInit(): void {
    // this.router.navigate(['/acquisto/scegli-tema'])
  }
  ngAfterViewInit(): void {
      this.header = document.getElementById('processo') as HTMLDivElement;
    this.renderer.listen('window', 'scroll', () => {
      this.onWindowScroll();
    })
  }

  onWindowScroll() {
    const header = document.querySelector('header');
    
    const headerHeight = header?.offsetHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (headerHeight && scrollTop > 0) {
      this.header.style.position = 'sticky';
      this.header.style.top = `${headerHeight}px`;
    } else {
      this.header.style.position = 'relative';
      this.header.style.top = '0';
    }
  }

}
