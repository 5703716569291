<div class="vh-100 d-flex justify-content-center flex-column sfondo">
    <div class="w-95 w-md-50" style="margin: 0 auto;">
        <div class="position-relative" style="overflow: hidden;">
            <div class="position-absolute start-0 text-center py-5" *ngIf="caricato" style="opacity: 0;top: 10%">
                <div #dataToExport class="a42">
                    <div style="width: 190mm!important; padding: 75px 0; margin: 0 auto;height: max-content;transform: translateY(-50%);" class="position-relative top-50">
                        <p class="text-center py-1" style="font-size: 100px!important;font-weight: 800;">WELCOME</p>
                        <hr class="mx-auto w-90 border-bottom border-1 border-black my-4">
                        <p class="m-0 mb-1 p-0" style="font-size: 30px!important;">We hope you have an amazing stay!</p>
                        <!-- <p class="fw-bold m-0 p-0" style="font-size: 35px!important;">{{personalizzazione['nomeStruttura']}}</p> -->
                        <hr class="mx-auto w-90 border-bottom border-1 border-black my-4">
                        <div class="mb-4">
                            <div class="container">
                                <div class="row py-4">
                                    <div class="col-7 border-end border-black px-3">
                                        <div>
                                            <div *ngIf="urlQrcode" class="mx-auto">
                                                <qrcode [qrdata]="urlQrcode" [width]="200" [errorCorrectionLevel]="'M'" class="mx-auto" cssClass="borderBlack"></qrcode>
                                            </div>
                                            <p class="fw-bold m-0 my-3 p-0 px-5" style="font-size: 17px!important;" *ngIf="personalizzazione['lingue']['inglese'] || personalizzazione['linguaDefault'] == 'inglese'">Scan the QR code to view the guide</p>
                                            <p class="fw-bold m-0 my-3 p-0 px-5" style="font-size: 17px!important;" *ngIf="personalizzazione['lingue']['italiano'] || personalizzazione['linguaDefault'] == 'italiano'">Scannerizza il qr code per visualizzare la guida</p>
                                            <p class="fw-bold m-0 my-3 p-0 px-5" style="font-size: 17px!important;" *ngIf="personalizzazione['lingue']['tedesco'] || personalizzazione['linguaDefault'] == 'tedesco'">Scannen Sie den QR-Code, um den Leitfaden anzuzeigen</p>
                                            <p class="fw-bold m-0 my-3 p-0 px-5" style="font-size: 17px!important;" *ngIf="personalizzazione['lingue']['spagnolo'] || personalizzazione['linguaDefault'] == 'spagnolo'">Escanea el código QR para ver la guía</p>
                                            <p class="fw-bold m-0 my-3 p-0 px-5" style="font-size: 17px!important;" *ngIf="personalizzazione['lingue']['francese'] || personalizzazione['linguaDefault'] == 'francese'">Scannez le code QR pour voir le guide</p>
                                        </div>
                                    </div>
                                    <div class="col-5 text-start ps-5">
                                        <h3 class="mb-4">{{personalizzazione['nomeStruttura']}}</h3>
                                        <div class="mb-4">
                                            <h4 class="p-0 m-0 mb-2"><i class="bi bi-house-door-fill"></i> Address</h4>
                                            <p class="p-0 m-0">{{personalizzazione['indirizzoStruttura']}}</p>
                                        </div>
                                        <div class="mb-4">
                                            <h4 class="p-0 m-0 mb-2"><i class="bi bi-telephone-fill"></i> Contacts</h4>
                                            <p class="p-0 m-0 mb-2">{{personalizzazione['contattoTelefono']}}</p>
                                            <p class="p-0 m-0">{{personalizzazione['email']}}</p>
                                        </div>
                                        <div class="mb-4">
                                            <h4 class="p-0 m-0 mb-2"><i class="fas fa-rss"></i> Wifi</h4>
                                            <p class="p-0 m-0 mb-2">{{personalizzazione['nomeInternet']}}</p>
                                            <p class="p-0 m-0">{{personalizzazione['passwordInternet']}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="mx-auto w-90 border-bottom border-1 border-black my-4">
                        <p class="m-0 mb-1 p-0" style="font-size: 30px!important;">Enjoy your stay!</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="container d-flex justify-content-center text-center py-5 border border-1 border-black" *ngIf="caricato">
            <div>
                <h3 class="px-3 px-md-4 text-center display-1 py-2 fw-bolder">Welcome</h3>
                <hr class="mx-md-auto w-md-90 border-bottom border-1 border-black my-2">
                <p class="fw-bold m-0 mb-1 p-0 fs-7 fs-md-5">Home Guide</p>
                <p class="fw-bold m-0 p-0 fs-7 fs-md-5">{{personalizzazione['nomeStruttura']}}</p>
                <hr class="mx-md-auto w-md-90 border-bottom border-1 border-black my-2">
                <div class="mb-2">
                    <p class="fw-bold m-0 p-0 fs-7 fs-md-5" *ngIf="personalizzazione['lingue']['inglese'] || personalizzazione['linguaDefault'] == 'inglese'">Scan the QR code to view the guide</p>
                    <p class="fw-bold m-0 p-0 fs-7 fs-md-5" *ngIf="personalizzazione['lingue']['italiano'] || personalizzazione['linguaDefault'] == 'italiano'">Scannerizza il qr code per visualizzare la guida</p>
                    <p class="fw-bold m-0 p-0 fs-7 fs-md-5" *ngIf="personalizzazione['lingue']['tedesco'] || personalizzazione['linguaDefault'] == 'tedesco'">Scannen Sie den QR-Code, um den Leitfaden anzuzeigen</p>
                    <p class="fw-bold m-0 p-0 fs-7 fs-md-5" *ngIf="personalizzazione['lingue']['spagnolo'] || personalizzazione['linguaDefault'] == 'spagnolo'">Escanea el código QR para ver la guía</p>
                    <p class="fw-bold m-0 p-0 fs-7 fs-md-5" *ngIf="personalizzazione['lingue']['francese'] || personalizzazione['linguaDefault'] == 'francese'">Scannez le code QR pour voir le guide</p>
                </div>
                <div *ngIf="urlQrcode" class="my-5">
                    <qrcode [qrdata]="urlQrcode" [width]="100" [errorCorrectionLevel]="'M'" class="mx-auto" cssClass="borderBlack"></qrcode>
                </div>
            </div>
        </div> -->
        <div class="d-flex justify-content-center border border-1 border-black" *ngIf="immagine" style="width: fit-content;margin: 0 auto;">
            <img [src]="immagine" class="img-fluid" style="max-height: 70vh;">
        </div>
    </div>
    
    <div class="d-flex justify-content-center z-3">
        <button class="btn btn-secondary px-3 my-3 pulsanteblu border border-1 border-white" type="button" (click)="downloadPdf()">Scarica</button>
    </div>
</div>