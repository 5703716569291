import { Component } from '@angular/core';

@Component({
  selector: 'app-mediumsection',
  templateUrl: './mediumsection.component.html',
  styleUrls: ['./mediumsection.component.scss']
})
export class MediumsectionComponent {

}
