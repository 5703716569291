<section>
  <div class="container-fluid py-5 classe">
    <div class="container p-5 mb-4">
        <h2 class="fw-bolder display-3 interlieaheroh">Crea la tua Guida</h2>
          <p class="h5 fw-light col-md-8 col-lg-6 interlieahero mt-3 fst-italic">
            Welcomebook è la soluzione che ti permette di realizzare con facilità una guida personalizzata della tua struttura, che i tuoi ospiti potranno sfogliare
          </p>
        <div class="lc-block mt-4">
          <a class="btn rounded-2 colorerosso" [routerLink]="['/acquisto/scegli-tema']" role="button"
            >Creala Ora</a
          >
        </div>
      </div>
    </div>
  </section>
  <section id="cambioColore"  >
    <div class="container px-5 pt-5">
      <div class="row py-2">
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-3">
              <img src="https://cdn.welcomebook.it/uploads/statics/1a.png" class="img-fluid" alt="Primo Scopo" />
            </div>
            <div class="col-9">
              <h5 class="fw-bolder">Migliora la comunicazione</h5>
              <p class="grigino">Gli ospiti consulteranno la guida per trovare le risposte alle loro esigenze automatizzando la comunicazione con te</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-3">
              <img src="https://cdn.welcomebook.it/uploads/statics/2a.png" class="img-fluid" alt="Secondo Scopo" />
            </div>
            <div class="col-9">
              <h5 class="fw-bolder">Aumenta i feedback positivi</h5>
              <p class="grigino">La guida aiuterà gli ospiti a sfruttare al meglio i servizi, migliorando l'esperienza e ottimizzando i feedback</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-3">
              <img src="https://cdn.welcomebook.it/uploads/statics/3b.png" class="img-fluid" alt="Terzo Scopo" />
            </div>
            <div class="col-9">
              <h5 class="fw-bolder">Dai valore alla tua struttura</h5>
              <p class="grigino">Una guida migliora la percezione della tua struttura, aumentandone il suo valore agli occhi degli ospiti</p>
            </div>
          </div>
        </div>
      </div>
      <hr class="w-90 mx-auto my-2" />
    </div>
    <app-introsection></app-introsection>
  </section>
  <section id="mockupsection">
    <app-mokupsection></app-mokupsection>
  </section>
  <section id="mediumsection">
    <app-mediumsection></app-mediumsection>
  </section>
  <section id="recensioni">
    <app-recensionisection></app-recensionisection>
    
  </section>
  <section id="domande">
    <app-domandesection></app-domandesection>
  </section>