<div class="w-100 blu text-white z-2" id="processo" style="z-index: 12000000!important;">
    <div class="container d-flex justify-content-evenly">
        <p class="fs-7 fs-md-6 p-1 pt-2 m-0" [routerLink]="['/acquisto/scegli-tema']" routerLinkActive="rosso_bordo" [routerLinkActiveOptions]="{exact:true}">Scegli Tema</p>
        <p class="fs-7 fs-md-6 p-1 pt-2 m-0"[routerLink]="['/acquisto/scegli-pacchetto']" routerLinkActive="rosso_bordo">Scegli Pacchetto</p>
        <p class="fs-7 fs-md-6 p-1 pt-2 m-0"[routerLink]="['/acquisto/personalizza']" routerLinkActive="rosso_bordo">Personalizza</p>
        <p class="fs-7 fs-md-6 p-1 pt-2 m-0"[routerLink]="['/acquisto/buy']" routerLinkActive="rosso_bordo">Acquista</p>
    </div>
</div>
<div class="container position-relative">
    <div class="position-absolute top-0 right-0 text-end pt-4 mt-3" style="width: max-content !important;
    right: 3vw;">
        <p class="my-0 p-0 px-md-4">passaggio <span class="rosso fw-bolder">{{passaggio}}</span> di <span class="rosso fw-bolder">{{totalePassaggi}}</span></p>
    </div>
</div>
<router-outlet></router-outlet>