<div class="container py-5" *ngIf="ordine">
  <h3 class="px-3 px-md-4">Dettagli Personalizzazione Ordine IT-{{ordine.id}}</h3>
  <div *ngIf="personalizzazione">
      <div
        class="row px-3 px-md-4"
        id="page-1"
        *ngIf="personalizzazione['pagina1']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3 class="fw-semibold">Copertina</h3>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <div class="form-floating">
                    <input
                      class="form-control rounded-2"
                      type="text"
                      placeholder="Nome dell'Host"
                      [value]="personalizzazione['nomeHost']"
                      readonly
                      name="nomeHost"
                    />
                    <label for="nomeHost">Nome dell'Host</label>
                </div>
              </div>
              <div class="col-6 mb-2">
                <div class="form-floating">
                    <input
                      class="form-control rounded-2"
                      type="text"
                      placeholder="Cognome dell'Host"
                      [value]="personalizzazione['cognomeHost']"
                      name="cognomeHost"
                      readonly
                    />
                    <label for="cognomeHost">Cognome dell'Host</label>
                </div>
              </div>
              <div class="col-6 mb-2">
                <div class="form-floating">
                    <input
                      class="form-control rounded-2"
                      type="text"
                      placeholder="Nome della Struttura"
                      [value]="personalizzazione['nomeStruttura']"
                      name="nomeStruttura"
                      readonly
                    />
                    <label for="nomeStruttura">Nome della Struttura</label>
                </div>
              </div>
              <div class="col-6 mb-2">
                <div class="form-floating">
                    <input
                      class="form-control rounded-2"
                      type="text"
                      placeholder="Indirizzo Struttura"
                      [value]="personalizzazione['indirizzoStruttura']"
                      name="indirizzoStruttura"
                      readonly
                    />
                    <label for="indirizzoStruttura">Indirizzo Struttura</label>
                </div>
              </div>
              <div class="col-12 mb-2">
                <img
                  [src]="personalizzazione['fotoCopertina']"
                  alt="Immagine"
                  class="w-25 my-2"
                  *ngIf="personalizzazione['fotoCopertina'] != ''"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-2"
        *ngIf="personalizzazione['pagina2']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Host Page</h3>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <div class="form-floating">
                    <input
                      class="form-control rounded-2"
                      type="text"
                      placeholder="Contatto Telefonico Host"
                      [value]="personalizzazione['contattoTelefono']"
                      name="contattoTelefono"
                      readonly
                    />
                    <label for="contattoTelefono">Contatto Telefonico Host</label>
                </div>
              </div>
              <div class="col-6 mb-2">
                <div class="form-floating">
                    <input
                      class="form-control rounded-2"
                      type="text"
                      placeholder="Indirizzo Email Host"
                      [value]="personalizzazione['email']"
                      name="email"
                      readonly
                    />
                    <label for="email">Indirizzo Email Host</label>
                </div>
              </div>
              <div class="col-12 mb-2">
                <img
                  [src]="personalizzazione['fotoHost']"
                  alt="Immagine"
                  class="w-25 my-2"
                  *ngIf="personalizzazione['fotoHost'] != ''"
                />
              </div>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 18vh; overflow-y: hidden"
                  [value]="personalizzazione['descrizioneHost']"
                  name="descrizioneHost"
                ></textarea>
                <label for="descrizioneHost">Descrizione Host</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-3"
        *ngIf="personalizzazione['pagina3']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Istruzioni</h3>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 13vh; overflow-y: hidden"
                  name="istruzioniCheckIn"
                  [value]="personalizzazione['istruzioniCheckIn']"
                  readonly
                ></textarea>
                <label for="istruzioniCheckIn">Istruzioni Check In</label>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 13vh; overflow-y: hidden"
                  name="istruzioniCheckOut"
                  [value]="personalizzazione['istruzioniCheckOut']"
                  readonly
                ></textarea>
                <label for="istruzioniCheckOut">Istruzioni Check Out</label>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 13vh; overflow-y: hidden"
                  name="cancellazione"
                  [value]="personalizzazione['cancellazione']"
                  readonly
                ></textarea>
                <label for="cancellazione">Cancellazione</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-4"
        *ngIf="personalizzazione['pagina4']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Riguardo la Casa</h3>
            </div>
            <img
              [src]="personalizzazione['fotoCasa']"
              alt="Immagine"
              class="w-25 my-2"
              *ngIf="personalizzazione['fotoCasa'] != ''"
            />
            <div class="row mb-2">
              <div class="col-6 col-lg-3 mb-2">
                <div class="form-floating">
                    <select
                      class="form-select"
                      [value]="personalizzazione['camere']"
                      name="camere"
                      readonly
                    >
                      <option selected value="-1">Bedroom</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3/4</option>
                    </select>
                    <label for="camere">Camere</label>
                </div>
              </div>
              <div class="col-6 col-lg-3 mb-2">
                <div class="form-floating">
                    <select
                      class="form-select"
                      [value]="personalizzazione['bagni']"
                      name="bagni"
                      readonly
                    >
                      <option selected value="-1">Bathroom</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3/4</option>
                    </select>
                    <label for="bagni">Bagni</label>
                </div>
              </div>
              <div class="col-6 col-lg-3 mb-2">
                <div class="form-floating">
                    <select
                      class="form-select"
                      [value]="personalizzazione['parcheggi']"
                      name="parcheggi"
                      readonly
                    >
                      <option selected value="-1">Parking</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3/4</option>
                    </select>
                    <label for="nomeHost">Parcheggi</label>
                </div>
              </div>
              <div class="col-6 col-lg-3 mb-2">
                <div class="form-floating">
                    <select
                      class="form-select"
                      [value]="personalizzazione['stanze']"
                      name="stanze"
                      readonly
                    >
                      <option selected value="-1">Rooms</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3/4</option>
                    </select>
                    <label for="stanze">Stanze</label>
                </div>
              </div>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 18vh; overflow-y: hidden"
                  [value]="personalizzazione['descrizioneCasa']"
                  name="descrizioneCasa"
                  readonly
                ></textarea>
                <label for="descrizioneCasa">Descrizione Casa</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-5"
        *ngIf="personalizzazione['pagina5']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Arriva a Casa</h3>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 18vh; overflow-y: hidden"
                  [value]="personalizzazione['descrizioneArrivo']"
                  name="descrizioneArrivo"
                  readonly
                ></textarea>
                <label for="descrizioneArrivo">Descrizione Arrivo</label>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 18vh; overflow-y: hidden"
                  [value]="personalizzazione['descrizioneArrivo2']"
                  name="descrizioneArrivo2"
                  readonly
                ></textarea>
                <label for="descrizioneArrivo2">Descrizione Arrivo 2</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-6"
        *ngIf="personalizzazione['pagina6']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Regole</h3>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 30vh; overflow-y: hidden"
                  [value]="personalizzazione['regoleCasa']"
                  name="regoleCasa"
                  readonly
                ></textarea>
                <label for="regoleCasa">Regole Casa</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-7"
        *ngIf="personalizzazione['pagina7']"
      >
        <div class="col-12 col-lg-8 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Guida Casa</h3>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 30vh; overflow-y: hidden"
                  [value]="personalizzazione['guidaCasa']"
                  name="guidaCasa"
                  readonly
                ></textarea>
                <label for="guidaCasa">Guida Casa</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-8"
        *ngIf="personalizzazione['pagina8']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>WiFi</h3>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <div class="form-floating">
                    <input
                      class="form-control rounded-2"
                      type="text"
                      placeholder="Nome Internet"
                      [value]="personalizzazione['nomeInternet']"
                      name="nomeInternet"
                      readonly
                    />
                    <label for="nomeInternet">Nome Internet</label>
                </div>
              </div>
              <div class="col-6 mb-2">
                <div class="form-floating">
                    <input
                      class="form-control rounded-2"
                      type="text"
                      placeholder="Password Internet"
                      [value]="personalizzazione['passwordInternet']"
                      name="passwordInternet"
                      readonly
                    />
                    <label for="passwordInternet">Password Internet</label>
                </div>
            </div>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 22vh; overflow-y: hidden"
                  [value]="personalizzazione['descrizioneElettronica']"
                  name="descrizioneElettronica"
                  readonly
                ></textarea>
                <label for="descrizioneElettronica">Descrizione Elettronica</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-9"
        *ngIf="personalizzazione['pagina9']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Piscina</h3>
            </div>
            <img
              [src]="personalizzazione['fotoPiscina']"
              alt="Immagine"
              class="w-25 my-2"
              *ngIf="personalizzazione['fotoPiscina'] != ''"
            />
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 22vh; overflow-y: hidden"
                  name="descrizionePiscina"
                  [value]="personalizzazione['descrizionePiscina']"
                  readonly
                ></textarea>
                <label for="descrizionePiscina">Descrizione Piscina</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-10"
        *ngIf="personalizzazione['pagina10']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Getting Around</h3>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 25vh; overflow-y: hidden"
                  name="descrizioneAttorno"
                  [value]="personalizzazione['descrizioneAttorno']"
                  readonly
                ></textarea>
                <label for="descrizioneAttorno">Descrizione Attorno</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-11"
        *ngIf="personalizzazione['pagina11']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Posti da visitare</h3>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 25vh; overflow-y: hidden"
                  name="descrizioneVisite"
                  [value]="personalizzazione['descrizioneVisite']"
                  readonly
                ></textarea>
                <label for="descrizioneVisite">Descrizione Visite</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-12"
        *ngIf="personalizzazione['pagina12']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Posti da visitare</h3>
            </div>
            <img
              [src]="personalizzazione['fotoVisita1']"
              alt="Immagine"
              class="w-25 my-2"
              *ngIf="personalizzazione['fotoVisita1'] != ''"
            />
            <div class="form-floating">
                <input
                  class="form-control mb-2"
                  type="text"
                  placeholder="Descrizione Immagine"
                  name="descrizioneVisita1"
                  [value]="personalizzazione['descrizioneVisita1']"
                  readonly
                />
                <label for="descrizioneVisita1">Descrizione Visita 1</label>
            </div>
            <img
              [src]="personalizzazione['fotoVisita2']"
              alt="Immagine"
              class="w-25 my-2"
              *ngIf="personalizzazione['fotoVisita2'] != ''"
            />
            <div class="form-floating">
                <input
                  class="form-control mb-2"
                  type="text"
                  placeholder="Descrizione Immagine"
                  name="descrizioneVisita2"
                  [value]="personalizzazione['descrizioneVisita2']"
                  readonly
                />
                <label for="descrizioneVisita2">Descrizione Visita 2</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row px-3 px-md-4"
        id="page-13"
        *ngIf="personalizzazione['pagina13']"
      >
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Riguardo la Città</h3>
            </div>
            <img
              [src]="personalizzazione['fotoCitta']"
              alt="Immagine"
              class="w-25 my-2"
              *ngIf="personalizzazione['fotoCitta'] != ''"
            />
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 22vh; overflow-y: hidden"
                  [value]="personalizzazione['descrizioneCitta']"
                  name="descrizioneCitta"
                  readonly
                ></textarea>
                <label for="descrizioneCitta">Descrizione Citta</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-3 px-md-4" id="page-14" *ngIf="personalizzazione['pagina14']">
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Dove mangiare</h3>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 25vh; overflow-y: hidden"
                  name="descrizioneCibo"
                  [value]="personalizzazione['descrizioneCibo']"
                  readonly
                ></textarea>
                <label for="descrizioneCibo">Descrizione Ristoranti</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-3 px-md-4" id="page-15" *ngIf="personalizzazione['pagina15']">
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Numeri di Emergenza</h3>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 25vh; overflow-y: hidden"
                  name="descrizioneEmergenza"
                  [value]="personalizzazione['descrizioneEmergenza']"
                  readonly
                ></textarea>
                <label for="descrizioneEmergenza">Descrizione Emergenza</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-3 px-md-4" id="page-16" *ngIf="personalizzazione['pagina16']">
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Feedback</h3>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <div class="form-floating">
                    <select
                      class="form-select"
                      [value]="personalizzazione['airBnb']"
                      name="airBnb"
                      readonly
                    >
                      <option selected value="-1">Affitti con Air BNB?</option>
                      <option value="si">Si</option>
                      <option value="no">No</option>
                    </select>
                    <label for="airBnb">Affitti con Air BNB?</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-floating">
                    <select
                      class="form-select"
                      [value]="personalizzazione['booking']"
                      name="booking"
                      readonly
                    >
                      <option selected value="-1">Affitti con Booking</option>
                      <option value="si">Si</option>
                      <option value="no">No</option>
                    </select>
                    <label for="booking">Affitti con Booking?</label>
                </div>
              </div>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 22vh; overflow-y: hidden"
                  [value]="personalizzazione['feedback']"
                  name="feedback"
                  readonly
                ></textarea>
                <label for="feedback">Feedback</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-3 px-md-4" id="page-17" *ngIf="personalizzazione['pagina17']">
        <div class="col-12 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Extra</h3>
            </div>
            <div class="form-floating">
                <input
                  class="form-control rounded-2 mb-2"
                  type="text"
                  placeholder="Titolo Extra"
                  [value]="personalizzazione['extra1Titolo']"
                  name="extra1Titolo"
                  readonly
                />
                <label for="extra1Titolo">Titolo Extra</label>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 22vh; overflow-y: hidden"
                  [value]="personalizzazione['extra1Descrizione']"
                  name="extra1Descrizione"
                  readonly
                ></textarea>
                <label for="extra1Descrizione">Descrizione Extra</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-3 px-md-4" id="page-18" *ngIf="personalizzazione['pagina18']">
        <div class="col-12 col-lg-8 py-3">
          <div>
            <div class="d-flex justify-content-between mb-3">
              <h3>Extra</h3>
            </div>
            <div class="form-floating">
                <input
                  class="form-control rounded-2 mb-2"
                  type="text"
                  placeholder="Titolo Extra"
                  [value]="personalizzazione['extra2Titolo']"
                  name="extra2Titolo"
                  readonly
                />
                <label for="extra2Titolo">Titolo Extra</label>
            </div>
            <div class="form-floating">
                <textarea
                  class="form-control rounded-2 mb-2"
                  placeholder="Scrivi le regole della casa che vuoi che vengano visualizzate nel libricino"
                  style="resize: none; height: 22vh; overflow-y: hidden"
                  [value]="personalizzazione['extra2Descrizione']"
                  name="extra2Descrizione"
                  readonly
                ></textarea>
                <label for="extra2Descrizione">Descrizione Extra</label>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
