<ng-template #content let-modal style="z-index: 12000000!important;">
    <ng-container id="modale">
        <div class="modal-header">
            <h3 class="modal-title fw-bold">Tema {{pagine.titolo}}</h3>
            <button type="button" class="btn-close" style="color: #8D99AE" aria-label="Close" (click)="modal.dismiss('Cross click'); closeModal()"></button>
        </div>
        <div class="modal-body" style="background-color: #EDF2F4;">
            <div class="container my-5" id="libro">
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-12">
                        <!-- Book -->
                        <div id="book" class="book container">
                            <div class="paper2">
                                <img [src]="pagine.pagine['intro']" alt="Prima Pagina" class="w-100 shadow-sm">
                            </div>
                            <!-- Paper 1 -->
                            <div id="p1" class="paper">
                                <div class="front">
                                    <div id="f1" class="front-content">
                                        <img [src]="pagine.pagine['intro']" alt="Prima Pagina" class="w-100 shadow-sm outline">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b1" class="back-content">
                                        <img [src]="pagine.pagine['1']" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <!-- Paper 2 -->
                            <div id="p2" class="paper">
                                <div class="front">
                                    <div id="f2" class="front-content">
                                        <img [src]="pagine.pagine[2]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b2" class="back-content">
                                        <img [src]="pagine.pagine[3]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <!-- Paper 3 -->
                            <div id="p3" class="paper">
                                <div class="front">
                                    <div id="f3" class="front-content">
                                        <img [src]="pagine.pagine[4]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b3" class="back-content">
                                        <img [src]="pagine.pagine[5]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <!-- Paper 3 -->
                            <div id="p4" class="paper">
                                <div class="front">
                                    <div id="f4" class="front-content">
                                        <img [src]="pagine.pagine[6]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b4" class="back-content">
                                        <img [src]="pagine.pagine[7]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <!-- Paper 3 -->
                            <div id="p5" class="paper">
                                <div class="front">
                                    <div id="f5" class="front-content">
                                        <img [src]="pagine.pagine[8]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b5" class="back-content">
                                        <img [src]="pagine.pagine[9]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <!-- Paper 3 -->
                            <div id="p6" class="paper">
                                <div class="front">
                                    <div id="f6" class="front-content">
                                        <img [src]="pagine.pagine[10]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b6" class="back-content">
                                        <img [src]="pagine.pagine[11]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <!-- Paper 3 -->
                            <div id="p7" class="paper">
                                <div class="front">
                                    <div id="f7" class="front-content">
                                        <img [src]="pagine.pagine[12]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b7" class="back-content">
                                        <img [src]="pagine.pagine[13]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <!-- Paper 3 -->
                            <div id="p8" class="paper">
                                <div class="front">
                                    <div id="f8" class="front-content">
                                        <img [src]="pagine.pagine[14]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b8" class="back-content">
                                        <img [src]="pagine.pagine[15]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <!-- Paper 3 -->
                            <div id="p9" class="paper">
                                <div class="front">
                                    <div id="f9" class="front-content">
                                        <img [src]="pagine.pagine[16]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b9" class="back-content">
                                        <img [src]="pagine.pagine[17]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                            <div id="p10" class="paper">
                                <div class="front">
                                    <div id="f10" class="front-content">
                                        <img [src]="pagine.pagine[18]" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                                <div class="back">
                                    <div id="b10" class="back-content">
                                        <img [src]="pagine.pagine['back']" alt="Prima Pagina" class="w-100 shadow-sm">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-50 mx-auto d-flex justify-content-center">
                <!-- Previous Button -->
                <button id="prev-btn" (click)="goPrevPage()">
                    <i class="fa-solid fa-arrow-left"></i>
                </button>
                <!-- Next Button -->
                <button id="next-btn" (click)="goNextPage()">
                    <i class="fa-solid fa-arrow-right"></i>
                </button>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
            <!-- <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button> -->
            <a class="btn rounded-3 colorerosso fs-6 fs-md-5 my-3" (click)="modal.close('Close click'); closeModal(); saveMockup()" [routerLink]="['/acquisto/scegli-pacchetto']" role="button"
                >Seleziona</a
            >
        </div>
    </ng-container>
</ng-template>

<div class="container-fluid container-md px-3 px-md-5 pt-3 pb-3">
    <div class="d-flex justify-content-between align-items-center mt-3" *ngIf="!paginaSpeciale">
        <h3 class="mt-4 fw-bold">Scegli il Tema</h3>
        <a routerLink="/acquisto/scegli-tema" class="nav-link" style="color: #2B2D42!important" >Vedi Tutti</a>
    </div>
    <h3 class="pb-2 fw-bold mt-4" *ngIf="paginaSpeciale">Scegli il Tema</h3>
    <div class="row py-4 my-2">
        <div class="col-6 col-lg-3 mb-3 pb-2" *ngFor="let item of mockups; let i = index;">
            <div class="text-center image-container" (click)="openModal(i+1, content, mockup)" #mockup (mouseover)="showOverlay(i+1)" (mouseout)="hideOverlay(i+1)">
                <img [src]="item.pagine.intro" [class.opacity75]="isOverlayVisible[i+1] || isSelected[i+1]" alt="Immagine tema" class="bordoImg"/>
                <div class="overlay mx-auto start-50 translate-middle-x" [class.show]="isOverlayVisible[i+1]">
                    <p class="text-center m-0 p-0">Visualizza</p>
                </div>
                <div class="overlay2 mx-auto start-50 translate-middle-x" [class.show]="isSelected[i+1]">
                    <p class="text-center m-0 p-0 text-white fw-bold">Selezionato</p>
                </div>
            </div>
            <div class="text-center">
                <h6 class="fw-bolder m-0 mt-3 mb-2 p-0">Tema {{item.titolo}}</h6>
                <!-- <p class="m-0 p-0 pb-1">a partire</p> -->
                <ng-template #t2 let-fill2="fill">
                    <i class="bi-star-fill">
                        <i *ngIf="fill2>0" class="bi-star-fill filled" [style.width.%]="fill2"></i>
                    </i>
                </ng-template>
                <ngb-rating [(rate)]="item.voto" [starTemplate]="t2" [readonly]="true" [max]="5" [ariaValueText]="ariaValueText" /><span>({{item.recensioni}})</span>
                <p class="m-0 p-0">a partire da <span class="fs-5 fw-bolder blu">€ {{prezzoMin}}</span></p>
            </div>
        </div>
    </div>
</div>

<div class="position-fixed top-50 start-50 translate-middle z-3" *ngIf="attesa2">
    <div class="spinner-border spinner-colore" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>