<div>
    <h1 class="px-3 px-md-4 text-center display-1 py-4 fw-bolder">Welcome</h1>
    <div class="py-2" [ngClass]="pagina.mockup == 1 ? 'blu' : pagina.mockup == 2 ? 'celeste' : pagina.mockup == 3 ? 'marrone' : pagina.mockup == 4 ? 'nero' : ''"></div>
    <div class="py-2"></div>
    <div class="text-white" [ngClass]="pagina.mockup == 1 ? 'blu' : pagina.mockup == 2 ? 'celeste' : pagina.mockup == 3 ? 'marrone' : pagina.mockup == 4 ? 'nero' : ''">
        <div class="container-lg text-center py-5" *ngIf="caricato">
            <p class="fw-bold m-0 p-0">Select one of these languages</p>
            <hr class="mx-md-auto w-md-25 border-bottom border-2 border-white my-4">
            <div class="border w-md-25 mx-md-auto border-1 border-white clicca py-1 rounded-2 my-3" *ngIf="personalizzazione['lingue'].inglese || personalizzazione['linguaDefault']=='inglese'">
                <a class="nav-link" (click)="cambioLingua('inglese')"><b>English</b></a>
            </div>
            <div class="border w-md-25 mx-md-auto border-1 border-white clicca py-1 rounded-2 my-3" *ngIf="personalizzazione['lingue'].italiano || personalizzazione['linguaDefault']=='italiano'">
                <a class="nav-link" (click)="cambioLingua('italiano')"><b>Italiano</b></a>
            </div>
            <div class="border w-md-25 mx-md-auto border-1 border-white clicca py-1 rounded-2 my-3" *ngIf="personalizzazione['lingue'].tedesco || personalizzazione['linguaDefault']=='tedesco'">
                <a class="nav-link" (click)="cambioLingua('tedesco')"><b>Deutsch</b></a>
            </div>
            <div class="border w-md-25 mx-md-auto border-1 border-white clicca py-1 rounded-2 my-3" *ngIf="personalizzazione['lingue'].spagnolo || personalizzazione['linguaDefault']=='spagnolo'">
                <a class="nav-link" (click)="cambioLingua('spagnolo')"><b>Español</b></a>
            </div>
            <div class="border w-md-25 mx-md-auto border-1 border-white clicca py-1 rounded-2 my-3" *ngIf="personalizzazione['lingue'].francese || personalizzazione['linguaDefault']=='francese'">
                <a class="nav-link" (click)="cambioLingua('francese')"><b>Français</b></a>
            </div>
            <hr class="mx-md-auto w-md-25 border-bottom border-2 border-white my-4">
            <p class="fw-bold m-0 p-0" *ngIf="linguaSelezionata=='inglese'">Your Guide to {{personalizzazione['nomeStruttura']}}</p>
            <p class="fw-bold m-0 p-0" *ngIf="linguaSelezionata=='italiano'">La tua guida a {{personalizzazione['nomeStruttura']}}</p>
            <p class="fw-bold m-0 p-0" *ngIf="linguaSelezionata=='tedesco'">Ihr Leitfaden für {{personalizzazione['nomeStruttura']}}</p>
            <p class="fw-bold m-0 p-0" *ngIf="linguaSelezionata=='spagnolo'">Tu guía para {{personalizzazione['nomeStruttura']}}</p>
            <p class="fw-bold m-0 p-0" *ngIf="linguaSelezionata=='francese'">Votre guide pour {{personalizzazione['nomeStruttura']}}</p>
            <div class="row w-lg-25 mx-lg-auto mb-2">
                <div class="col-6">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.indice">
                        <b>Indice</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.hostpage != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.hostpage">
                        <b>Host Page</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.istruzioni != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.istruzioni">
                        <b>Istruzioni</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.abouthouse != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.abouthouse">
                        <b>About House</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.gethouse != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.gethouse">
                        <b>Getting to the House</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.houserules != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.houserules">
                        <b>House Rules</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.houseguide != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.houseguide">
                        <b>House Guide</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.wifi != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.wifi">
                        <b>Wifi</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.piscina != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.piscina">
                        <b>Piscina</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.gettingaround != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.gettingaround">
                        <b>Getting Around</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.placetosee != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.placetosee">
                        <b>Place to see 1</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.placetosee2 != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.placetosee2">
                        <b>Place to see 2</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.aboutcity != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.aboutcity">
                        <b>About the city</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.placeeat != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.placeeat">
                        <b>Place to eat</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.emergency != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.emergency">
                        <b>Emergency</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.feedback != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.feedback">
                        <b>Feedback</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.extra != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.extra">
                        <b>{{personalizzazione['extra1Titolo']}}</b>
                    </div>
                </div>
                <div class="col-6" *ngIf="pagine.extra2 != null">
                    <div class="border border-1 border-white clicca py-1 rounded-2 my-3" (click)="pdfViewerOnDemand.page = pagine.extra2">
                        <b>{{personalizzazione['extra2Titolo']}}</b>
                    </div>
                </div>
            </div>
            <div style="height: 70vh;">
                <ng2-pdfjs-viewer
                    #pdfViewerOnDemand
                    [showSpinner]="true"
                    [viewBookmark]="false"
                    [fullScreen]="false"
                    [find]="false"
                    [download]="false"
                    [startPrint]="false"
                    [print]="false"
                    [externalWindow]="false"
                    zoom="page-fit"
                    (onDocumentLoad)="caricamentoPdf($event)"
                ></ng2-pdfjs-viewer>

            </div>
            <p class="text-center mt-4">Powered by <a class="nav-link d-inline fw-semibold" routerLink="/">welcomebook.it</a></p>
        </div>
    </div>
</div>