<div class="container my-5 py-5">
    <div *ngIf="!recupero">
        <div class="w-100 w-lg-50 mx-auto border border-1 border-black rounded-2 p-4" *ngIf="inviato == false">
            <form #recuperoPassword="ngForm">
                <p>Inserisci la mail della quale vuoi recuperare la password, invieremo un link di reset se esiste un account collegato</p>
                <div class="form-floating">
                    <input type="email" class="form-control" name="email" ngModel />
                    <label for="email" class="form-label">Email</label>
                </div>
                <a
                    class="btn rounded-3 colorerosso fs-6 fs-md-5 mt-3"
                    (click)="recuperaPassword(recuperoPassword);"
                    role="button"
                    >Recupera Password</a
                >
            </form>
        </div>
        <div class="text-center" *ngIf="inviato == true">
            <h3>Email di recupero password inviata</h3>
            <p>Troverai tutti i dettagli nella mail che ti abbiamo inviato. Ha validità 10 minuti, dopo di che dovrai richiedere un nuovo link</p>
        </div>
    </div>
    <div class="w-100 w-lg-50 mx-auto border border-1 border-black rounded-2 p-4" *ngIf="recupero">
        <form #recuperoPassword2="ngForm">
            <p>Inserisci la nuova password del tuo account</p>
            <div class="form-floating">
                <input type="password" name="newPassword" ngModel class="form-control" />
                <label for="newPassword" class="form-label">Nuova Password</label>
            </div>
            <a
                class="btn rounded-3 colorerosso fs-6 fs-md-5 mt-3"
                (click)="changePassword(recuperoPassword2);"
                role="button"
                >Cambia Password</a
            >
        </form>
    </div>
</div>