import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { TemplateRefService } from '../service/template-ref.service';
import { ToastService } from '../service/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authSrv: AuthService, private router: Router, private templateRefSrv: TemplateRefService, private toastService: ToastService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authSrv.user$.pipe(
        take(1),
        map(user => {
          if (user) {
            return true
          }
          this.authSrv.redirectUrl = state.url.split('?')[0];
          this.authSrv.redirectQueryParams = { ...route.queryParams };
          // this.toastService.show({template: this.templateRefSrv.getTemplateRef('noLoggato'), classname: 'bg-secondary text-white', delay: 10000})
          this.authSrv.openLoginModal();
          return false
        })
      )
  }
  
}
