<div class="container-fluid container-md px-3 px-md-5 pt-3 pb-3" id="pacchetto">
  <h3 class="pb-2 fw-bold mt-4">Scegli il Pacchetto</h3>

  <div class="row py-2 px-md-4" [ngClass]="innerWidth >= 992 ? 'd-flex justify-content-center' : ''">
    <ng-container *ngFor="let pacchetto of pacchetti; let i = index">
      <!-- <div class="col-3 d-lg-none" *ngIf="i == (pacchetti.length - 1)"></div> -->
      <div class="col-6 py-3 col-lg-3 d-flex justify-content-center" [ngClass]="i == (pacchetti.length - 1) ? 'col-12' : ''">
        <div
          class="rounded-5 text-end text-lg-center position-relative w-xl-80 w-100 clicca" (click)="selezionaPacchetto(pacchetto.nome, i)"
          
        >
          <img
            [src]="(i == (pacchetti.length - 1) && innerWidth < 992) ? (pacchetto.selezionato ? 'assets/img/testimmxlhover.jpg' : 'assets/img/testimmxl.jpg'): (pacchetto.selezionato ? 'assets/img/testimmhover.jpg' : 'assets/img/testimm.jpg')"
            alt="Test"
            class="img-fluid rounded-5 classebordo"
          />
          <div
            class="position-absolute top-0 left-0 text-start py-4 pb-3 px-3 px-md-4 blu d-flex flex-column justify-content-between w-100 h-100"
          >
            <div>
              <h3 class="fw-semibold fs-md-3"  [ngClass]="pacchetto.selezionato ? 'text-white' : ''">{{pacchetto.nome}}</h3>
              <p class="fs-7 fs-sm-6 fs-md-5 fs-lg-7 fs-xxl-6 grigio p-0 m-0 interlinea" [ngClass]="pacchetto.selezionato ? 'text-white' : ''" [innerHTML]="pacchetto.contenuto"></p>
            </div>
  
            <div class=" d-flex justify-content-between">
              <h3 class="fs-lg-4"  [ngClass]="pacchetto.selezionato ? 'text-white' : ''">{{pacchetto.prezzo == 'Variabile' ? pacchetto.prezzo : '€ '+pacchetto.prezzo}}</h3>
              <i *ngIf="pacchetto.selezionato" class="bi bi-check-circle-fill fs-5 me-2 "  [ngClass]="pacchetto.selezionato ? 'text-white' : ''"></i>
  
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-3 d-lg-none" *ngIf="i == (pacchetti.length - 1)"></div> -->
    </ng-container>
  </div>
</div>

<div class="section">
  <div class="container-fluid container-md px-3 px-md-5 pb-4 pt-4">
    <h3 class="pb-0 fw-bold blu pt-1">{{ selezionato.titolo }}</h3>
    <div class="row py-0 pt-0 px-2 px-md-4">
      <div class="col-12 col-lg-4 py-4 order-md-1 px-md-4">
        <div
          class="border-bottom border-1 border-black px-2 d-flex justify-content-between"
        >
          <p class="py-2 m-0">Multilingua</p>
          <p class="py-2 m-0">{{ selezionato.traduzione }}</p>
        </div>
        <div
          class="border-bottom border-1 border-black px-2 d-flex justify-content-between"
        >
          <p class="py-2 m-0">Copia fisica Libro</p>
          <p class="py-2 m-0">{{ selezionato.copiaFisica }}</p>
        </div>
        <div
          class="border-bottom border-1 border-black px-2 d-flex justify-content-between"
        >
          <p class="py-2 m-0">QrCode Libro</p>
          <p class="py-2 m-0">{{ selezionato.qrcode }}</p>
        </div>
        <div
          class="border-bottom border-1 border-black px-2 d-flex justify-content-between"
        >
          <p class="py-2 m-0">Pdf Libro</p>
          <p class="py-2 m-0">{{ selezionato.pdf }}</p>
        </div>
      </div>
      <div
        class="col-12 col-lg-4 d-flex px-md-4 justify-content-end align-items-center" style="height: max-content; padding-top: 2.25rem; padding-bottom: 2.25rem;"
      >
        <img
          src="assets/img/introsectionprovisoriab.png"
          alt="Intro section provvisoria"
          class="w-100 border border-1 border-light rounded-4"
        />
      </div>
      <div class="col-12  px-md-4 col-lg-4 py-4 order-md-2">
        <p class="py-2">{{ selezionato.descrizione }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-3">
      <button type="button" class=" text-white px-5 py-1 text-semibold btn rounded-3 colorerosso fs-6 fs-md-5" (click)="procedi()">
        Procedi
      </button>
    </div>
  </div>
</div>
<app-recensionisection></app-recensionisection>