import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { Ticket } from '../interface/ticket.interface';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient) { }

  saveTicket(ticket: any) {
    return this.http.post<number>(`${environment.apiUrl}/tickets`, ticket)
  }

  getAllTickets() {
    return this.http.get<Ticket[]>(`${environment.apiUrl}/tickets`)
  }
}
