<header class="border-bottom-custom position-sticky top-0 bg-white z-3" *ngIf="showComponent" style="z-index: 12000000!important;">
  <div class="container-md g-0">
    <app-navbar></app-navbar>
  </div>
</header>
<main>
  <router-outlet></router-outlet>
</main>
<app-footer *ngIf="showComponent"></app-footer>

<div class="toast-container position-fixed top-0 end-0 p-3" style="z-index: 12000000;">
  <div *ngFor="let toast of toasts" class="mb-2">
    <ngb-toast
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)"
      style="z-index: 12000000;"
    >
      <ng-template [ngTemplateOutlet]="toast.template" [ngTemplateOutletContext]="toast.context"></ng-template>
    </ngb-toast>
  </div>
</div>

<app-scelta-mockup></app-scelta-mockup>