import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ordine } from 'src/app/interface/ordine.interface';
import { AuthService } from 'src/app/service/auth.service';
import { OrdineService } from 'src/app/service/ordine.service';

@Component({
  selector: 'app-dettagli-personalizzazione',
  templateUrl: './dettagli-personalizzazione.component.html',
  styleUrls: ['./dettagli-personalizzazione.component.scss']
})
export class DettagliPersonalizzazioneComponent implements OnInit {
  ordine!:Ordine;
  constructor(private activatedRoute: ActivatedRoute, private ordineSrv: OrdineService, private authSrv: AuthService) {}

  personalizzazione: {[key: string]:any} = {};

  ngOnInit(): void {
    this.authSrv.user$.subscribe((userData) => {
      if (userData) {
        if (userData.user) {
          this.activatedRoute.params.subscribe(params => {
            let id = params["id"];
            if (userData.user.id) this.ordineSrv.findOrdineByIdAndUtente2(Number(id), userData.user.id).subscribe((ordine) => {
              this.ordine = ordine;
              if (typeof this.ordine.personalizzazione == "string") {
                this.ordine.personalizzazione = JSON.parse(this.ordine.personalizzazione);
                if (typeof this.ordine.personalizzazione == "object") {
                  this.personalizzazione = this.ordine.personalizzazione;
                  this.personalizzazione['lingue'] = JSON.parse(this.ordine.personalizzazione['lingue'])
                  this.personalizzazione['copie'] = JSON.parse(this.ordine.personalizzazione['copie'])
                }
              }
            })
          })
        }
      }
    })
  }
}
