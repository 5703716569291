import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-recensionisection',
  templateUrl: './recensionisection.component.html',
  styleUrls: ['./recensionisection.component.scss']
})
export class RecensionisectionComponent {
  stato = false;
  rating = 4.5

  ariaValueText(current: number, max: number) {
		return `${current} out of ${max} hearts`;
	}

  constructor(private router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event.routerEvent.url == '/acquisto/scegli-pacchetto') {
        this.stato = true;
      } else {
        this.stato = false;
      }
    });
  }
}
