import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TemplateRefService } from 'src/app/service/template-ref.service';
import { TicketService } from 'src/app/service/ticket.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-contattattaci',
  templateUrl: './contattattaci.component.html',
  styleUrls: ['./contattattaci.component.scss']
})
export class ContattattaciComponent implements OnInit {
  contatto!: FormGroup;

  constructor(private fb: FormBuilder, private ref: ChangeDetectorRef, private ticketSrv: TicketService, private route: ActivatedRoute, private templateRef: TemplateRefService, private toast: ToastService) {
    this.contatto = this.fb.group({
      nomeCompleto: [null, [Validators.required]],
      telefono: [null, [Validators.pattern('^\\+?[0-9]{10,15}$')]],
      email: [null, [Validators.required, Validators.email]],
      tipoAssistenza: [null, [Validators.required]],
      descrizione: [null]
    })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const preventivo = params['preventivo']
      const ordine = params['ordine']
      const sicurezza = params['sicurezza']
      if (preventivo) {
        this.contatto.patchValue({
          tipoAssistenza: 'preventivo'
        })
      }else if (ordine) {
        this.contatto.patchValue({
          tipoAssistenza: 'ordini'
        })
      } else if (sicurezza) {
        this.contatto.patchValue({
          tipoAssistenza: 'sicurezza'
        })
      }
    })
  }

  get email() {
    return this.contatto.get('email')
  }
  get telefono() {
    return this.contatto.get('telefono')
  }
  get nomeCompleto() {
    return this.contatto.get('nomeCompleto')
  }

  get tipoAssistenza() {
    return this.contatto.get('tipoAssistenza')
  }

  valido: boolean = true;
  inviaAssistenza() {
    this.ref.detectChanges();
    if (!this.contatto.valid) {
      this.valido = false;
      return
    }
    this.valido = true;
    this.ticketSrv.saveTicket(this.contatto.value).subscribe((risultato) => {
      if (risultato) {
        // alert("Richiesta inviata!")
        this.toast.show({template: this.templateRef.getTemplateRef('richiestaInviata'), classname: 'bg-secondary text-white', delay: 5000})
        this.contatto.reset();
      }
    })
  }
}
