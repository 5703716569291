import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ordine } from 'src/app/interface/ordine.interface';
import { Utente } from 'src/app/interface/utente.interface';
import { FileUpload } from 'src/app/model/file-upload';
import { AuthService } from 'src/app/service/auth.service';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { OrdineService } from 'src/app/service/ordine.service';
import { RecensioniService } from 'src/app/service/recensioni.service';
import { TemplateRefService } from 'src/app/service/template-ref.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss']
})
export class ProfiloComponent implements OnInit {
  @ViewChild('content', {static: true}) contentTemplate!: TemplateRef<any>;
  selectedFiles?: FileList;
  currentFileUpload?: FormData;
  socialUser!: SocialUser;
  idordine!: number;
  private modalService = inject(NgbModal)
  user: Utente | undefined;
  ordini: Ordine[] = []
  attesa = true;
  async sendFeedback(form: NgForm) {
    let formData = { ...form.value, immagineUrl: "", ordineId: this.idordine };
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;
      if (file) {
        this.currentFileUpload = new FormData();
        this.currentFileUpload.append('file', file, file.name)
        let upload = await this.uploadSrv.uploadToBackEnd(this.currentFileUpload).toPromise()
        if (upload) {
          formData.immagineUrl = upload;
        }
      }
    }
    this.recensioniSrv.saveRecensione(formData).subscribe(); 
  }

  StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  changePassword(form: NgForm) {
    let newPassword = form.value.newPassword
    let result = this.StrongPasswordRegx.test(newPassword);
    if (result) {
      this.authSrv.changePassword(newPassword).subscribe(() => {
        this.logout();
      })
    } else {
      this.toastService.show({template: this.templateRefSrv.getTemplateRef('passwordStrong'), classname: 'bg-secondary text-white', delay: 5000})
    }
  }

  constructor(private authSrv: AuthService, private ordineSrv: OrdineService, private uploadSrv: FileUploadService, private recensioniSrv: RecensioniService, private socialAuthService: SocialAuthService, private activatedRoute: ActivatedRoute, private toastService: ToastService, private templateRefSrv: TemplateRefService) {}

  logout() {
    this.authSrv.logout();
    localStorage.clear();
    if (this.socialUser) {
      this.socialAuthService.signOut()
    }
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  ngOnInit(): void {
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
    })
    this.authSrv.user$.subscribe((data) => {
      this.user = data?.user;
      setTimeout(() => {
        if (this.user && this.user.id) {
          this.ordineSrv.findOrdiniByUtente(this.user.id).subscribe((ordini) => {
            this.ordini = ordini;
            this.attesa = false;
            this.activatedRoute.queryParams.subscribe((data) => {
              if (data['feedback'] && data["ordine"]) {
                let index = this.ordini.findIndex((ordine) => {
                  return ordine.id == data["ordine"];
                })
                if (index != -1) {
                  this.openModalFeedback(this.contentTemplate, Number(data["ordine"]))
                }
              } else if (data["ordine"]) {
                let index = this.ordini.findIndex((ordine) => {
                  return ordine.id == data["ordine"];
                })
                if (index != -1) {
                  this.openModalDettagli(this.ordini[index])
                }
              }
            })
          })
        }
      }, 500)
    })
  }

  openModalFeedback(content:TemplateRef<any>, ordine: number) {
    this.idordine = ordine;
    this.modalService.open(content, {size: 'lg', centered: true})
  }

  openModalCambioPassword(content:TemplateRef<any>) {
    this.modalService.open(content, {size: 'lg', centered: true})
  }

  openModalDettagli(ordine: Ordine) {
    this.ordineSrv.openDettagliOrdine(ordine);
  }
}
