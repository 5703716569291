import { ChangeDetectorRef, Component, HostListener, OnInit, TemplateRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from 'src/app/interface/can-component-deactivate.interface';
import { Prodotto } from 'src/app/interface/prodotto.interface';
import { Utente } from 'src/app/interface/utente.interface';
import { AuthService } from 'src/app/service/auth.service';
import { EnvironmentService } from 'src/app/service/environment.service';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { OrdineService } from 'src/app/service/ordine.service';
import { TemplateRefService } from 'src/app/service/template-ref.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-personalizza',
  templateUrl: './personalizza.component.html',
  styleUrls: ['./personalizza.component.scss']
})
export class PersonalizzaComponent implements OnInit, CanComponentDeactivate {
  pagine: any = {}
  user: Utente | undefined;
  controllo = false;
  immagineZoommata: string = "";
  model: any = {
    pagina1: true,
    pagina2: true,
    pagina3: true,
    pagina4: true,
    pagina5: true,
    pagina6: true,
    pagina7: true,
    pagina8: true,
    pagina9: true,
    pagina10: true,
    pagina11: true,
    pagina12: true,
    pagina13: true,
    pagina14: true,
    pagina15: true,
    pagina16: true,
    pagina17: true,
    pagina18: true,
    linguaDefault: "-1",
    nomeHost: "",
    cognomeHost: "",
    nomeStruttura: "",
    indirizzoStruttura: "",
    fotoCopertina: "",
    contattoTelefono: "",
    email: "",
    fotoHost: "",
    descrizioneHost: "",
    istruzioniCheckIn: "",
    istruzioniCheckOut: "",
    cancellazione: "",
    fotoCasa: "",
    camere: -1,
    bagni: -1,
    parcheggi: -1,
    stanze: -1,
    descrizioneCasa: "",
    descrizioneArrivo: "",
    descrizioneArrivo2: "",
    regoleCasa: "",
    guidaCasa: "",
    nomeInternet: "",
    passwordInternet: "",
    descrizioneElettronica: "",
    fotoPiscina: "",
    descrizionePiscina: "",
    descrizioneAttorno: "",
    descrizioneVisite: "",
    fotoVisita1: "",
    descrizioneVisita1: "",
    fotoVisita2: "",
    descrizioneVisita2: "",
    fotoCitta: "",
    descrizioneCitta: "",
    descrizioneCibo: "",
    descrizioneEmergenza: "",
    airBnb: "-1",
    booking: "-1",
    feedback: "",
    extra1Titolo: "",
    extra1Descrizione: "",
    extra2Titolo: "",
    extra2Descrizione: ""
  }

  lingue: {[key: string]: boolean} = {
    ["inglese"]: false,
    ["italiano"]: false,
    ["tedesco"]: false,
    ["francese"]: false,
    ["spagnolo"]: false

  }

  prezzi!: {
    traduzione: number,
    copia: number,
    spedizione: number
  }

  copie: {
    lingua: string,
    copie: string
  }[] = [];

  pacchetto!: Prodotto;

  totaleCopie: number = 0;
  totaleTraduzioni: number = 0;

  basic: boolean = false;

  constructor(private environmentSrv: EnvironmentService, private service: FileUploadService, private ordineSrv: OrdineService, private authSrv: AuthService, private router: Router, private cdr: ChangeDetectorRef, private templateRefSrv: TemplateRefService, private toastService: ToastService) {}

  changeLinguaDef(value: string) {
    this.lingue = {
      ["inglese"]: false,
      ["italiano"]: false,
      ["tedesco"]: false,
      ["francese"]: false,
      ["spagnolo"]: false
  
    }
    if (this.lingue[value] == false) this.lingue[value] = true;
    this.totaleTraduzioni = 0;
    this.totaleCopie = 0;
    this.copie = []
    if (localStorage.getItem('pacchetto') != "1") {
      let copia = {
        lingua: "",
        copie: "1"
      }
      this.copie.push(copia);
    }
  }

  changeLinguaTrad(lingua: string, def?: boolean) {
    let found = false;
    this.copie.forEach((copia) => {
      if (copia.lingua == lingua) {
        found = true
      }
    })
    if (!found) {
      this.lingue[lingua] = !this.lingue[lingua]
      if (!def) {
        if (this.lingue[lingua] && lingua != "-1") {
          this.totaleTraduzioni += this.prezzi.traduzione
        } else {
          this.totaleTraduzioni -= this.prezzi.traduzione
        }
      }
    }
  }

  aggiungiCopia() {
    this.copie.push({
      lingua: "",
      copie: "1"
    })
    if (this.copie.length > 1) {
      this.totaleCopie += this.prezzi.copia
    }
    this.cdr.detectChanges()
  }

  changeLingua(indice: number, lingua:string) {
    let numLingua = 0
    this.copie.forEach((copia) => {
      if (copia.lingua == lingua) {
        numLingua += 1
      }
    })
    if (this.copie[indice].lingua != this.model.linguaDefault && this.copie[indice].lingua != lingua && numLingua == 1) {
      this.totaleTraduzioni -= this.prezzi.traduzione
    }
    this.copie[indice].lingua = lingua;
    if (!this.lingue[lingua] && lingua != "-1" && lingua != this.model.linguaDefault) {
      this.lingue[lingua] = true
      this.totaleTraduzioni += this.prezzi.traduzione
    }
    this.cdr.detectChanges()
  }

  changeCopie(indice: number, copie: string) {
    this.copie[indice].copie = copie;
    this.totaleCopie = 0;
    this.copie.forEach((copia, index) => {
      if (index == 0) {
        return
      }
      this.totaleCopie += (Number(copia.copie) * this.prezzi.copia)
    })
    this.cdr.detectChanges()
  }

  removeCopia(indice: number) {
    this.copie.splice(indice, 1)
    this.totaleCopie -= this.prezzi.copia
    this.cdr.detectChanges()
  }

  changeImmagine(event: any, tipo: string) {
    const file: File = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      this.service.uploadToBackEnd(formData).subscribe(response => {
        console.log(response)
        this.model[tipo] = response;
      }, error => {
        console.log(error)
        this.toastService.show({template: this.templateRefSrv.getTemplateRef('erroreCaricamentoImmagine'), classname: 'bg-secondary text-white', delay: 10000})
      })
    }
  }

  onImageClick(content:TemplateRef<any>, url: string) {
    this.immagineZoommata = url;
    this.modalService.open(content, { ariaLabelledBy: 'modale-immagine-zoommata', centered: true }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
  }

  changePage(index: number, event?: Event, pulsante?: boolean) {
    if (index == 1) {
      this.toastService.show({template: this.templateRefSrv.getTemplateRef('paginaNonRimuovibile'), classname: 'bg-secondary text-white', delay: 10000})
      this.model['pagina'+index] = true;
      if (event) {
        const checkbox = event.target as HTMLInputElement;
        checkbox.checked = true;
      }
      return;
    }
    let page = document.getElementById('page-'+index) as HTMLDivElement;
    if (page) {
      if (page.classList.contains('d-none')) {
        this.model['pagina'+index] = true;
        page.classList.remove('d-none')
      } else {
        page.classList.add('d-none')
        this.model['pagina'+index] = false;
        if (pulsante) {
          this.toastService.show({template: this.templateRefSrv.getTemplateRef('elementoRimosso'), classname: 'bg-secondary text-white', delay: 10000})
        }
      }
    }
  }

  controlloForm() : boolean {
    if (this.model.linguaDefault == "-1") return false
    if (Number(localStorage.getItem('pacchetto')) != 1) {
      let apposto = true;
      this.copie.forEach((copia: {lingua:string, copie: string}) => {
        if (copia.lingua == "") {
          apposto = false;
        }
      })
      if (!apposto) return false
    }
    if (this.model.pagina1) {
      if (this.model.nomeHost == "" || this.model.cognomeHost == "" || this.model.nomeStruttura == "" || this.model.indirizzoStruttura == "" || this.model.fotoCopertina == "") return false
    }
    if (this.model.pagina2) {
      // if (this.model.contattoTelefono == "" || this.model.email == "" || this.model.fotoHost == "" || this.model.descrizioneHost == "") return false;
      if (this.model.contattoTelefono == "" || this.model.email == "" || this.model.descrizioneHost == "") return false;
    }
    if (this.model.pagina3) {
      if (this.model.istruzioniCheckIn == "" || this.model.istruzioniCheckOut == "" || this.model.cancellazione == "") return false;
    }
    if (this.model.pagina4) {
      if (this.model.fotoCasa == "" || this.model.descrizioneCasa == "") return false;
    }
    if (this.model.pagina5) {
      if (this.model.descrizioneArrivo == "" || this.model.descrizioneArrivo2 == "") return false;
    }
    if (this.model.pagina6) {
      if (this.model.regoleCasa == "") return false;
    }
    if (this.model.pagina7) {
      if (this.model.guidaCasa == "") return false;
    }
    if (this.model.pagina8) {
      if (this.model.nomeInternet == "" || this.model.passwordInternet == "" || this.model.descrizioneElettronica == "") return false;
    }
    if (this.model.pagina9) {
      if (this.model.fotoPiscina == "" || this.model.descrizionePiscina == "") return false;
    }
    if (this.model.pagina10) {
      if (this.model.descrizioneAttorno == "") return false;
    }
    if (this.model.pagina11) {
      if (this.model.descrizioneVisite == "") return false;
    }
    if (this.model.pagina12) {
      if (this.model.fotoVisita1 == "" || this.model.descrizioneVisita1 == "" || this.model.fotoVisita2 == "" || this.model.descrizioneVisita2 == "") return false;
    }
    if (this.model.pagina13) {
      if (this.model.fotoCitta == "" || this.model.descrizioneCitta == "") return false;
    }
    if (this.model.pagina14) {
      if (this.model.descrizioneCibo == "") return false;
    }
    if (this.model.pagina15) {
      if (this.model.descrizioneEmergenza == "") return false;
    }
    if (this.model.pagina16) {
      if (this.model.airBnb == "-1" || this.model.booking == "-1" || this.model.feedback == "") return false;
    }
    if (this.model.pagina17) {
      if (this.model.extra1Titolo == "" || this.model.extra1Descrizione == "") return false
    }
    if (this.model.pagina18) {
      if (this.model.extra2Titolo == "" || this.model.extra2Descrizione == "") return false
    }
    return true
  }

  procedi() {
    if (this.user) {
      // this.model.utenteId = this.user.id;
      // this.model.mockup = Number(localStorage.getItem('mockup'))
      // this.model.pacchetto = Number(localStorage.getItem('pacchetto'))
      if (this.controlloForm()) {
        this.controllo = false;
        this.model.lingue = JSON.stringify(this.lingue)
        this.model.copie = JSON.stringify(this.copie)
        const ordine = {
          utenteId: this.user.id,
          mockup: Number(localStorage.getItem('mockup')),
          pacchetto: Number(localStorage.getItem('pacchetto')),
          personalizzazione: JSON.stringify(this.model)
        }
        this.backup = this.model;
        if (localStorage.getItem('ordineSelezionato')) {
          this.ordineSrv.patchModificaOrdine(Number(localStorage.getItem('ordineSelezionato')), ordine).subscribe((data) => {
            this.router.navigate(["/acquisto/buy"])
          })
        } else {
          this.ordineSrv.saveOrdine(ordine).subscribe((data) => {
            localStorage.setItem('ordineSelezionato', data.toString())
            this.router.navigate(['/acquisto/buy'])
          })
        }
      } else {
        this.toastService.show({template: this.templateRefSrv.getTemplateRef('formDaCompletare'), classname: 'bg-secondary text-white', delay: 10000})
        this.controllo = true;
      }
    } else {
      this.authSrv.openLoginModal();
    }
  }

  backup: {[key: string]: any} = {};

  controlloCambiamenti(): boolean {
    this.model.lingue = JSON.stringify(this.lingue)
    this.model.copie = JSON.stringify(this.copie)
    let uguale = true
    for (let key in this.model) {
      if (this.backup[key] != this.model[key]) {
        uguale = false;
      }
    }
    return uguale;
  }

  @HostListener("window:beforeunload", ['$event'])
  unloadNotification($event:any):void {
    let uguale = this.controlloCambiamenti()
    if (!uguale) {
      $event.returnValue = "Ci sono modifiche non salvate. Sei sicuro di voler uscire?";
    }
  }

  canDeactivate(): boolean {
    let uguale = this.controlloCambiamenti()
    if (!uguale) {
      return confirm('Ci sono modifiche non salvate. Sei sicuro di voler uscire?');
    }
    return true
  }

  async ngOnInit() {
    this.authSrv.user$.subscribe((user) => {
      this.user = user?.user;
      if (localStorage.getItem('ordineSelezionato') && this.user && this.user.id) {
        this.ordineSrv.findOrdineByIdAndUtente2(Number(localStorage.getItem('ordineSelezionato')), this.user.id).subscribe((ordine) => {
          if (typeof ordine.personalizzazione == "string") {
            this.model = JSON.parse(ordine.personalizzazione);
            this.backup = JSON.parse(ordine.personalizzazione);
          }
          this.lingue = JSON.parse(this.model.lingue);
          this.copie = JSON.parse(this.model.copie);

          for (let key in this.lingue) {
            if (key != this.model.linguaDefault && this.lingue[key]) {
              this.totaleTraduzioni += this.prezzi.traduzione
            }
          }
        })
      }
    })
    if (localStorage.getItem('pacchetto') != "1") {
      let copia = {
        lingua: "",
        copie: "1"
      }
      this.copie.push(copia);
    } else {
      this.basic = true;
    }
    this.environmentSrv.getAllPrezzi().subscribe((prezzi) => {
      this.prezzi = prezzi;
    })
    this.environmentSrv.getAllPacchetti().subscribe((pacchetti) => {
      let pacchetto = pacchetti.find(pacchetto => pacchetto.id == Number(localStorage.getItem("pacchetto")))
      if (pacchetto) {
        this.pacchetto = pacchetto;
      }
    })
    this.environmentSrv.getAllMockup().subscribe((mockups) => {
      mockups = mockups.sort((a, b) => a.id - b.id)
      let pagine = mockups.find(mockup => mockup.id === Number(localStorage.getItem("mockup")))
      if (pagine) {
        this.pagine = pagine.pagine;
      }
    })
  }

  private modalService = inject(NgbModal);

	open(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modale-impostazioni-mobile', centered: true }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
	}
}
