<div class="container my-5">
    <h3>Recensioni</h3>
    <div class="row">
        <div class="col-4">
            <div class="container-fluid bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5 classebordo my-4" style="height: max-content!important;" *ngFor="let recensione of colonna1">
                <div class="row d-flex align-items-center ">
                    <div class="col-7">
                        <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">{{recensione.nomeCompleto}}</h5>
                    </div>
                    <div class="col-5 d-flex justify-content-end">
                        <ng-template #t let-fill="fill">
                            <i class="bi-star-fill">
                                <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                            </i>
                        </ng-template>
                        <ngb-rating [rate]="recensione.voto" [starTemplate]="t" [readonly]="true" [max]="5" />
                    </div>
                </div>
                <p class="m-0 w-100 mt-1" style="font-style: italic;word-break: break-all;">"{{recensione.feedback}}"</p>
                <img class="w-100 rounded-3 my-3" [src]="recensione.immagineUrl" *ngIf="recensione.immagineUrl && recensione.immagineUrl!= ''" alt="Immagine recensione"/>
                <div class="d-flex justify-content-end">
                    <span class="fs-7 coloreGrigio">{{recensione.giorno}} {{getMese(recensione.mese)}} {{recensione.anno}}</span>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="container-fluid bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5 classebordo my-4" style="height: max-content!important;" *ngFor="let recensione of colonna2">
                <div class="row d-flex align-items-center ">
                    <div class="col-7">
                        <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">{{recensione.nomeCompleto}}</h5>
                    </div>
                    <div class="col-5 d-flex justify-content-end">
                        <ng-template #t let-fill="fill">
                            <i class="bi-star-fill">
                                <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                            </i>
                        </ng-template>
                        <ngb-rating [rate]="recensione.voto" [starTemplate]="t" [readonly]="true" [max]="5" />
                    </div>
                </div>
                <p class="m-0 w-100 mt-1" style="font-style: italic;word-break: break-all;">"{{recensione.feedback}}"</p>
                <img class="w-100 rounded-3 my-3" [src]="recensione.immagineUrl" *ngIf="recensione.immagineUrl && recensione.immagineUrl!= ''" alt="Immagine recensione"/>
                <div class="d-flex justify-content-end">
                    <span class="fs-7 coloreGrigio">{{recensione.giorno}} {{getMese(recensione.mese)}} {{recensione.anno}}</span>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="container-fluid bg-white w-100 h-100 rounded-4 py-3 px-4 px-lg-5 classebordo my-4" style="height: max-content!important;" *ngFor="let recensione of colonna3">
                <div class="row d-flex align-items-center ">
                    <div class="col-7">
                        <h5 class="mb-0 no-wrap ellipsis fs-6 fs-md-5">{{recensione.nomeCompleto}}</h5>
                    </div>
                    <div class="col-5 d-flex justify-content-end">
                        <ng-template #t let-fill="fill">
                            <i class="bi-star-fill">
                                <i *ngIf="fill>0" class="bi-star-fill filled" [style.width.%]="fill"></i>
                            </i>
                        </ng-template>
                        <ngb-rating [rate]="recensione.voto" [starTemplate]="t" [readonly]="true" [max]="5" />
                    </div>
                </div>
                <p class="m-0 w-100 mt-1" style="font-style: italic;word-break: break-all;">"{{recensione.feedback}}"</p>
                <img class="w-100 rounded-3 my-3" [src]="recensione.immagineUrl" *ngIf="recensione.immagineUrl && recensione.immagineUrl!= ''" alt="Immagine recensione"/>
                <div class=" d-flex justify-content-end">
                    <span class="fs-7 coloreGrigio">{{recensione.giorno}} {{getMese(recensione.mese)}} {{recensione.anno}}</span>
                </div>
            </div>
        </div>
    </div>
</div>