import { Component, inject, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Mockup } from 'src/app/interface/mockup.interface';
import { Ordine } from 'src/app/interface/ordine.interface';
import { Prodotto } from 'src/app/interface/prodotto.interface';
import { Utente } from 'src/app/interface/utente.interface';
import { AuthService } from 'src/app/service/auth.service';
import { EnvironmentService } from 'src/app/service/environment.service';

@Component({
  selector: 'app-dettaglio-ordine',
  templateUrl: './dettaglio-ordine.component.html',
  styleUrls: ['./dettaglio-ordine.component.scss']
})
export class DettaglioOrdineComponent implements OnInit{
  @Input() ordine!: Ordine;
  user: Utente | undefined;
  pacchetti!: Prodotto[];
  prezzi!: {
    traduzione: number,
    copia: number,
    spedizione: number
  };
  temi!: Mockup[];
  nomeStruttura = "";
  personalizzazione: any;

  constructor(public modal: NgbActiveModal, private authSrv: AuthService, private router: Router, private environmentSrv: EnvironmentService) {
  }

  temaById(mockupId: number) {
    let tema = this.temi.find((mockup) => mockup.id = mockupId)
    return tema && (typeof tema.pagine == 'string' && JSON.parse(tema.pagine) || tema.pagine)
  }

  getNomePacchetto(pacchetto: number) {
    return this.pacchetti.find((prodotto) => prodotto.id == pacchetto)?.nome
  }
  
  ngOnInit() {
    this.environmentSrv.getAllPacchetti().subscribe((pacchetti) => {
      this.pacchetti = pacchetti;
    })
    this.environmentSrv.getAllMockup().subscribe((mockup) => {
      this.temi = mockup.sort((a, b) => a.id - b.id);
    })
    this.environmentSrv.getAllPrezzi().subscribe((prezzi) => {
      this.prezzi = prezzi;
    })
    this.authSrv.user$.subscribe((user) => {
      if (typeof this.ordine.personalizzazione == "string") this.personalizzazione = JSON.parse(this.ordine.personalizzazione);
      this.personalizzazione.lingue = JSON.parse(this.personalizzazione.lingue)
      this.personalizzazione.copie = JSON.parse(this.personalizzazione.copie)
      this.nomeStruttura = this.personalizzazione.nomeStruttura.replaceAll(" ", "-")
      this.nomeStruttura = this.nomeStruttura.replaceAll("_", "-")
      this.nomeStruttura = this.nomeStruttura.toLowerCase();
      this.user = user?.user;
    })
  }

  paga() {
    localStorage.setItem('mockup', JSON.stringify(this.ordine.mockup))
    localStorage.setItem('pacchetto', JSON.stringify(this.ordine.pacchetto))
    localStorage.setItem('ordineSelezionato', JSON.stringify(this.ordine.id))
    this.router.navigate(['/acquisto/buy'])
  }

  calcoloTraduzioni() {
    let traduzioni = 0;
    for (const [key, value] of Object.entries(this.personalizzazione.lingue)) {
      if (value && key != this.personalizzazione.linguaDefault) {
        traduzioni += this.prezzi.traduzione;
      }
    }
    return traduzioni;
  }

  calcoloCopie() {
    let copie = 0;
    this.personalizzazione.copie.forEach((copia: {
      lingua: string,
      copie: string
    }) => {
      let copieNum = Number(copia.copie)
      copie += (copieNum * this.prezzi.copia)
    })
    return copie;
  }

  assistenza() {
    this.router.navigate(
      ['/contattattaci'], {
      queryParams: {
        ordine: true
      },
      queryParamsHandling: 'merge'
    })
  }

  calcoloTotale(iva?: boolean) {
    let spedizione = this.ordine.pacchetto != 1 ? this.prezzi.spedizione : 0;
    let pacchetto = this.pacchetti.find(prodotto => prodotto.id == this.ordine.pacchetto)
    let totale = parseFloat(pacchetto ? pacchetto.prezzo.replace('€', '').trim() : "0.00") + this.calcoloTraduzioni() + this.calcoloCopie() + spedizione;
    if (iva) {
      totale += Number(this.calcolaPercentuale(this.calcoloTotale(), 22))
    }
    return totale
  }

  calcolaPercentuale(prezzo: number, percentuale: number): string {
    return (prezzo * (percentuale / 100)).toFixed(2);
  }

  calcoloIva() {
    
    return this.calcolaPercentuale(this.calcoloTotale(), 22)
  }

  openInANewWindow(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank')
  }
}
