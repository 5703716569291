<!-- <ng-template #content let-modal>
	<div class="modal-header border-0 pb-1 pb-md-2">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body py-1 py-md-2 border-0">
		<div class="container-md container-fluid h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12">
          <div class="card border-0" style="border-radius: 1rem;">
            <div class="card-body p-md-5 py-md-0 text-center" *ngIf="singin">
  
              <h3 class="mb-3 coloretestoblu fw-bold" >Sign in</h3>
              <form #loginForm="ngForm">
                <div class="form-floating mb-4 mt-3">
                  <input type="email" id="typeEmailX-2" name="email" class="form-control form-control-lg" ngModel />
                  <label class="form-label" for="typeEmailX-2">Email</label>
                </div>
    
                <div class="form-floating mb-4">
                  <input type="password" id="typePasswordX-2" name="password" class="form-control form-control-lg" ngModel />
                  <label class="form-label" for="typePasswordX-2">Password</label>
                </div>
              
                <button data-mdb-button-init data-mdb-ripple-init class="btn btn-lg btn-block py-1 pulsanteblu"  type="button" (click)="login(loginForm);modal.dismiss('Cross click')">Login</button>
              </form>
              
              <hr class="my-md-4">
              
              <button data-mdb-button-init data-mdb-ripple-init class="btn w-100 btn-primary mb-2 border-0" style="background-color: #8D99AE;"
              type="submit"><i class="bi bi-google me-2"></i> Sign in with google</button>
              <button data-mdb-button-init data-mdb-ripple-init class="btn w-100 btn-primary mb-2 border-0" style="background-color: #8D99AE;"
              type="submit"><i class="bi bi-facebook me-2"></i>Sign in with facebook</button>
              
              <div>
                <p class="mt-2 mb-0" style="color: #8D99AE">Don't have an account? <a (click)="singin = false;" class="text-primary-50 fw-bold" style="color: #2B2D42; text-decoration: none;">Sign Up</a>
                </p>
              </div>
              <div>
                <p class="mt-1" style="color: #8D99AE">Don't remember password? <a  class="text-primary-50 fw-bold" style="color: #2B2D42; text-decoration: none;">Recover</a>
                </p>
              </div>
            </div>
            <div class="card-body p-md-5 py-md-0 text-center" *ngIf="!singin">
  
              <h3 class="mb-3 coloretestoblu fw-bold">Sign Up</h3>
              <form #registrazione="ngForm">
                <div class="form-floating mb-4 mt-3">
                  <input type="text" id="nome" name="nome" class="form-control form-control-lg" ngModel />
                  <label class="form-label" for="nome">Nome</label>
                </div>

                <div class="form-floating mb-4 mt-3">
                  <input type="text" id="cognome" name="cognome" class="form-control form-control-lg" ngModel />
                  <label class="form-label" for="cognome">Cognome</label>
                </div>
    
                <div class="form-floating mb-4 mt-3">
                  <input type="email" id="email" name="email" class="form-control form-control-lg" ngModel />
                  <label class="form-label" for="email">Email</label>
                </div>
    
                <div class="form-floating mb-4">
                  <input type="password" id="password" name="password" class="form-control form-control-lg" ngModel />
                  <label class="form-label" for="password">Password</label>
                </div>
    
                <button data-mdb-button-init data-mdb-ripple-init class="btn  btn-lg btn-block mb-3  py-1 pulsanteblu" type="button" (click)="register(registrazione)">Register</button>
              </form>

              <div>
                <p class="mt-2 mb-0" style="color: #8D99AE">Have already an account? <a (click)="singin = true;" class="text-primary-50 fw-bold" style="color: #2B2D42; text-decoration: none;">Sign In</a>
                </p>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
	<div class="modal-footer  border-0">
		
	</div>
</ng-template> -->

<nav class="navbar navbar-expand-lg p-0">
  <div class="container-fluid g-0 d-flex align-items-center justify-content-between">
    <a class="navbar-brand navbar-logo d-flex align-items-center py-1" routerLink="/">
      <img
        src="assets/img/logonavw.png"
        alt=""
        class="h-100"
        width="200"
        id="logoHeader"
      />
    </a>
    <div>
      <!-- <a href="#download" class="header0__download order-1 d-lg-none"
        >Download</a
      > -->
      <button id="accediHeader1" type="button" class="btn btn-success border-0 rounded-4 py-1 coloreblu d-lg-none mx-0 mx-md-2" (click)="accedi()">{{user ? "Profilo" : "Accedi"}}</button>
      <button
        class="navbar-toggler px-2 mx-1 me-2 mx-md-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-label="Toggle navigation"
      >
        <!-- <span
          class="navbar-toggler-icon"
          style="
            background-image: url('https://clochez.com/frontend_assets/media/img/logos/hamburger.svg');
          "
        ></span> -->
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse justify-content-md-end" id="navbarNav">
      <ul class="navbar-nav d-block d-lg-flex align-items-center">
        <li
          class="nav-item mx-2"
        >
          <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="nav-link py-0">Homepage</a>
        </li>
        <li
          class="nav-item mx-2"
        >
          <a [routerLink]="['/acquisto/scegli-tema']" routerLinkActive="active"  class="nav-link py-0"
            >Temi</a
          >
        </li>
        <li
          class="nav-item mx-2 mb-3 mb-lg-0"
        >
          <a [routerLink]="['/contattattaci']" class="nav-link py-0"
            >Contatti</a
          >
        </li>
        <li class="nav-item d-none d-lg-block p-0 mx-0 mx-md-2">
            <button id="accediHeader2" type="button" (click)="accedi()" class="btn btn-success border-0 rounded-4 py-1 coloreblu">{{user ? "Profilo" : "Accedi"}}</button>
          <!-- <a
            href="javascript:void(0)"
            class="header0__download order-1"
            href="javascript:void(0);"
            >Download</a
          > -->
        </li>
      </ul>
    </div>
  </div>
</nav>
