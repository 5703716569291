import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TemplateRefService } from '../service/template-ref.service';
import { ToastService } from '../service/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AcquistaGuard implements CanActivate {
  constructor(private templateRefSrv: TemplateRefService, private toastService: ToastService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let ordine = localStorage.getItem("ordineSelezionato")
    if (ordine) {
      return true
    }
    ordine = route.queryParams['ordine'];
    if (ordine) {
      return true
    }
    this.toastService.show({template: this.templateRefSrv.getTemplateRef('sceltaOrdine'), classname: 'bg-secondary text-white', delay: 10000})
    return false;
  }
  
}
