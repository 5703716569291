import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './component/navbar/navbar.component';
import { HomeComponent } from './component/home/home.component';
import { IntrosectionComponent } from './component/home/introsection/introsection.component';
import { RecensionisectionComponent } from './component/home/recensionisection/recensionisection.component';
import { DomandesectionComponent } from './component/home/domandesection/domandesection.component';
import { MediumsectionComponent } from './component/home/mediumsection/mediumsection.component';
import { FooterComponent } from './component/footer/footer.component';
import { MokupsectionComponent } from './component/home/mokupsection/mokupsection.component';
import { ProcessoComponent } from './component/processo/processo.component';
import { ScegliPacchettoComponent } from './component/processo/scegli-pacchetto/scegli-pacchetto.component';
import { PersonalizzaComponent } from './component/processo/personalizza/personalizza.component';
import { AcquistaComponent } from './component/processo/acquista/acquista.component';
import { ProfiloComponent } from './component/profilo/profilo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { LoginComponent } from './component/login/login.component';
import { CustomStatusPipe } from './pipe/custom-status.pipe';
import { DettaglioOrdineComponent } from './component/dettaglio-ordine/dettaglio-ordine.component';
import { ContattattaciComponent } from './component/contattattaci/contattattaci.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { RecensioniComponent } from './component/recensioni/recensioni.component';
import { SceltaMockupComponent } from './component/toast/scelta-mockup/scelta-mockup.component';
import { PacchettoGuard } from './guard/pacchetto.guard';
import { ToastService } from './service/toast.service';
import { TemplateRefService } from './service/template-ref.service';
import { PagamentoComponent } from './component/processo/pagamento/pagamento.component';
import { DettagliPersonalizzazioneComponent } from './component/dettagli-personalizzazione/dettagli-personalizzazione.component';
import { PagineQrcodeComponent } from './component/pagine-qrcode/pagine-qrcode.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PdfQrcodeComponent } from './component/pdf-qrcode/pdf-qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FacebookLoginProvider, GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { GoogleSigninComponent } from './component/google-signin/google-signin.component';
import { DomandeComponent } from './component/domande/domande.component';
import { NgxStripeModule } from 'ngx-stripe';
import { RecuperoPasswordComponent } from './component/recupero-password/recupero-password.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    IntrosectionComponent,
    RecensionisectionComponent,
    DomandesectionComponent,
    MediumsectionComponent,
    FooterComponent,
    MokupsectionComponent,
    ProcessoComponent,
    ScegliPacchettoComponent,
    PersonalizzaComponent,
    AcquistaComponent,
    ProfiloComponent,
    LoginComponent,
    CustomStatusPipe,
    DettaglioOrdineComponent,
    ContattattaciComponent,
    PageNotFoundComponent,
    RecensioniComponent,
    SceltaMockupComponent,
    PagamentoComponent,
    DettagliPersonalizzazioneComponent,
    PagineQrcodeComponent,
    PdfQrcodeComponent,
    GoogleSigninComponent,
    DomandeComponent,
    RecuperoPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgbPopoverModule,
    PdfJsViewerModule,
    QRCodeModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    NgxStripeModule.forRoot("pk_test_51Pg6hjRxJO1qGoOg9427om3FKiqTCYS8GabMA4PkIaG8vbyNcxAHau7WA1hEBrlsm80gJq1TBwzL2OSvDHhwljwu008fxToXyF")
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    PacchettoGuard,
    ToastService,
    TemplateRefService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider("322859662928-2t58a9pll45jtkbkf569tlmqanaib32h.apps.googleusercontent.com")
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("485225587598484")
          }
        ]
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
