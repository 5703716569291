<div class="container px-5 pt-2 pb-3 mt-5 mb-4">
    <div class="row d-none d-lg-flex">
        <div class="col-7" style="word-wrap: break-word;">
            <h3 class="fw-bold">Hai richieste particolari?</h3>
            <p class="pe-5 grigio"> Hai bisogno di un servzio ancora più personalizzato? Per i Clienti più esigenti come "hotel, b&b, affittacamere" abbiamo sviluppato il pacchetto Full Custom che prevede di seguire il cliente in modo personale e di sviluppare un prodotto specifico in linea con le sue esigenzee i servizi della sua struttura</p>
            <br>
            <h3 class="fw-bold">Copia Custom</h3>
            <ul class="grigio">
                <li><span class="fw-bolder">Affiancamento al cliente </span> | Servizio extra</li>
                <li><span class="fw-bolder">Grafica, Font e Pagine personalizzate </span> | Servizio extra</li>
                <li><span class="fw-bolder">Copie Fisiche illimitate </span> | Servizio extra</li>        
            </ul>
        </div>
        <div class="col-5 d-flex justify-content-end align-items-center">
            <img src="assets/img/introsectionprovisoria.jpg" alt="Intro section provvisoria" class="w-100" />
            <!-- <div class="container bg-secondary rounded-2 w-100 h-75 m-0"></div> -->
        </div>
    </div>
    <div class="row d-lg-none">
        <div class="col-12">
            <h3 class="fw-bold">Hai richieste particolari?</h3>
            <p class="pe-5 grigio"> Hai bisogno di un servzio ancora più personalizzato? Per i Clienti più esigenti come "hotel, b&b, affittacamere" abbiamo sviluppato il pacchetto Full Custom che prevede di seguire il cliente in modo personale e di sviluppare un prodotto specifico in linea con le esigenze e i servizi della sua struttura</p>
        </div>
        <div class="col-12 d-flex justify-content-end align-items-center my-2">
            <img src="assets/img/introsectionprovisoria.jpg" alt="Intro section provvisoria" class="w-100" />
            <!-- <div class="container bg-secondary rounded-2 w-100 h-75 m-0"></div> -->
        </div>
        <div class="col-12 mt-3" style="word-wrap: break-word;">
            <h3 class="fw-bold">Copia Custom</h3>
            <ul class="grigio">
                <li><span class="fw-bolder">Affiancamento al cliente </span> | Servizio extra</li>
                <li><span class="fw-bolder">Grafica, Font e Pagine personalizzate </span> | Servizio extra</li>
                <li><span class="fw-bolder">Copie Fisiche illimitate </span> | Servizio extra</li> 
            </ul>
        </div>
    </div>
</div>