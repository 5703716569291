import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TemplateRefService } from '../service/template-ref.service';
import { ToastService } from '../service/toast.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalizzaGuard implements CanActivate {
  constructor(private templateRefSrv: TemplateRefService, private toastService: ToastService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let mockup = localStorage.getItem("mockup")
    let pacchetto = localStorage.getItem("pacchetto")
    if (mockup && pacchetto) {
      return true
    } else {
      if (!mockup && !pacchetto) {
        this.toastService.show({template: this.templateRefSrv.getTemplateRef('sceltaMockup'), classname: 'bg-secondary text-white', delay: 10000})
      } else if (!pacchetto) {
        this.toastService.show({template: this.templateRefSrv.getTemplateRef('sceltaPacchetto'), classname: 'bg-secondary text-white', delay: 10000})
      }
      return false;
    }
  }
  
}
