<div class="modal-header border-0 pb-1 pb-md-2">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body py-1 py-md-2 border-0">
    <div class="container-md container-fluid h-100">
  <div class="row d-flex justify-content-center align-items-center h-100">
    <div class="col-12">
      <div class="card border-0" style="border-radius: 1rem;">
        <div class="card-body p-md-5 py-md-0 text-center" *ngIf="singin">

          <h3 class="mb-3 coloretestoblu fw-bold" >Sign in</h3>
          <form #loginForm="ngForm">
            <div class="form-floating mb-4 mt-3">
              <input type="email" id="typeEmailX-2" name="email" class="form-control form-control-lg" ngModel />
              <label class="form-label" for="typeEmailX-2">Email</label>
            </div>

            <div class="form-floating mb-4">
              <input type="password" id="typePasswordX-2" name="password" class="form-control form-control-lg" ngModel />
              <label class="form-label" for="typePasswordX-2">Password</label>
            </div>
          
            <button data-mdb-button-init data-mdb-ripple-init class="btn btn-lg btn-block py-1 pulsanteblu"  type="button" (click)="login(loginForm);activeModal.dismiss('Cross click')">Login</button>
          </form>
          
          <hr class="my-md-4">
          
          <!-- <button data-mdb-button-init data-mdb-ripple-init class="btn w-100 btn-primary mb-2 border-0" style="background-color: #8D99AE;"
          type="submit" (click)="loginWithGoogle()"><i class="bi bi-google me-2"></i> Sign in with google</button> -->
          <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin>
          <!-- <asl-google-signin-button type='standard' size='medium'></asl-google-signin-button> -->
          <button data-mdb-button-init data-mdb-ripple-init class="btn w-100 btn-primary mb-2 border-0" style="background-color: #8D99AE;"
          (click)="loginWithFacebook()"><i class="bi bi-facebook me-2"></i>Sign in with facebook</button>
          
          <div>
            <p class="mt-2 mb-0" style="color: #8D99AE">Don't have an account? <a (click)="singin = false;" class="text-primary-50 fw-bold" style="color: #2B2D42; text-decoration: none;">Sign Up</a>
            </p>
          </div>
          <div>
            <p class="mt-1" style="color: #8D99AE">Don't remember password? <a (click)="activeModal.dismiss('Cross click')" routerLink="/recupero-password" class="text-primary-50 fw-bold" style="color: #2B2D42; text-decoration: none;">Recover</a>
            </p>
          </div>
        </div>
        <div class="card-body p-md-5 py-md-0 text-center" *ngIf="!singin">

          <h3 class="mb-3 coloretestoblu fw-bold">Sign Up</h3>
          <form #registrazione="ngForm">
            <div class="form-floating mb-4 mt-3">
              <input type="text" id="nome" name="nome" class="form-control form-control-lg" ngModel />
              <label class="form-label" for="nome">Nome</label>
            </div>

            <div class="form-floating mb-4 mt-3">
              <input type="text" id="cognome" name="cognome" class="form-control form-control-lg" ngModel />
              <label class="form-label" for="cognome">Cognome</label>
            </div>

            <div class="form-floating mb-4 mt-3">
              <input type="email" id="email" name="email" class="form-control form-control-lg" ngModel />
              <label class="form-label" for="email">Email</label>
            </div>

            <div class="form-floating mb-4">
              <input type="password" id="password" name="password" class="form-control form-control-lg" ngModel />
              <label class="form-label" for="password">Password</label>
            </div>

            <!-- <div class="form-check d-flex justify-content-start mb-4">
              <input class="form-check-input" type="checkbox" value="" id="form1Example3" />
              <label class="form-check-label" for="form1Example3"> Remember password </label>
            </div> -->

            <button data-mdb-button-init data-mdb-ripple-init class="btn  btn-lg btn-block mb-3  py-1 pulsanteblu" type="button" (click)="register(registrazione)">Register</button>
          </form>

          <!-- <hr class="my-md-4"> -->

          <!-- <button data-mdb-button-init data-mdb-ripple-init class="btn w-100 btn-primary mb-2 border-0" style="background-color: #dd4b39;"
            type="submit"><i class="bi bi-google me-2"></i> Sign in with google</button>
          <button data-mdb-button-init data-mdb-ripple-init class="btn w-100 btn-primary mb-2 border-0" style="background-color: #3b5998;"
            type="submit"><i class="bi bi-facebook me-2"></i>Sign in with facebook</button> -->

          <div>
            <p class="mt-2 mb-0" style="color: #8D99AE">Have already an account? <a (click)="singin = true;" class="text-primary-50 fw-bold" style="color: #2B2D42; text-decoration: none;">Sign In</a>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="modal-footer  border-0">
    
</div>