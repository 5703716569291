import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})
export class PagamentoComponent implements OnInit {
  constructor(private http: HttpClient, private router:Router) {}
  async ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id")
    const response = await this.http.get<any>(environment.apiUrl+"/session-status?sessionId="+sessionId).toPromise();
    console.log(response.status)

    if (response.status == 'open') {
      document.getElementById('unsuccess')?.classList.remove('d-none');
    } else if (response.status == 'complete') {
      document.getElementById('success')?.classList.remove('d-none');
      localStorage.removeItem('mockup');
      localStorage.removeItem('pacchetto');
      localStorage.removeItem('ordineSelezionato');
      let email = document.getElementById('customer-email')
      if (email) {
        email.textContent= response.customer_email;
      }
    }
  }
}
