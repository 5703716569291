import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/service/auth.service';
import { TemplateRefService } from 'src/app/service/template-ref.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-recupero-password',
  templateUrl: './recupero-password.component.html',
  styleUrls: ['./recupero-password.component.scss']
})
export class RecuperoPasswordComponent implements OnInit {
  inviato: boolean = false;
  recupero: boolean = false;
  scaduto: boolean = false;
  private jwtHelper = new JwtHelperService();
  private tt!: string

  constructor(private authSrv: AuthService, private route:ActivatedRoute, private toastService: ToastService, private templateRefSrv: TemplateRefService, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.tt = params['tt']
      if (this.tt) {
        const dataExp = this.jwtHelper.getTokenExpirationDate(this.tt) as Date;
        const msExp = dataExp.getTime() - new Date().getTime();
        console.log(msExp)
        if (msExp <= 0) {
          this.scaduto = true;
        } else {
          this.recupero = true;
        }
      }
    })
  }
  StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  changePassword(form: NgForm) {
    let newPassword = form.value.newPassword
    let result = this.StrongPasswordRegx.test(newPassword);
    if (result) {
      this.authSrv.changePasswordRecupero(newPassword, this.tt).subscribe(() => {
        this.router.navigate(['/'])
      })
    } else {
      this.toastService.show({template: this.templateRefSrv.getTemplateRef('passwordStrong'), classname: 'bg-secondary text-white', delay: 5000})
    }
  }

  recuperaPassword(form: NgForm) {
    if (form.value.email) {
      this.authSrv.recuperoPassword(form.value.email).subscribe((data) => {
        this.inviato = true
      })
    }
  }
}
