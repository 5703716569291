import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateRefService {

  private templateRef: {[key: string] : TemplateRef<any>} = {};

  setTemplateRef(tipo: string, templateRef: TemplateRef<any>) {
    this.templateRef[tipo] = templateRef;
  }

  getTemplateRef(tipo: string): TemplateRef<any> {
    return this.templateRef[tipo];
  }
}
