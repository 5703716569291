import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Utente } from 'src/app/interface/utente.interface';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private modalService = inject(NgbModal)
    user: Utente | undefined;
    singin = true;

    constructor(private authSrv: AuthService, private router:Router) {}

    ngOnInit(): void {
      this.authSrv.user$.subscribe((data) => {
        this.user = data?.user;
      })
    }

    accedi(content?: TemplateRef<any>) {
      if (!this.user) {
        if (content) this.openLogin(content); else this.authSrv.openLoginModal();
      } else {
        this.router.navigate(['/profilo'])
      }
    }

    openLogin(content: TemplateRef<any>) {
      // this.singin = true;
      // this.modalService.open(content, { centered: true, modalDialogClass: 'dark-modal'})
    }

    // register(form:NgForm) {
    //   this.authSrv.register(form.value).subscribe((data) => {
    //     console.log('Utente con id '+data+" creato")
    //     this.singin = true
    //     alert("Hai effettuato la registrazione. Ora loggati")
    //   })
    // }

    // login(form: NgForm) {
    //   this.authSrv.login(form.value).subscribe((data) => {
    //     alert("Hai effettuato il login")
    //   })
    // }
}
