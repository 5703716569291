import { Component, OnInit } from '@angular/core';
import { Recensione } from 'src/app/interface/recensione.interface';
import { RecensioniService } from 'src/app/service/recensioni.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-recensioni',
  templateUrl: './recensioni.component.html',
  styleUrls: ['./recensioni.component.scss']
})
export class RecensioniComponent implements OnInit {
  recensioni: Recensione[] = [];
  colonna1: Recensione[] = []
  colonna2: Recensione[] = []
  colonna3: Recensione[] = []
  constructor(private recensioniSrv: RecensioniService) {}

  ngOnInit() {
    this.recensioniSrv.getAllRecensioniEasy().subscribe((recensioni) => {
      this.recensioni = recensioni;
      let newRecensioni: Recensione[] = [];
      let num = 1;
      this.colonna1 = []
      this.colonna2 = []
      this.colonna3 = []
      this.recensioni.forEach((recensione) => {
        if (recensione.statoRecensione == "APPROVATO" && recensione.posizione != null) {
          newRecensioni[recensione.posizione - 1] = recensione;
        }
      })
      newRecensioni.forEach((recensione) => {
        if (num == 1) {
          this.colonna1.push(recensione)
        } else if (num==2) {
          this.colonna2.push(recensione)
        } else {
          this.colonna3.push(recensione)
        }
        if (num == 3) {
          num = 1
        } else {
          num++;
        }
      })
    })
  }

  getMese(mese:number) {
    return environment.mese[mese-1];
  }
}
