export const environment = {
    production: false,
    apiUrl: 'https://api.illustrativocasa.it',
    stripeKey: 'pk_test_51Pg6hjRxJO1qGoOg9427om3FKiqTCYS8GabMA4PkIaG8vbyNcxAHau7WA1hEBrlsm80gJq1TBwzL2OSvDHhwljwu008fxToXyF',
    firebase: {
        apiKey: "AIzaSyBAm2vzmSl9txCU1Khu8fF1FS0xwqSFvg0",
        authDomain: "illustrativocasa.firebaseapp.com",
        projectId: "illustrativocasa",
        storageBucket: "illustrativocasa.appspot.com",
        messagingSenderId: "167103256840",
        appId: "1:167103256840:web:402ddd732ef848ee1bf4bd"
    },
    stati: {
      nonPagato: "NON_PAGATO",
      pagato: "PAGATO",
      inConsegna: "IN_CONSEGNA",
      consegnato: "CONSEGNATO"
    },
    mese: ["Gen","Feb","Mar","Apr","Mag","Giu","Lug","Ago","Set","Ott","Nov","Dic"],
    prezzi: {
      traduzione: 20.00,
      copia: 15.00,
      copiatraduzione: 35.00,
      spedizione: 19.99
    },
    pacchetti: [
        {
          name: 'Basic',
          id: 1,
          selezionato: true,
          prezzo: "€79.95",
          contenuto: "Copia PDF <br> Copia digitale QRCode",
          traduzione: "Non inclusa",
          copia_fisica: "Non inclusa",
          qrcode: "Si",
          pdf: "Si",
          descrizione: "Pacchetto Basic, riceverai una copia PDF del prodotto via mail, la medesima copia sarà associata a un QRCode e sarà visualizzabile online ogni volta che verrà scannerizzato"
        },
        {
          name: 'Premium',
          id: 2,
          selezionato: false,
          prezzo: "€139.95",
          contenuto: "Copia PDF <br> Copia digitale QRCode <br> Copia Fisica",
          traduzione: "Non inclusa",
          copia_fisica: "1",
          qrcode: "Si",
          pdf: "Si",
          descrizione: "Pacchetto Premium, in aggiunta ai benefit del pacchetto Basic riceverai una copia fisica cartacea del prodotto direttamente a casa tua"
        },
        // {
        //   name: 'Premium',
        //   id: 3,
        //   selezionato: false,
        //   prezzo: "€189.95",
        //   contenuto: "Copia PDF <br> Copia digitale QRCode <br> Copia Fisica <br> Traduzione",
        //   traduzione: "1",
        //   copia_fisica: "1",
        //   qrcode: "Si",
        //   pdf: "Si",
        //   descrizione: "Pacchetto Premium, in aggiunta ai benefit dei pacchetti Basic e Standard avrai incluso un servizio di traduzione professionale del libricino nella lingua che desideri"
        // },
        {
          name: 'Custom',
          id: 4,
          selezionato: false,
          prezzo: "Variabile",
          contenuto: "Servizio Personalizzato in base alle richieste del cliente",
          traduzione: "Su richiesta",
          copia_fisica: "Su richiesta",
          qrcode: "Su richiesta",
          pdf: "Su richiesta",
          descrizione: "Scegli un tema di partenza tra quelli proposti. Completa il form fornendo i dati della sua struttura"
        }
      ],
      mockup: [
        {
          ["intro"]: 'https://cdn.welcomebook.it/uploads/temi/professional/1.jpg',
          ["back"]: 'https://cdn.welcomebook.it/uploads/temi/professional/20.jpg',
          [1]: 'https://cdn.welcomebook.it/uploads/temi/professional/2.jpg',
          [2]: 'https://cdn.welcomebook.it/uploads/temi/professional/3.jpg',
          [3]: 'https://cdn.welcomebook.it/uploads/temi/professional/4.jpg',
          [4]: 'https://cdn.welcomebook.it/uploads/temi/professional/5.jpg',
          [5]: 'https://cdn.welcomebook.it/uploads/temi/professional/6.jpg',
          [6]: 'https://cdn.welcomebook.it/uploads/temi/professional/7.jpg',
          [7]: 'https://cdn.welcomebook.it/uploads/temi/professional/8.jpg',
          [8]: 'https://cdn.welcomebook.it/uploads/temi/professional/9.jpg',
          [9]: 'https://cdn.welcomebook.it/uploads/temi/professional/10.jpg',
          [10]: 'https://cdn.welcomebook.it/uploads/temi/professional/11.jpg',
          [11]: 'https://cdn.welcomebook.it/uploads/temi/professional/12.jpg',
          [12]: 'https://cdn.welcomebook.it/uploads/temi/professional/13.jpg',
          [13]: 'https://cdn.welcomebook.it/uploads/temi/professional/14.jpg',
          [14]: 'https://cdn.welcomebook.it/uploads/temi/professional/15.jpg',
          [15]: 'https://cdn.welcomebook.it/uploads/temi/professional/16.jpg',
          [16]: 'https://cdn.welcomebook.it/uploads/temi/professional/17.jpg',
          [17]: 'https://cdn.welcomebook.it/uploads/temi/professional/18.jpg',
          [18]: 'https://cdn.welcomebook.it/uploads/temi/professional/19.jpg',
        },
        {
          ["intro"]: 'https://cdn.welcomebook.it/uploads/temi/estivo/1.jpg',
          ["back"]: 'https://cdn.welcomebook.it/uploads/temi/estivo/20.jpg',
          [1]: 'https://cdn.welcomebook.it/uploads/temi/estivo/2.jpg',
          [2]: 'https://cdn.welcomebook.it/uploads/temi/estivo/3.jpg',
          [3]: 'https://cdn.welcomebook.it/uploads/temi/estivo/4.jpg',
          [4]: 'https://cdn.welcomebook.it/uploads/temi/estivo/5.jpg',
          [5]: 'https://cdn.welcomebook.it/uploads/temi/estivo/6.jpg',
          [6]: 'https://cdn.welcomebook.it/uploads/temi/estivo/7.jpg',
          [7]: 'https://cdn.welcomebook.it/uploads/temi/estivo/8.jpg',
          [8]: 'https://cdn.welcomebook.it/uploads/temi/estivo/9.jpg',
          [9]: 'https://cdn.welcomebook.it/uploads/temi/estivo/10.jpg',
          [10]: 'https://cdn.welcomebook.it/uploads/temi/estivo/11.jpg',
          [11]: 'https://cdn.welcomebook.it/uploads/temi/estivo/12.jpg',
          [12]: 'https://cdn.welcomebook.it/uploads/temi/estivo/13.jpg',
          [13]: 'https://cdn.welcomebook.it/uploads/temi/estivo/14.jpg',
          [14]: 'https://cdn.welcomebook.it/uploads/temi/estivo/15.jpg',
          [15]: 'https://cdn.welcomebook.it/uploads/temi/estivo/16.jpg',
          [16]: 'https://cdn.welcomebook.it/uploads/temi/estivo/17.jpg',
          [17]: 'https://cdn.welcomebook.it/uploads/temi/estivo/18.jpg',
          [18]: 'https://cdn.welcomebook.it/uploads/temi/estivo/19.jpg',
        },
        {
          ["intro"]: 'https://cdn.welcomebook.it/uploads/temi/montagna/1.jpg',
          ["back"]: 'https://cdn.welcomebook.it/uploads/temi/montagna/20.jpg',
          [1]: 'https://cdn.welcomebook.it/uploads/temi/montagna/2.jpg',
          [2]: 'https://cdn.welcomebook.it/uploads/temi/montagna/3.jpg',
          [3]: 'https://cdn.welcomebook.it/uploads/temi/montagna/4.jpg',
          [4]: 'https://cdn.welcomebook.it/uploads/temi/montagna/5.jpg',
          [5]: 'https://cdn.welcomebook.it/uploads/temi/montagna/6.jpg',
          [6]: 'https://cdn.welcomebook.it/uploads/temi/montagna/7.jpg',
          [7]: 'https://cdn.welcomebook.it/uploads/temi/montagna/8.jpg',
          [8]: 'https://cdn.welcomebook.it/uploads/temi/montagna/9.jpg',
          [9]: 'https://cdn.welcomebook.it/uploads/temi/montagna/10.jpg',
          [10]: 'https://cdn.welcomebook.it/uploads/temi/montagna/11.jpg',
          [11]: 'https://cdn.welcomebook.it/uploads/temi/montagna/12.jpg',
          [12]: 'https://cdn.welcomebook.it/uploads/temi/montagna/13.jpg',
          [13]: 'https://cdn.welcomebook.it/uploads/temi/montagna/14.jpg',
          [14]: 'https://cdn.welcomebook.it/uploads/temi/montagna/15.jpg',
          [15]: 'https://cdn.welcomebook.it/uploads/temi/montagna/16.jpg',
          [16]: 'https://cdn.welcomebook.it/uploads/temi/montagna/17.jpg',
          [17]: 'https://cdn.welcomebook.it/uploads/temi/montagna/18.jpg',
          [18]: 'https://cdn.welcomebook.it/uploads/temi/montagna/19.jpg',
        },
        {
          ["intro"]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/1.jpg',
          ["back"]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/20.jpg',
          [1]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/2.jpg',
          [2]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/3.jpg',
          [3]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/4.jpg',
          [4]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/5.jpg',
          [5]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/6.jpg',
          [6]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/7.jpg',
          [7]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/8.jpg',
          [8]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/9.jpg',
          [9]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/10.jpg',
          [10]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/11.jpg',
          [11]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/12.jpg',
          [12]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/13.jpg',
          [13]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/14.jpg',
          [14]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/15.jpg',
          [15]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/16.jpg',
          [16]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/17.jpg',
          [17]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/18.jpg',
          [18]: 'https://cdn.welcomebook.it/uploads/temi/qualcosa/19.jpg',
        }
      ]
};
