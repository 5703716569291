import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jsPDF from 'jspdf';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor(private http: HttpClient) { }

  uploadToBackEnd(formData: FormData) {
    return this.http.post('https://cdn.welcomebook.it/upload', formData, {responseType: "text"})
  }

  async generatePDF(contentDataURL: string, position: number, imgWidth: number, imgHeight: number): Promise<Blob> {
    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    return new Blob([pdf.output('blob')], {type: 'application/pdf'});
  }

  async downloadZip(pdf: {pdfData: Blob, nome: string}, png: {pngData: Blob, nome: string}, id: number) {
    const zip = new JSZip();
    zip.file(pdf.nome+".pdf",pdf.pdfData);
    zip.file(png.nome+".png",png.pngData);
    const content = await zip.generateAsync({type: 'blob'})
    saveAs(content, "welcomebook-"+id+".zip")
  }
}
