import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { TemplateRefService } from 'src/app/service/template-ref.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  singin = true;
  socialUser!: SocialUser;
  subscription!: Subscription;
  constructor(public activeModal: NgbActiveModal, private authSrv: AuthService, private toast: ToastService, private templateRef: TemplateRefService, private socialAuthService: SocialAuthService, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.subscription = this.socialAuthService.authState.subscribe((user) => {
      console.log(user)
      this.authSrv.loginWithGoogle(user).subscribe(() => {
        this.toast.show({template: this.templateRef.getTemplateRef('loginEffettuato'), classname: 'bg-blue text-white', delay: 5000})
        this.activeModal.close();
      });
    })
  }

  loginWithFacebook() {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  googleSignin(googleWrapper: any) {
    googleWrapper.click();
  }

  StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  register(form:NgForm) {
    let password = form.value.password
    let result = this.StrongPasswordRegx.test(password);
    if (result) {
      this.authSrv.register(form.value).subscribe((data) => {
        console.log('Utente con id '+data+" creato")
        this.singin = true
        // alert("Hai effettuato la registrazione. Ora loggati")
        this.toast.show({template: this.templateRef.getTemplateRef('registrazioneEffettuata'), classname: 'bg-blue text-white', delay: 5000})
      })
    } else {
      this.toast.show({template: this.templateRef.getTemplateRef('passwordStrong'), classname: 'bg-secondary text-white', delay: 5000})
    }
  }

  login(form: NgForm) {
    this.authSrv.login(form.value).subscribe((data) => {
      // alert("Hai effettuato il login")
      this.toast.show({template: this.templateRef.getTemplateRef('loginEffettuato'), classname: 'bg-blue text-white', delay: 5000})
    }, (error:string) => {
      console.log(error)
      if (error == "Devi effettuare l'accesso con il provider con il quale ti sei registrato") {
        this.toast.show({template: this.templateRef.getTemplateRef('accessoProvider'), classname: 'bg-blue text-white', delay: 5000})
      } else {
        let template = this.templateRef.getTemplateRef('custom')
        this.toast.show({template: template, classname: 'bg-blue text-white', delay: 5000, context: {customMessage: error}})
      }
    })
  }
}
