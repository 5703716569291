import { AfterViewInit, Component, ElementRef, inject, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from './service/auth.service';
import { ToastService } from './service/toast.service';
import { Toast } from './interface/toast.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'illustrativo';
  header!: HTMLElement;
  toastService = inject(ToastService)
  toasts: Toast[] = []

  showComponent = true;

  constructor(private renderer: Renderer2, private router: Router, private authSrv: AuthService) {
    this.authSrv.restore();
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.showComponent = false;
      setTimeout(() => {
        this.showComponent = true
        if (event.urlAfterRedirects.includes("guida")) {
          this.showComponent = false;
        }
      }, 0)
    });
    this.toastService.$toasts.subscribe((toasts) => {
      this.toasts = toasts;
    })
  }
  ngAfterViewInit(): void {
      this.header = document.getElementsByTagName('header')[0]
    this.renderer.listen('window', 'scroll', () => {
      this.onWindowScroll();
    })
  }

  onWindowScroll() {
    this.header = document.getElementsByTagName('header')[0]
    const cambioColore = document.getElementById('cambioColore')
    const logoHeader = document.getElementById('logoHeader')
    const accediHeader1 = document.getElementById('accediHeader1')
    const accediHeader2 = document.getElementById('accediHeader2')
    const linksHeader = document.querySelectorAll('.nav-link')
    let metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (!metaThemeColor) {
      metaThemeColor = document.createElement('meta');
      metaThemeColor.setAttribute("name", "theme-color");
      metaThemeColor.setAttribute("content", "#ffffff");
      document.getElementsByTagName("head")[0].appendChild(metaThemeColor);
    }
    if (!this.header || !cambioColore || !logoHeader || !accediHeader1 || !accediHeader2 || linksHeader.length == 0) {
      return
    }
    
    const headerHeight = this.header.offsetHeight;
    const contentTop = cambioColore.getBoundingClientRect().top;
    
    if (contentTop <= headerHeight) {
      this.renderer.removeClass(this.header, 'bg-light');
      this.renderer.addClass(this.header, 'sfondo-header');
      logoHeader.setAttribute('src', "assets/img/logonavb.png")
      this.renderer.addClass(accediHeader1, 'accedi-white');
      this.renderer.addClass(accediHeader2, 'accedi-white');
      linksHeader.forEach((link) => {
        this.renderer.addClass(link, 'bottone-white')
      })
      metaThemeColor.setAttribute("content", "#8d99ae");
    } else {
      this.renderer.removeClass(this.header, 'sfondo-header')
      metaThemeColor.setAttribute("content", "#ffffff");
      this.renderer.addClass(this.header, 'bg-light');
      logoHeader.setAttribute('src', "assets/img/logonavw.png")
      this.renderer.removeClass(accediHeader1, 'accedi-white');
      this.renderer.removeClass(accediHeader2, 'accedi-white');
      linksHeader.forEach((link) => {
        this.renderer.removeClass(link, 'bottone-white')
      })
    }
  }
}
