<div id="success" class="d-none text-center">
    <div class="confermato">
        <div class="confermato-200 mb-2">
            <h1 class="display-1">Confermato</h1>
        </div>
        <h2>Il tuo ordine è stato pagato.</h2>
        <p class="mx-3">Una mail di conferma sarà inviata a <span id="customer-email"></span></p>
        <a class="bottone" routerLink="/">Vai alla Home</a>
    </div>
</div>
<div id="unsuccess" class="d-none text-center">
    <div class="confermato">
        <div class="confermato-200 mb-2">
            <h1 class="display-1">Errore</h1>
        </div>
        <h2>Qualcosa è andato storto.</h2>
        <p class="mx-3">Qualcosa è andato storto durante la transazione, riprova più tardi.</p>
        <a class="bottone" routerLink="/acquisto/buy">Riprova</a>
    </div>
</div>