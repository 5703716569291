<ng-template #stripeModale let-modal style="z-index: 12000000!important;">
    <div class="modal-header" style="border: 0!important; outline: 0!important;">
		<!-- <h4 class="modal-title" id="modal-basic-title">Modifica Ordine</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modificaOrdine.dismiss('Cross click')"></button> -->
	</div>
    <div class="modal-body border-0 p-0 m-0 my-3" style="background-color: transparent!important;">
      <div id="checkout"></div>
    </div>
    <div class="modal-footer" style="border: 0!important; outline: 0!important;">
		<!-- <button type="button" class="btn btn-outline-secondary" (click)="modificaOrdine.close('Close click')">Chiudi</button> -->
	</div>
</ng-template>

<div class="container-fluid container-md px-3 px-md-5 pt-3 pb-3">
    <h3 class="pb-2 fw-bold mt-4">Acquista</h3>
    <div class="row px-md-4 mb-md-5">
        <div class="col-lg-8 col-12 pe-lg-6">
            <h4 class="pb-3 fw-bold mt-4">Indirizzo di Fatturazione</h4>
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <div class="form-floating">
                        <input type="text" class="form-control" name="nomeCognome" id="nomeCognome" [(ngModel)]="model.nomeCompleto"/>
                        <label class="form-label z-0" for="nomeCognome">Nome Completo</label>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <div class="form-floating">
                        <input type="text" class="form-control" name="indirizzo" id="indirizzo"[(ngModel)]="model.indirizzo"/>
                        <label class="form-label z-0" for="indirizzo">Indirizzo</label>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <div class="form-floating">
                        <input type="email" class="form-control" name="email" id="email"[(ngModel)]="model.emailFatturazione"/>
                        <label class="form-label z-0" for="email">Email</label>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <div class="form-floating">
                        <select class="form-select" [(ngModel)]="model.paese" name="paese" id="paese">
                            <option selected>Seleziona Paese</option>
                            <option value="italia">Italia</option>
                            <option value="usa">USA</option>
                        </select>
                        <label class="form-label z-0" for="indirizzo">Paese</label>
                    </div>
                </div>
            </div>
            <div class="d-flex w-100 justify-content-between align-items-center">
                <h4 class="pb-3 fw-bold mt-2">Vuoi la Fattura?</h4>
                <div class="pb-2 mt-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" (change)="model.fattura = true" type="radio" name="fattura" id="fattura1">
                        <label class="form-check-label" for="fattura1">
                          Si
                        </label>
                    </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" (change)="model.fattura = false" type="radio" name="fattura" id="fattura2" checked>
                        <label class="form-check-label" for="fattura2">
                          No
                        </label>
                      </div>
                </div>
            </div>
            <div class="row" *ngIf="model.fattura">
                <div class="col-12 col-md-6 mb-3">
                    <div class="form-floating">
                        <input type="text" class="form-control" name="ragioneSociale" id="ragioneSociale" [(ngModel)]="model.ragioneSociale"/>
                        <label class="form-label z-0" for="ragioneSociale">Ragione Sociale</label>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <div class="form-floating">
                        <input type="text" class="form-control" name="codiceFiscale" id="codiceFiscale" [(ngModel)]="model.codiceFiscale"/>
                        <label class="form-label z-0" for="codiceFiscale">Codice Fiscale</label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="pacchetto?.nome != 'Basic'">
                <h4 class="pb-3 fw-bold mt-2">Indirizzo di Consegna</h4>
                <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <input type="text" class="form-control" name="nome" id="nome" [(ngModel)]="model.nome"/>
                            <label class="form-label z-0" for="nome">Nome</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <input type="text" class="form-control" name="cognome" id="cognome"[(ngModel)]="model.cognome"/>
                            <label class="form-label z-0" for="cognome">Cognome</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <input type="text" class="form-control" name="indirizzo2" id="indirizzo2" [(ngModel)]="model.indirizzoConsegna"/>
                            <label class="form-label z-0" for="indirizzo2">Indirizzo</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <input type="text" class="form-control" name="info" id="info" [(ngModel)]="model.info"/>
                            <label class="form-label z-0" for="info">Informazioni aggiuntive</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <input type="text" class="form-control" name="citta" id="citta" [(ngModel)]="model.citta"/>
                            <label class="form-label z-0" for="citta">Città</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <input type="text" class="form-control" name="cap" id="cap" [(ngModel)]="model.cap"/>
                            <label class="form-label z-0" for="cap">CAP</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <select class="form-select" name="provincia" id="provincia" [(ngModel)]="model.provincia">
                                <option selected>Seleziona Provincia</option>
                                <option value="sassari">Sassari</option>
                                <option value="cagliari">Cagliari</option>
                            </select>
                            <label class="form-label z-0" for="provincia">Provincia</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <select class="form-select" name="nazione" id="nazione" [(ngModel)]="model.nazioneConsegna">
                                <option selected>Seleziona Nazione</option>
                                <option value="italia">Italia</option>
                                <option value="usa">USA</option>
                            </select>
                            <label class="form-label z-0" for="nazione">Nazione</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="form-floating">
                            <input type="tel" class="form-control" name="cellulare" id="cellulare" [(ngModel)]="model.cellulare"/>
                            <label class="form-label z-0" for="cellulare">Cellulare</label>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- <h4 class="pb-3 fw-bold mt-2">Metodi di pagamento</h4> -->
            <!-- <div class="row">
                <div class="col-12 rounded-2 mb-2" (click)="model.pagamento = 'stripe'">
                    <div class="row border border-1 p-3 d-flex align-items-center g-0" [ngClass]="{selected_pagamento: model.pagamento == 'stripe'}">
                        <div class="col-2">
                            <img src="https://www.instant-gaming.com/themes/igv2/images/icons/payments/icon-mcvisa.svg" class="p-3 w-100" alt="Carta">
                        </div>
                        <div class="col-10 d-flex h-100 align-items-center">
                            <p class="m-0 p-0 blu">Carta</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 rounded-2 mb-2" (click)="model.pagamento ='paypal'">
                    <div class="row border border-1 p-3 d-flex align-items-center g-0" [ngClass]="{selected_pagamento: model.pagamento == 'paypal'}">
                        <div class="col-2">
                            <img src="https://www.instant-gaming.com/themes/igv2/images/icons/payments/icon-paypal.svg" class="p-3 w-100" alt="Paypal">
                        </div>
                        <div class="col-10 d-flex h-100 align-items-center">
                            <p class="m-0 p-0 blu">Paypal</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 rounded-2 mb-2" (click)="model.pagamento = 'paysafecard'">
                    <div class="row border border-1 p-3 d-flex align-items-center g-0"  [ngClass]="{selected_pagamento: model.pagamento == 'paysafecard'}">
                        <div class="col-2">
                            <img src="https://www.instant-gaming.com/themes/igv2/images/icons/payments/icon-paysafecard.svg" class="p-3 w-100" alt="PaysafeCard">
                        </div>
                        <div class="col-10 d-flex h-100 align-items-center">
                            <p class="m-0 p-0 blu">PaysafeCard</p>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <ngx-paypal [config]="payPalConfig" style="z-index: 0!important;"></ngx-paypal> -->
        </div>
        <div class="col-12 col-lg-4">
            <h4 class="pb-3 fw-bold mt-4">Riepilogo</h4>
            <div class="w-100 p-4 py-4 border-1 border rounded-4 mb-2">
                <div class="d-flex justify-content-between align-items-center">
                    <h6 class="pb-2 fw-bolder m-0 p-0">Pacchetto {{pacchetto?.nome}}</h6>
                    <p class="m-0 p-0 grigino">{{pacchetto?.prezzo}}</p>
                </div>
                <hr class="pt-0 mt-1">
                <div class="row">
                    <div class="col-5 d-flex justify-content-center">
                        <img
                        [src]="mockup['intro']"
                        alt="Test"
                        class="img-fluid w-75 altezza-personalizzata border border-1"
                        style="height: auto!important;"
                      />
                    </div>
                    <div class="col-7">
                        <p class="m-0 p-0 grigino" [innerHTML]="pacchetto?.contenuto"></p>
                    </div>
                </div>
            </div>
            <div class="w-100 p-4 px-5 rounded-4 coloreblu text-white my-4">
                <div class="d-flex justify-content-between">
                    <p class="mb-1">IVA</p>
                    <p class="mb-1">{{calcoloIva()}} €</p>
                </div>
                <div class="d-flex justify-content-between" *ngIf="ordine.pacchetto != 1">
                    <p class="mb-1">Spedizione</p>
                    <p class="mb-md-1">{{prezzi.spedizione}} €</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Totale</p>
                    <p>{{calcoloTotale(true)}} €</p>
                </div>
                <div class="d-flex justify-content-center my-2">
                    <button type="button" class="btn colorerosso text-white px-5  rounded-3 fs-6 fs-md-5 fw-semibold" (click)="buy()">Acquista</button>
                </div>
                <hr>
                <div class="mx-5 text-center fs-7 ">
                    <p class="mb-0"> ho preso visione dei termini e condizioni del servizio</p>
                </div>
            </div>
        </div>
    </div>
    
</div>

<!-- <div id="card-element"></div> -->
<!-- <div id="checkout"></div> -->