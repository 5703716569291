import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customStatus'
})
export class CustomStatusPipe implements PipeTransform {

  transform(value: string): string {
    let conSpazi = value.replace("_", " ");
    return conSpazi.charAt(0)+conSpazi.slice(1).toLowerCase();
  }

}
