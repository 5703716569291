<div class="modal-header">
    <h3 class="modal-title fw-bold">Ordine IT-{{ordine.id}}</h3>
    <button type="button" class="btn-close" style="color: #8D99AE" aria-label="Close" (click)="modal.dismiss('Cross click');"></button>
</div>
<div class="modal-body" style="background-color: #EDF2F4;">
    <div class="container p-2 p-lg-4">
        <div class="row">
            <div class="d-none d-lg-block col-4">
                <div class="text-center image-container">
                    <img [src]="temaById(ordine.mockup)?.intro" alt="Immagine tema" class="bordoImg w-80"/>
                </div>
                <div class="ms-4">
                    <h6 class="mt-4 mb-2 fw-bolder fs-7 fs-lg-6">Data: <span class="coloreGrigio fw-normal">{{ordine.data}}</span></h6>
                    <h6 class="mt-2 mb-2 fw-bolder">Stato: {{ordine.statoOrdine}}</h6>
                    <button class="nav-link link-underline" *ngIf="ordine.statoOrdine != 'NON_PAGATO'" (click)="openInANewWindow('/guida/'+ordine.id)">Scarica QRCode</button>
                    <button class="nav-link link-underline" *ngIf="ordine.statoOrdine != 'NON_PAGATO' && ordine.numeroOrdine != null" (click)="openInANewWindow('/guida/'+nomeStruttura+'/'+ordine.numeroOrdine)">Visualizza QRCode</button>
                    <button class="nav-link link-underline" *ngIf="ordine.statoOrdine != 'NON_PAGATO'">Scarica Formato PDF</button>
                </div>
            </div>
            <div class="col-12 col-lg-8">
                <div class="row">
                    <div class="col-6">
                        <h6 class="mb-1 fw-bolder fs-7 fs-lg-6">Riepilogo Ordine</h6>
                        <div class="coloreGrigio fs-7 fs-lg-6">Tema {{ordine.mockup}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6">Pacchetto {{getNomePacchetto(ordine.pacchetto)}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6" *ngFor="let item of personalizzazione.lingue | keyvalue">
                            <span *ngIf="item.value">Traduzione {{item.key}}</span>
                        </div>
                        <div class="coloreGrigio fs-7 fs-lg-6" *ngFor="let item of personalizzazione.copie">
                            x {{item.copie}} Copia Fisica ({{item.lingua}})
                        </div>
                        <h6 class="my-2 fw-bolder fs-7 fs-lg-6">Dati Caricati</h6>
                        <a class="nav-link coloreGrigio fs-7 fs-lg-6" (click)="modal.close('Close click');" [routerLink]="['/ordine', ordine.id]">Clicca qui</a>
                    </div>
                    <div class="col-6">
                        <!-- <h6 class="mb-1 fw-bolder fs-7 fs-lg-6">Metodo di Pagamento</h6>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.pagamento || "PayPal / Carta"}}</div> -->
                        <h6 class="mb-2 fw-bolder fs-7 fs-lg-6">Riepilogo Spese</h6>
                        <div class="d-flex justify-content-between">
                            <div class="coloreGrigio fs-7 fs-lg-6">Pacchetto Basic</div>
                            <div class="coloreGrigio fs-7 fs-lg-6">€ 89.95</div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="coloreGrigio fs-7 fs-lg-6">Traduzione Extra</div>
                            <div class="coloreGrigio fs-7 fs-lg-6">€ {{calcoloTraduzioni()}}</div>
                        </div>
                        <div class="d-flex justify-content-between" *ngIf="personalizzazione.copie.length > 0">
                            <div class="coloreGrigio fs-7 fs-lg-6">Copie Extra</div>
                            <div class="coloreGrigio fs-7 fs-lg-6">€ {{calcoloCopie()}}</div>
                        </div>
                        <div class="d-flex justify-content-between" *ngIf="personalizzazione.copie.length > 0">
                            <div class="coloreGrigio fs-7 fs-lg-6">Spedizione</div>
                            <div class="coloreGrigio fs-7 fs-lg-6">€ 19.99</div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="coloreGrigio fs-7 fs-lg-6">Iva</div>
                            <div class="coloreGrigio fs-7 fs-lg-6">€ {{calcoloIva()}}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="coloreGrigio fs-7 fs-lg-6">Totale</div>
                            <div class="coloreGrigio fs-7 fs-lg-6">€ {{calcoloTotale(true)}}</div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row" *ngIf="ordine.statoOrdine != 'NON_PAGATO'">
                    <div class="col-6">
                        <h6 class="my-1 fw-bolder fs-7 fs-lg-6">Indirizzo di Fatturazione</h6>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.nomeCompleto}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.emailFatturazione}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.indirizzo}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.paese}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6" *ngIf="ordine.fattura">{{ordine.ragioneSociale}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6" *ngIf="ordine.fattura">{{ordine.codiceFiscale}}</div>
                    </div>
                    <div class="col-6" *ngIf="ordine.pacchetto != 1">
                        <h6 class="my-1 fw-bolder fs-7 fs-lg-6">Indirizzo di Spedizione</h6>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.nome}} {{ordine.cognome}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.indirizzoConsegna}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.info}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.citta}} ({{ordine.provincia}}) - {{ordine.cap}} {{ordine.nazioneConsegna}}</div>
                        <div class="coloreGrigio fs-7 fs-lg-6">{{ordine.cellulare}}</div>
                    </div>
                </div>
                <div class="d-lg-none">
                    <hr>
                    <div class="row">
                        <div class="col-6">
                            <h6 class="mt-2 mb-2 fw-bolder fs-7 fs-lg-6">Stato: {{ordine.statoOrdine}}</h6>
                            <a class="nav-link link-underline" *ngIf="ordine.statoOrdine != 'NON_PAGATO' && ordine.numeroOrdine != null">Scarica QRCode</a>
                            <a class="nav-link link-underline" *ngIf="ordine.statoOrdine != 'NON_PAGATO' && ordine.numeroOrdine != null" [routerLink]="['/guida', nomeStruttura, ordine.numeroOrdine]">Visualizza QRCode</a>
                            <a class="nav-link link-underline" *ngIf="ordine.statoOrdine != 'NON_PAGATO' && ordine.numeroOrdine != null">Scarica Formato PDF</a>
                        </div>
                        <div class="col-6">
                            <h6 class="mt-2 fw-bolder fs-7 fs-lg-6">Data: <span class="coloreGrigio fw-normal">{{ordine.data}}</span></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer d-flex justify-content-center">
    <a class="btn rounded-3 colorerosso fs-6 fs-md-5 my-3" (click)="assistenza();modal.close('Close click');" *ngIf="user?.ruoloUtente != 'ADMIN'" role="button"
        >Assistenza</a
    >
    <a class="btn rounded-3 colorerosso fs-6 fs-md-5 my-3" (click)="paga();modal.close('Close click');" *ngIf="ordine.statoOrdine == 'NON_PAGATO'" role="button"
        >Continua</a
    >
</div>