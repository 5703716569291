import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ordine } from 'src/app/interface/ordine.interface';
import { AuthService } from 'src/app/service/auth.service';
import { OrdineService } from 'src/app/service/ordine.service';
import jsPDF, { jsPDFOptions } from 'jspdf';
import html2canvas from 'html2canvas';
import { FileUploadService } from 'src/app/service/file-upload.service';

@Component({
  selector: 'app-pdf-qrcode',
  templateUrl: './pdf-qrcode.component.html',
  styleUrls: ['./pdf-qrcode.component.scss']
})
export class PdfQrcodeComponent implements OnInit {
  @ViewChild('dataToExport', { static: false }) public dataToExport!: ElementRef;
  ordine!: Ordine;
  personalizzazione: {[key:string]: any} = {}
  caricato = false;
  urlQrcode!: string;

  immagine!: string;

  constructor(private ordineSrv: OrdineService, private activatedRoute: ActivatedRoute, private authSrv: AuthService, private downloadSrv: FileUploadService) {
  }

  ngOnInit(): void {
    this.authSrv.user$.subscribe((data) => {
      if (data && data.user && data.user.id) {
        this.activatedRoute.params.subscribe(params => {
          let id = params["id"];
          if (data.user.id) {
            this.ordineSrv.findOrdineByIdAndUtente2(Number(id), data.user.id).subscribe((data: Ordine) => {
              if (data) {
                this.ordine = data;
                if (typeof this.ordine.personalizzazione == "string") this.personalizzazione = JSON.parse(this.ordine.personalizzazione);
                this.personalizzazione['lingue'] = JSON.parse(this.personalizzazione['lingue'])
                this.personalizzazione['copie'] = JSON.parse(this.personalizzazione['copie'])
                let nomeStruttura = this.personalizzazione['nomeStruttura'].replaceAll(" ", "-")
                nomeStruttura = nomeStruttura.replaceAll("_", "-")
                nomeStruttura = nomeStruttura.toLowerCase();
                this.urlQrcode = 'http://185.229.237.118:4200/guida/'+nomeStruttura+"/"+this.ordine.numeroOrdine;
              }
              this.caricato = true
              setTimeout(() => {
                html2canvas(this.dataToExport.nativeElement).then(canvas => {
                  const contentDataURL = canvas.toDataURL("image/png")
                  this.immagine = contentDataURL;
                })
              }, 1000)
            })
          }
        })
      }
    })
  }

  downloadPdf() {
    html2canvas(this.dataToExport.nativeElement).then(async canvas => {
      const contentDataURL = canvas.toDataURL("image/png")
      // const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      let pdf = await this.downloadSrv.generatePDF(contentDataURL, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      let nomeStruttura = this.personalizzazione['nomeStruttura'].replaceAll(" ", "-")
      nomeStruttura = nomeStruttura.replaceAll("_", "-")
      nomeStruttura = nomeStruttura.toLowerCase();
      canvas.toBlob((blob) => {
        let png = blob;
        if (png) this.downloadSrv.downloadZip({pdfData: pdf, nome: nomeStruttura}, {pngData: png, nome:nomeStruttura}, this.ordine.id)
      }, 'image/png')
      // pdf.save(nomeStruttura+'.pdf');
    })
  }
}
