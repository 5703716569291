import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { AuthData } from '../interface/auth-data.interface';
import { HttpClient } from '@angular/common/http';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../component/login/login.component';
import { SocialUser } from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private jwtHelper = new JwtHelperService();
  redirectUrl: string | null = null;
  redirectQueryParams: { [key: string]: any} | null = null;

  private authSub = new BehaviorSubject<AuthData | null>(null);
  user$ = this.authSub.asObservable();
  private timeOut!: any;

  constructor(private http: HttpClient, private router: Router, private modalService: NgbModal) { }

  openLoginModal() {
    this.modalService.open(LoginComponent, { centered: true, modalDialogClass: 'dark-modal'})
  }

  register(data: {
    nome: string,
    cognome: string,
    email: string,
    password: string
  }) {
    return this.http.post<number>(`${this.apiUrl}/auth/register`, data)
  }

  login(data: {
    email: string,
    password: string
  }) {
    return this.http.post<AuthData>(`${this.apiUrl}/auth/login`, data).pipe(
      tap((data2) => {
        this.authSub.next(data2);
        localStorage.setItem('user', JSON.stringify(data2))
        if (this.redirectUrl) {
          const navigationExtras: NavigationExtras = {
            queryParams: this.redirectQueryParams || {},
          };
          this.router.navigate([this.redirectUrl], navigationExtras);
          this.redirectUrl = null;
          this.redirectQueryParams = null;
        }
        // this.router.navigate(["/"])
      }),
      catchError(this.errors)
    )
  }

  changePassword(password: string) {
    let data = {
      email: this.authSub.value?.user.email,
      password: password
    }
    return this.http.patch(`${this.apiUrl}/auth/changePassword`, data)
  }

  changePasswordRecupero(password: string, token: string) {
    let data = {
      token: token,
      password: password
    }
    return this.http.post(`${this.apiUrl}/auth/changePasswordRecupero`, data)
  }

  recuperoPassword(email: string) {
    let data = {
      email: email
    }
    return this.http.post(`${this.apiUrl}/auth/recuperoPassword`, data)
  }

  logout() {
    this.authSub.next(null);
    localStorage.removeItem('user');
    this.router.navigate(["/"])
  }

  async restore() {
    const userJson = localStorage.getItem('user')
    if (!userJson) {
      return
    }

    const user: AuthData = JSON.parse(userJson);
    this.authSub.next(user);
    this.autoLogout(user);
  }

  private autoLogout(data:AuthData) {
    const dataExp = this.jwtHelper.getTokenExpirationDate(data.accessToken) as Date;
    const msExp = dataExp.getTime() - new Date().getTime();
    this.timeOut = setTimeout(() => {
      this.logout();
    }, msExp)
  }

  private errors(err: any) {
    return throwError(err.error.message)
  }

  loginWithGoogle(user:SocialUser) {
    return this.http.post<AuthData>(`${this.apiUrl}/auth/login/google`, user).pipe(
      tap((data2) => {
        this.authSub.next(data2);
        localStorage.setItem('user', JSON.stringify(data2))
        // this.router.navigate(["/"])
        if (this.redirectUrl) {
          const navigationExtras: NavigationExtras = {
            queryParams: this.redirectQueryParams || {},
          };
          this.router.navigate([this.redirectUrl], navigationExtras);
          this.redirectUrl = null;
          this.redirectQueryParams = null;
        }
      }),
      catchError(this.errors)
    )
  }
}
