import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { injectStripe, StripeService } from 'ngx-stripe';
import { switchMap } from 'rxjs';
// import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { Ordine } from 'src/app/interface/ordine.interface';
import { Prodotto } from 'src/app/interface/prodotto.interface';
import { Utente } from 'src/app/interface/utente.interface';
import { AuthService } from 'src/app/service/auth.service';
import { EnvironmentService } from 'src/app/service/environment.service';
import { OrdineService } from 'src/app/service/ordine.service';
import { TemplateRefService } from 'src/app/service/template-ref.service';
import { ToastService } from 'src/app/service/toast.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-acquista',
  templateUrl: './acquista.component.html',
  styleUrls: ['./acquista.component.scss'],
})
export class AcquistaComponent implements OnInit {
  user: Utente | undefined;
  pacchetti!: Prodotto[] | undefined;
  ordine!: Ordine;
  prezzi!: {
    traduzione: number,
    copia: number,
    spedizione: number
  }
  model: {
    nomeCompleto?: string;
    indirizzo?: string;
    emailFatturazione?: string;
    paese?: string;
    fattura?: boolean;
    ragioneSociale?: string;
    codiceFiscale?: string;
    nome?: string;
    cognome?: string;
    indirizzoConsegna?: string;
    info?: string;
    citta?: string;
    cap?: string;
    provincia?: string;
    nazioneConsegna?: string;
    cellulare?: string;
    pagamento?: string;
    totale: number;
  } = {
    fattura: false,
    totale: 0.0,
  };

  stripe = injectStripe("pk_test_51Pg6hjRxJO1qGoOg9427om3FKiqTCYS8GabMA4PkIaG8vbyNcxAHau7WA1hEBrlsm80gJq1TBwzL2OSvDHhwljwu008fxToXyF")
  @ViewChild('stripeModale') stripeModale!: TemplateRef<any>;

  mockup: any = null;

  pacchetto: Prodotto | undefined;

  // stripe: Stripe | null = null;
  // elements: StripeElements | null = null;
  // card: StripeCardElement | null = null;
  // payPalConfig?: IPayPalConfig;
  private modalService = inject(NgbModal)

  constructor(
    private authSrv: AuthService,
    private ordineSrv: OrdineService,
    private router: Router,
    private http: HttpClient,
    private toast: ToastService,
    private templateRef: TemplateRefService,
    private route: ActivatedRoute,
    private environmentSrv: EnvironmentService,
    private stripeService: StripeService
  ) {}

  buy() {
    // this.ordineSrv
    //   .patchOrdine(
    //     Number(localStorage.getItem('ordineSelezionato')),
    //     this.model
    //   )
    //   .subscribe((data) => {
    //     // window.alert('Ordine effettuato e pagato!');
    //     this.toast.show({template: this.templateRef.getTemplateRef('ordineEffEPagato'), classname: 'bg-secondary text-white', delay: 5000})
    //     localStorage.removeItem('mockup');
    //     localStorage.removeItem('pacchetto');
    //     localStorage.removeItem('ordineSelezionato');
    //     this.router.navigate(['/profilo']);
    //   });
    const modalRef = this.modalService.open(this.stripeModale, {centered: true, size: 'xl', backdrop: 'static'})

    setTimeout(() => {
      this.http.post<string>(`${environment.apiUrl}/stripe-checkout`, {totale: this.model.totale, ordine: localStorage.getItem('ordineSelezionato'), model: this.model}, {
        responseType: "text" as "json"
      }).subscribe(async result => {
        this.stripe.initEmbeddedCheckout({clientSecret: result}).subscribe((checkout) => {
          checkout.mount('#checkout')
        })
      })
    }, 500)
  }

  calcoloTraduzioni() {
    let traduzioni = 0;
    if (typeof this.ordine.personalizzazione=="object") {
      for (const [key, value] of Object.entries(this.ordine.personalizzazione['lingue'])) {
        if (value && key != this.ordine.personalizzazione["linguaDefault"]) {
          traduzioni += this.prezzi.traduzione;
        }
      }
    }
    return traduzioni;
  }

  calcoloCopie() {
    let copie = 0;
    if (typeof this.ordine.personalizzazione == "object") {
      this.ordine.personalizzazione['copie'].forEach((copia: {
        lingua: string,
        copie: string
      }) => {
        let copieNum = Number(copia.copie)
        copie += (copieNum * this.prezzi.copia)
      })
    }
    return copie;
  }

  calcoloTotale(iva?: boolean) {
    let spedizione = Number(this.ordine.pacchetto) != 1 ? this.prezzi.spedizione : 0;
    if (!this.pacchetti) return 0;
    let pacchetto = this.pacchetti.find((prodotto) => prodotto.id == this.ordine.pacchetto)
    let totale = parseFloat(pacchetto ? pacchetto.prezzo.replace('€', '').trim() : "0.00") + this.calcoloTraduzioni() + this.calcoloCopie() + spedizione;
    if (iva) {
      totale += Number(this.calcolaPercentuale(this.calcoloTotale(), 22))
    }
    return Number(totale.toFixed(2))
  }

  calcolaPercentuale(prezzo: number, percentuale: number): string {
    return (prezzo * (percentuale / 100)).toFixed(2);
  }

  calcoloIva() {
    
    return this.calcolaPercentuale(this.calcoloTotale(), 22)
  }

  async ngOnInit() {
    this.pacchetti = await this.environmentSrv.getAllPacchetti().toPromise()
    if (!this.pacchetti) {
      return
    }
    let prezzi = await this.environmentSrv.getAllPrezzi().toPromise();
    if (!prezzi) return;
    this.prezzi = prezzi;
    if (localStorage.getItem('pacchetto')) {
      let pacchetto = this.pacchetti.find((prodotto) => prodotto.id == Number(localStorage.getItem('pacchetto')))
      if (pacchetto) {
        this.pacchetto = pacchetto;
      }
    }
    this.route.queryParams.subscribe(params => {
      let ordine = params['ordine']
      if (!ordine) {
        ordine = localStorage.getItem('ordineSelezionato');
        if (!ordine) {
          return;
        }
      }
      this.authSrv.user$.subscribe((user) => {
        this.user = user?.user;
        if (this.user != undefined && this.user.id != undefined) {
          this.ordineSrv
            .findOrdineByIdAndUtente2(Number(ordine), this.user.id)
            .subscribe((data) => {
              if (!this.pacchetti) return;
              this.ordine = data;
              if (typeof this.ordine.personalizzazione == "string") this.ordine.personalizzazione = JSON.parse(this.ordine.personalizzazione);
              if (typeof this.ordine.personalizzazione == "object") {
                this.ordine.personalizzazione['copie'] = JSON.parse(this.ordine.personalizzazione['copie'])
                this.ordine.personalizzazione['lingue'] = JSON.parse(this.ordine.personalizzazione['lingue'])
              }
              this.model.totale = this.calcoloTotale(true)
              // this.initPaypal();
              if (!localStorage.getItem('pacchetto')) {
                localStorage.setItem('pacchetto', this.ordine.pacchetto.toString())
                let pacchetto = this.pacchetti.find((prodotto) => prodotto.id == Number(localStorage.getItem('pacchetto')))
                if (pacchetto) {
                  this.pacchetto = pacchetto;
                }
              }
              if (!localStorage.getItem('mockup')) {
                localStorage.setItem('mockup', this.ordine.mockup.toString())
              }
              this.environmentSrv.getAllMockup().subscribe((mockups) => {
                mockups = mockups.sort((a, b) => a.id - b.id)
                let mockup = mockups.find((mockup) => mockup.id == Number(localStorage.getItem('mockup')))
                if (mockup) {
                  if (typeof mockup.pagine == 'string') {
                    this.mockup = JSON.parse(mockup.pagine);
                  } else {
                    this.mockup = mockup.pagine;
                  }
                }
              })
            }, err => {
              this.router.navigate(['/'])
            });
        }
      });
    })
  }

  // private initPaypal() {
  //   this.payPalConfig = {
  //     currency: 'EUR',
  //     clientId: 'AckJNSGJWxfkt94FbStkDEXzk8eZTV0DsGcZlGL8NfzJQh6cn1wvsTKgeCY96txcRECAquLqLVzh7rVv',
  //     createOrderOnClient: (data) =>
  //       <ICreateOrderRequest>{
  //         intent: 'CAPTURE',
  //         purchase_units: [
  //           {
  //             amount: {
  //               currency_code: 'EUR',
  //               value: this.model.totale.toString(),
  //               breakdown: {
  //                 item_total: {
  //                   currency_code: 'EUR',
  //                   value: this.model.totale.toString(),
  //                 },
  //               },
  //             },
  //             items: [
  //               {
  //                 name: 'Pacchetto ' + (this.pacchetto ? this.pacchetto.nome : ''),
  //                 quantity: '1',
  //                 category: 'DIGITAL_GOODS',
  //                 unit_amount: {
  //                   currency_code: 'EUR',
  //                   value: this.model.totale.toString(),
  //                 },
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     advanced: {
  //       commit: 'true'
  //     },
  //     style: {
  //       label: 'pay',
  //       shape: 'rect',
  //       color: 'white',
  //       layout: 'vertical',
  //       tagline: false
  //     },
  //     onApprove: (data, actions) => {
  //       console.log('onApprove - transaction was approved, but not authorized', data, actions);
  //       actions.order.get().then((details: any) => {
  //         console.log('onApprove - you can get full order details inside onApprove: ', details);
  //       });
  //     },
  //     onClientAuthorization: (data) => {
  //       // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
  //       this.ordineSrv
  //         .patchOrdine(
  //           Number(localStorage.getItem('ordineSelezionato')),
  //           this.model
  //         )
  //         .subscribe((data) => {
  //           // window.alert('Ordine effettuato e pagato!');
  //           this.toast.show({template: this.templateRef.getTemplateRef('ordineEffEPagato'), classname: 'bg-secondary text-white', delay: 5000})
  //           localStorage.removeItem('mockup');
  //           localStorage.removeItem('pacchetto');
  //           localStorage.removeItem('ordineSelezionato');
  //           this.router.navigate(['/profilo']);
  //         });
  //       // this.showSuccess = true;
  //     },
  //     onCancel: (data, actions) => {
  //         console.log('OnCancel', data, actions);
  //         // this.showCancel = true;

  //     },
  //     onError: err => {
  //         console.log('OnError', err);
  //         // this.showError = true;
  //     },
  //     onClick: (data, actions) => {
  //         console.log('onClick', data, actions);
  //     }
  //   };
  // }
}
