import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastService } from '../service/toast.service';
import { TemplateRefService } from '../service/template-ref.service';

@Injectable({
  providedIn: 'root'
})
export class PacchettoGuard implements CanActivate {
  constructor(private templateRefSrv: TemplateRefService, private toastService: ToastService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let mockup = localStorage.getItem("mockup")
    if (mockup) {
      return true
    } else {
      this.toastService.show({template: this.templateRefSrv.getTemplateRef('sceltaMockup'), classname: 'bg-secondary text-white', delay: 10000})
      return false;
    }
  }
  
}
