import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { TemplateRefService } from 'src/app/service/template-ref.service';

@Component({
  selector: 'app-scelta-mockup',
  templateUrl: './scelta-mockup.component.html',
  styleUrls: ['./scelta-mockup.component.scss']
})
export class SceltaMockupComponent implements AfterViewInit {
  @ViewChild('sceltaMockup', {static: true}) sceltaMockupTemplate!: TemplateRef<any>;
  @ViewChild('sceltaMockupEPacchetto', {static: true}) sceltaMockupEPacchettoTemplate!: TemplateRef<any>;
  @ViewChild('sceltaPacchetto', {static: true}) sceltaPacchettoTemplate!: TemplateRef<any>;
  @ViewChild('sceltaOrdine', {static: true}) sceltaOrdineTemplate!: TemplateRef<any>;
  @ViewChild('noLoggato', {static: true}) noLoggatoTemplate!: TemplateRef<any>;
  @ViewChild('paginaNonRimuovibile', {static: true}) paginaNonRimuovibileTemplate!: TemplateRef<any>;
  @ViewChild('erroreCaricamentoImmagine', {static: true}) erroreCaricamentoImmagineTemplate!: TemplateRef<any>;
  @ViewChild('formDaCompletare', {static: true}) formDaCompletareTemplate!: TemplateRef<any>;
  @ViewChild('prodottoFullCustomNonDisponibile', {static: true}) prodottoFullCustomNonDisponibileTemplate!: TemplateRef<any>;
  @ViewChild('prodottoPremiumNonDisponibile', {static: true}) prodottoPremiumNonDisponibileTemplate!: TemplateRef<any>;
  @ViewChild('richiestaInviata', {static: true}) richiestaInviataTemplate!: TemplateRef<any>;
  @ViewChild('registrazioneEffettuata', {static: true}) registrazioneEffettuataTemplate!: TemplateRef<any>;
  @ViewChild('loginEffettuato', {static: true}) loginEffettuatoTemplate!: TemplateRef<any>;
  @ViewChild('ordineEffEPagato', {static: true}) ordineEffEPagatoTemplate!: TemplateRef<any>;
  @ViewChild('elementoRimosso', {static: true}) elementoRimossoTemplate!: TemplateRef<any>;
  @ViewChild('accessoProvider', {static: true}) accessoProviderTemplate!: TemplateRef<any>;
  @ViewChild('passwordStrong', {static: true}) passwordStrongTemplate!: TemplateRef<any>;
  @ViewChild('custom', {static: true}) customTemplate!: TemplateRef<any>;
  constructor(private templateRefSrv: TemplateRefService) {
  }

  ngAfterViewInit(): void {
    this.templateRefSrv.setTemplateRef("sceltaMockup", this.sceltaMockupTemplate)
    this.templateRefSrv.setTemplateRef("sceltaMockupEPacchetto", this.sceltaMockupEPacchettoTemplate)
    this.templateRefSrv.setTemplateRef("sceltaPacchetto", this.sceltaPacchettoTemplate)
    this.templateRefSrv.setTemplateRef("sceltaOrdine", this.sceltaOrdineTemplate)
    this.templateRefSrv.setTemplateRef("noLoggato", this.noLoggatoTemplate)
    this.templateRefSrv.setTemplateRef("paginaNonRimuovibile", this.paginaNonRimuovibileTemplate)
    this.templateRefSrv.setTemplateRef("erroreCaricamentoImmagine", this.erroreCaricamentoImmagineTemplate)
    this.templateRefSrv.setTemplateRef("formDaCompletare", this.formDaCompletareTemplate)
    this.templateRefSrv.setTemplateRef("prodottoFullCustomNonDisponibile", this.prodottoFullCustomNonDisponibileTemplate)
    this.templateRefSrv.setTemplateRef("prodottoPremiumNonDisponibile", this.prodottoPremiumNonDisponibileTemplate)
    this.templateRefSrv.setTemplateRef("richiestaInviata", this.richiestaInviataTemplate)
    this.templateRefSrv.setTemplateRef("registrazioneEffettuata", this.registrazioneEffettuataTemplate)
    this.templateRefSrv.setTemplateRef("loginEffettuato", this.loginEffettuatoTemplate)
    this.templateRefSrv.setTemplateRef("ordineEffEPagato", this.ordineEffEPagatoTemplate)
    this.templateRefSrv.setTemplateRef("elementoRimosso", this.elementoRimossoTemplate)
    this.templateRefSrv.setTemplateRef("accessoProvider", this.accessoProviderTemplate)
    this.templateRefSrv.setTemplateRef("passwordStrong", this.passwordStrongTemplate)
    this.templateRefSrv.setTemplateRef("custom", this.customTemplate)
    
  }
}
